const minMessage = (num: number) => `${num} 보다 커야 합니다.`;
const maxMessage = (num: number) => `${num} 보다 작아야 합니다.`;
const requiredMessage = () => '필수로 작성해주세요.';
const multipleOfMessage = (num: number) => `${num}의 배수여야 합니다.`;

const scoreMessage = (examName: string, scoreName: string) => {
  const extraMessage = {
    maxMessage: (num: number) => `${examName}의 ${scoreName} ${maxMessage(num)}`,
    minMessage: (num: number) => `${examName}의 ${scoreName} ${minMessage(num)}`,
    requiredMessage: (type: '입력' | '선택' = '입력') =>
      `${examName}의 ${scoreName} 필수로 ${type}해주세요.`,
    multipleOfMessage: (num: number) => `${examName}의 ${scoreName} ${multipleOfMessage(num)}`,
    customMessage: (message: string) => `${examName}의 ${scoreName} ${message}`,
  };
  return extraMessage;
};

export const SCHEMA_MESSAGE = {
  maxMessage,
  minMessage,
  requiredMessage,
  multipleOfMessage,
  scoreMessage,
};
