import Text from '@simppl/component/Text';

import { Edit } from './icon';

type CardViewProps = {
  type?: 'highSchool' | 'etc';
  semester: string;
  testName: string;
  countOfSubjects: number;
  score: number;
  onClick?: () => void;
};
export function CardView({
  testName,
  onClick,
  semester,
  countOfSubjects,
  score,
  type = 'highSchool',
}: CardViewProps) {
  return (
    <div
      className='flex h-[248px] w-[240px] flex-col gap-[8px] rounded-[8px] border-[0.5px] border-[#B0C4E4] px-[32px] py-[24px]'
      onClick={onClick}
    >
      <div className='flex w-full flex-col items-center justify-center gap-[16px] text-center'>
        {type === 'highSchool' && (
          <>
            <Text
              color='text-Blue-4'
              weight={0}
              as='Body'
              size={1}
            >
              {semester}
            </Text>
            <div className='h-[2px] w-[46px] bg-[#E6ECF6]' />
          </>
        )}
        <Text
          color='text-blue-600'
          weight={0}
          as='Title'
          size={2}
        >
          {testName}
        </Text>
        <div className='flex w-full justify-center gap-[32px]'>
          <div className='flex flex-col gap-[8px]'>
            <Text
              color='text-Blue-4'
              weight={0}
              as='Body'
              size={1}
            >
              과목 수
            </Text>
            <div className='h-[2px] w-[46px] bg-[#E6ECF6]' />
            <Text
              color='text-Blue-4'
              weight={0}
              as='Title'
              size={2}
            >
              {countOfSubjects}
            </Text>
          </div>
          <div className='flex flex-col gap-[8px]'>
            <Text
              color='text-Blue-4'
              weight={0}
              as='Body'
              size={1}
            >
              총점
            </Text>
            <div className='h-[2px] w-[46px] bg-[#E6ECF6]' />
            <Text
              color='text-Blue-4'
              weight={0}
              as='Title'
              size={2}
            >
              {score}
            </Text>
          </div>
        </div>
      </div>
      <button className='flex h-[40px] w-full items-center justify-center'>
        <Edit />
      </button>
    </div>
  );
}
