import * as yup from 'yup';

import { ACTIVITY_format, ACTIVITY_schema } from './activity';
import { AWARD_format, AWARD_schema } from './award';

export const activityAwardTestMap = {
  ACTIVITY: {
    content: 'ACTIVITY',
    defaultFormat: ACTIVITY_format,
  },
  AWARD: {
    content: 'AWARD',
    defaultFormat: AWARD_format,
  },
};

export const activityAwardTestSchemaMap = yup.object().shape({
  activityAward: yup.array().of(
    yup.lazy((value) => {
      const { subject } = value;
      return subject === 'ACTIVITY' ? ACTIVITY_schema : AWARD_schema;
    }),
  ),
});
