/* eslint-disable react/no-array-index-key */

/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Title } from '@component/score/common/form';
import { IELTS_format } from '@schema/language/ielts';
import Spacing from '@simppl/component/Spacing';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type IELTSFormProps = {
  content: string;
};

export function IELTSForm({ content }: IELTSFormProps) {
  const methods = useFormContext();

  useEffect(() => {
    const languageTestId = methods.watch(`${content}.languageTestId`);
    if (languageTestId) {
      methods.setValue(`${content}.testId`, languageTestId);
      const totalScore = methods.watch(`${content}.totalScore`);
      methods.setValue(`${content}.score`, totalScore ?? 0);
    }
  }, []);
  return (
    <div className='grid gap-[24px] [grid-template-areas:"grade_others"]'>
      <Spacing width='124px' />
      <div className='flex flex-col gap-[24px] [grid-area:others]'>
        {IELTS_format.map(({ subject, property }, index) => (
          <div
            key={index}
            className='flex gap-[24px]'
          >
            {property.map((propertyItem) => (
              <>
                {propertyItem.type === 'input' && (
                  <Input
                    key={propertyItem.content}
                    {...propertyItem}
                    name={`${content}.${subject}`}
                  />
                )}
                {propertyItem.type === 'title' && (
                  <Title
                    key={propertyItem.content}
                    {...propertyItem}
                  />
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
