/* eslint-disable no-extra-boolean-cast */

/* eslint-disable react/destructuring-assignment */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-shadow */
import { useMockUserAdmissions } from '@hook/useMockUserAdmissions';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { mockUserAdmissions } from '@simppl/repository/admins';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type FakeApplicantAddModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; userAdmissionId: string }
);

export const FakeApplicantUpdateModal = (props: FakeApplicantAddModalProps) => {
  if (props.mode !== 'edit' || !props.userAdmissionId) return null;

  const mockUserAdmission = useMockUserAdmissions().find(
    (item) => item.id === props.userAdmissionId,
  );
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const { control, handleSubmit } = methods;

  useEffect(() => {
    if (mockUserAdmission) {
      Object.entries(mockUserAdmission.serializedScore).forEach(([key, value]) => {
        methods.setValue(`data.${key}`, `${value}`);
      });
    }
  }, []);

  const patchMockUserAdmissionsHook = useMutation(mockUserAdmissions.patch);
  const queryClient = useQueryClient();

  return (
    <Modal
      size='2xl'
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      onClose={props.onClose}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>가 데이터 추가</ModalHeader>
            <ModalBody>
              <FormProvider {...methods}>
                <form
                  id='authorization'
                  className='flex flex-col gap-4'
                  onSubmit={handleSubmit(({ data }) => {
                    console.log(data);
                    const temp = {
                      score: parseInt(data.score ?? 0, 10),
                      userSerializedScore: {
                        ibSubjectCount: parseInt(data.ibSubjectCount, 10),
                        apSubjectCount: parseInt(data.apSubjectCount, 10),
                        aLevelSubjectCount: parseInt(data.aLevelSubjectCount, 10),
                        localScore: parseInt(data.localScore, 10),
                        toeflScore: parseInt(data.toeflScore, 10),
                        ieltsScore: parseInt(data.ieltsScore, 10),
                        tepsScore: parseInt(data.tepsScore, 10),
                        toeicScore: parseInt(data.toeicScore, 10),
                        hskScore: parseInt(data.hskScore, 10),
                        deleScore: parseInt(data.deleScore, 10),
                        delfScore: parseInt(data.delfScore, 10),
                        ibScore: parseInt(data.ibScore, 10),
                        apScore: parseInt(data.apScore, 10),
                        satScore: parseInt(data.satScore, 10),
                        aLevelScore: parseInt(data.aLevelScore, 10),
                        actScore: parseInt(data.actScore, 10),
                        awardScore: parseInt(data.awardScore, 10),
                        activityScore: parseInt(data.activityScore, 10),
                        deleRank:
                          data.deleRank === null || data.deleRank?.length === 0
                            ? null
                            : data.deleRank,
                        delfRank:
                          data.delfRank === null || data.delfRank?.length === 0
                            ? null
                            : data.delfRank,
                        hskRank:
                          data.hskRank === null || data.hskRank?.length === 0
                            ? null
                            : data.hskRank?.split('급')?.[0] ?? 1,
                      },
                    };
                    console.log(temp);
                    if (mockUserAdmission)
                      patchMockUserAdmissionsHook.mutate(
                        {
                          userAdmissionId: props.userAdmissionId,
                          admissionId: mockUserAdmission.admissionId,
                          universityId: mockUserAdmission.universityId,
                          ...temp,
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries(['useMockUserAdmissions']);
                            props.onClose();
                          },
                        },
                      );
                  })}
                >
                  <div className='relative h-[1300px] max-h-[700px] overflow-auto'>
                    <Controller
                      name='data.score'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='총점'
                          placeholder='총점'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.localScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='localScore'
                          placeholder='localScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.toeflScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='toeflScore'
                          placeholder='toeflScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.ieltsScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='ieltsScore'
                          placeholder='ieltsScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.tepsScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='tepsScore'
                          placeholder='tepsScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.toeicScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='toeicScore'
                          placeholder='toeicScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.hskRank'
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelPlacement='outside'
                          label='hskRank'
                          placeholder='hskRank'
                        >
                          {['0', '1급', '2급', '3급', '4급', '5급', '6급'].map((grade, index) => {
                            if (index === 0) {
                              return (
                                <SelectItem
                                  key=''
                                  value=''
                                >
                                  없음
                                </SelectItem>
                              );
                            }
                            return (
                              <SelectItem
                                key={grade}
                                value={grade}
                              >
                                {grade}
                              </SelectItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                    <Controller
                      name='data.hskScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='hskScore'
                          placeholder='hskScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.deleRank'
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelPlacement='outside'
                          label='deleRank'
                          placeholder='deleRank'
                        >
                          {['0', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade, index) => {
                            if (index === 0) {
                              return (
                                <SelectItem
                                  key=''
                                  value=''
                                >
                                  없음
                                </SelectItem>
                              );
                            }
                            return (
                              <SelectItem
                                key={grade}
                                value={grade}
                              >
                                {grade}
                              </SelectItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                    <Controller
                      name='data.deleScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='deleScore'
                          placeholder='deleScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.delfRank'
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelPlacement='outside'
                          label='delfRank'
                          placeholder='delfRank'
                        >
                          {['0', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade, index) => {
                            if (index === 0) {
                              return (
                                <SelectItem
                                  key=''
                                  value=''
                                >
                                  없음
                                </SelectItem>
                              );
                            }
                            return (
                              <SelectItem
                                key={grade}
                                value={grade}
                              >
                                {grade}
                              </SelectItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                    <Controller
                      name='data.delfScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='delfScore'
                          placeholder='delfScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.ibScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='ibScore'
                          placeholder='ibScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.ibSubjectCount'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='ibSubjectCount'
                          placeholder='ibSubjectCount'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.apScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='apScore'
                          placeholder='apScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.apSubjectCount'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='apSubjectCount'
                          placeholder='apSubjectCount'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.satScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='satScore'
                          placeholder='satScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.aLevelScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='aLevelScore'
                          placeholder='aLevelScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.aLevelSubjectCount'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='aLevelSubjectCount'
                          placeholder='aLevelSubjectCount'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.actScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='actScore'
                          placeholder='actScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.awardScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='awardScore'
                          placeholder='awardScore'
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name='data.activityScore'
                      control={control}
                      defaultValue='0'
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          label='activityScore'
                          placeholder='activityScore'
                          {...field}
                        />
                      )}
                    />
                  </div>
                </form>
              </FormProvider>
            </ModalBody>
            <ModalFooter>
              <Button
                type='button'
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
              >
                수정
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
