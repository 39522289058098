/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useNoticeComments } from '@hook/useNoticeComments';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { noticeComment } from '@simppl/repository/notice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type NoticeCommentModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; commentId: string }
);

export function NoticeCommentModal(props: NoticeCommentModalProps) {
  const { id } = useParams();
  if (!id) return null;

  const comment =
    props.mode === 'edit'
      ? useNoticeComments({
          id,
          page: 0,
          size: 0,
        }).comments.find((comment) => comment.id.toString() === props.commentId)
      : null;
  const [content, setContent] = useState('' as string);

  const queryClient = useQueryClient();

  const postItemHook = useMutation(noticeComment.post);
  const patchItemHook = useMutation(noticeComment.patch);

  useEffect(() => {
    if (props.mode === 'edit' && comment) {
      setContent(comment.content);
    }
  }, [props, comment]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '댓글 추가' : '댓글 수정'}
            </ModalHeader>
            <ModalBody>
              <Editor
                initialData={content}
                onChange={setContent}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postItemHook.mutate(
                      {
                        id,
                        comment: content,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getNoticeComments']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && comment?.id)
                    patchItemHook.mutate(
                      {
                        id,
                        commentId: props.commentId,
                        comment: content,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getNoticeComments']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
