import { ComboBox, Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useStandarizedTestIds } from '@hook/useStandarizedTestIds';
import { ErrorMessage } from '@hookform/error-message';
import { ALEVELSubject } from '@schema/standardized/aLevel';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteUserStandardizedTestSubject } from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type ALevelFormProps = {
  content: string;
};

export function ALevelForm({ content }: ALevelFormProps) {
  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${content}.subjectList`,
  });

  return (
    <>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${content}.subjectList`}
        render={({ message }) => <p className='text-[12px] text-red-600'>{message}</p>}
      />
      <div className='flex w-full flex-col gap-2'>
        {fields.map(({ id }, index) => (
          <Form
            content={content}
            index={index}
            remove={remove}
            key={id}
          />
        ))}
      </div>
      <div className='pl-[148px]'>
        <button
          type='button'
          className='flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]'
          onClick={() =>
            append({
              id: null,
              score: null,
            })
          }
        >
          <Plus color='white' />
          과목추가
        </button>
      </div>
    </>
  );
}

type FormProps = {
  content: string;
  index: number;
  remove: (index: number) => void;
};

function Form({ content, index, remove }: FormProps) {
  const methods = useFormContext();
  const { A_LEVEL } = useStandarizedTestIds();
  const deleteUserStandardizedTestSubjectHook = useMutation((subjectId: string) =>
    deleteUserStandardizedTestSubject('A-LEVEL', subjectId),
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    const id = methods.watch(`${content}.subjectList.${index}.id`) ?? null;
    const name = methods.watch(`${content}.subjectList.${index}.name`) ?? null;
    if (id === null && name !== null) {
      methods.setValue(`${content}.subjectList.${index}.id`, A_LEVEL[name as keyof typeof A_LEVEL]);
    }
    if (id !== null && name === null) {
      methods.setValue(`${content}.subjectList.${index}.name`, A_LEVEL[id as keyof typeof A_LEVEL]);
    }
  }, [content, index, methods, A_LEVEL]);

  return (
    <div className='mr-[82px] flex justify-end gap-[24px]'>
      <Controller
        name={`${content}.subjectList.${index}`}
        control={methods.control}
        render={({ field }) => (
          <>
            <div className='flex justify-end gap-[9px]'>
              <>
                <ComboBox
                  type='combobox'
                  placeholder='과목 검색'
                  category={ALEVELSubject}
                  content={methods.watch(`${field.name}.name`) ?? ''}
                  name={`${field.name}.name`}
                  onChange={(value) => {
                    methods.setValue(`${field.name}.id`, A_LEVEL[value as keyof typeof A_LEVEL]);
                  }}
                />
                <Select
                  type='select'
                  size='long'
                  placeholder='등급'
                  category={{
                    'A*': 'A*',
                    A: 'A',
                    B: 'B',
                    C: 'C',
                    D: 'D',
                    E: 'E',
                    U: 'U',
                  }}
                  content={methods.watch(`${field.name}.score`) ?? ''}
                  name={`${field.name}.score`}
                />
              </>
            </div>
            <button
              className=' flex   h-[40px]   w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700'
              type='button'
              onClick={() => {
                const subjectId = methods.watch(
                  `${content}.subjectList.${index}.standarizedALevelId`,
                );
                if (subjectId)
                  deleteUserStandardizedTestSubjectHook.mutate(subjectId, {
                    onSuccess: () => remove(index),
                  });
                else {
                  remove(index);
                }
                queryClient.invalidateQueries(['standarized-tests-testByUserId']);
              }}
            >
              <Minus />
            </button>
          </>
        )}
      />
    </div>
  );
}
