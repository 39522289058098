import { ComboBox, Input, Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useStandarizedTestIds } from '@hook/useStandarizedTestIds';
import { ErrorMessage } from '@hookform/error-message';
import { APSubject } from '@schema/standardized/ap';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteUserStandardizedTestSubject } from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type APFormProps = {
  content: string;
};

export function APForm({ content }: APFormProps) {
  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${content}.subjectList`,
  });

  return (
    <>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${content}.subjectList`}
        render={({ message }) => <p className='text-[12px] text-red-600'>{message}</p>}
      />
      <div className='flex w-full flex-col gap-2'>
        {fields.map(({ id }, index) => (
          <Form
            content={content}
            index={index}
            remove={remove}
            key={id}
          />
        ))}
      </div>
      <div className='pl-[148px]'>
        <button
          type='button'
          className='flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]'
          onClick={() =>
            append({
              id: null,
              score: 0,
            })
          }
        >
          <Plus color='white' />
          과목추가
        </button>
      </div>
    </>
  );
}

type FormProps = {
  content: string;
  index: number;
  remove: (index: number) => void;
};

function Form({ content, index, remove }: FormProps) {
  const methods = useFormContext();
  const { AP } = useStandarizedTestIds();
  const deleteUserStandardizedTestSubjectHook = useMutation((subjectId: string) =>
    deleteUserStandardizedTestSubject('AP', subjectId),
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    const id = methods.watch(`${content}.subjectList.${index}.id`) ?? null;
    const name = methods.watch(`${content}.subjectList.${index}.name`) ?? null;
    if (id === null && name !== null) {
      methods.setValue(`${content}.subjectList.${index}.id`, AP[name as keyof typeof AP]);
    }
    if (id !== null && name === null) {
      methods.setValue(`${content}.subjectList.${index}.name`, AP[id as keyof typeof AP]);
    }
  }, [content, index, methods, AP]);

  return (
    <div className='mr-[82px] flex justify-end gap-[24px]'>
      <Controller
        name={`${content}.subjectList.${index}`}
        control={methods.control}
        render={({ field }) => (
          <>
            <div className='flex justify-end gap-[9px]'>
              <>
                <ComboBox
                  type='combobox'
                  placeholder='과목 검색'
                  category={APSubject}
                  content={methods.watch(`${field.name}.name`) ?? ''}
                  name={`${field.name}.name`}
                  onChange={(value) => {
                    methods.setValue(`${field.name}.id`, AP[value as keyof typeof AP]);
                  }}
                />
                <Select
                  defaultValue='1'
                  size='long'
                  type='select'
                  content='점수'
                  category={{
                    '5': '5',
                    '4': '4',
                    '3': '3',
                    '2': '2',
                    '1': '1',
                  }}
                  name={`${field.name}.score`}
                  onChange={(value) => {
                    methods.setValue(`${field.name}.score`, parseInt(value, 10));
                  }}
                />
              </>
            </div>
            <button
              className=' flex   h-[40px]   w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700'
              type='button'
              onClick={() => {
                const subjectId = methods.watch(`${content}.subjectList.${index}.standarizedApId`);
                if (subjectId)
                  deleteUserStandardizedTestSubjectHook.mutate(subjectId, {
                    onSuccess: () => remove(index),
                  });
                else {
                  remove(index);
                }

                queryClient.invalidateQueries(['standarized-tests-testByUserId']);
              }}
            >
              <Minus />
            </button>
          </>
        )}
      />
    </div>
  );
}
