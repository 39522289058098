import NavBar from '@component/Navbar';
import SideBar from '@component/SideBar';
import { Outlet } from 'react-router-dom';

const App = () => (
  <div className='relative flex flex-row'>
    <SideBar />
    <div className='flex min-h-screen w-[calc(100%-12rem)] flex-col'>
      <NavBar />
      <main className='relative flex h-full w-full grow flex-col'>
        <div className='flex h-full flex-col justify-center p-4'>
          <Outlet />
        </div>
      </main>
    </div>
  </div>
);

App.Error = () => <>error</>;

export default App;
