export const Minus = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
    >
      <rect
        x='0.5'
        y='5'
        width='12'
        height='2'
        rx='1'
        fill='#16489B'
      />
    </svg>
  );
};
