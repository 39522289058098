/* eslint-disable no-shadow */
import { NoticeModal } from '@component/community/notice/NoticeModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useNotices } from '@hook/useNotices';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { notice } from '@simppl/repository/notice';
import { Search } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';

const columns = [
  {
    key: 'title',
    label: '공지사항 제목',
  },
  {
    key: 'isFixed',
    label: '필독 여부',
  },
  {
    key: 'viewCount',
    label: '조회수',
  },
  {
    key: 'setting',
    label: '비고',
  },
];

export default function NoticePage() {
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const notices = useNotices({
    searchType: 'title',
    search: filterValue,
    page,
    size: 10,
  });

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue('');
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue('');
    setPage(1);
  }, []);

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; id: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const deleteNoticeHook = useMutation(notice.delete);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && (
          <NoticeModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>공지사항 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      setModalState({
                        mode: 'add',
                      });
                      onOpen();
                    }}
                  >
                    공지사항 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader columns={columns}>
              {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
            </TableHeader>
            <TableBody items={notices.posts}>
              {(notice) => (
                <TableRow
                  className='cursor-pointer'
                  key={notice.id}
                  href={`/community/notice/${notice.id}`}
                  as={Link}
                >
                  <TableCell>{notice.title}</TableCell>
                  <TableCell>{notice.isFixed ? '필독' : '일반'}</TableCell>
                  <TableCell>{notice.viewCount}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              id: notice.id.toString(),
                            });
                            onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            // eslint-disable-next-line no-restricted-globals
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteNoticeHook.mutate(
                              {
                                id: notice.id.toString(),
                              },
                              {
                                onSuccess: () => queryClient.invalidateQueries(['getNotices']),
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(notices.total / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
