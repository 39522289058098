import SwitchCase from '@simppl/component/SwitchCase';
import type { HighSchoolTestResType } from '@simppl/repository/test/res';

import { ALevelView } from './ALevel';
import { ApView } from './AP';
import { EtcView } from './ETC';
import { IbView } from './IB';
import { KoreaView } from './KOREA';

type HightSchoolFormProps = {
  testName: string;
  reportCardDetailList: HighSchoolTestResType['reportCardList'][0]['reportCardDetailList'];
};
export function HighSchoolListView({ testName, reportCardDetailList }: HightSchoolFormProps) {
  return (
    <SwitchCase
      value={testName}
      caseBy={{
        'A-LEVEL': <ALevelView reportCardDetailList={reportCardDetailList} />,
        AP: <ApView reportCardDetailList={reportCardDetailList} />,
        IB: <IbView reportCardDetailList={reportCardDetailList} />,
        ETC: <EtcView reportCardDetailList={reportCardDetailList} />,
        KOREA: <KoreaView reportCardDetailList={reportCardDetailList} />,
      }}
    />
  );
}
