import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { paymentsHistories } from '@simppl/repository/paymentHistories';

export const useUserPayments = () => {
  const { data } = useSuspenseQuery({
    queryKey: ['userPayments'],
    queryFn: () => paymentsHistories.get(),
  });

  return { data };
};
