import { useUserInfo } from '@hook/useUserInfo';
import { Button, useDisclosure } from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import { Pencil } from 'lucide-react';
import { useContext } from 'react';

import { EditUserInfoModal } from './modal/EditUserInfoModal';

export const UserDefaultTab = () => {
  const { userId } = useContext(UserIdContext);
  const userInfo = useUserInfo({ userId });
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className='flex flex-col gap-4'>
      {isOpen && (
        <EditUserInfoModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          userId={userId}
        />
      )}
      <div className='flex items-center gap-4'>
        <Button
          onPress={onOpen}
          isIconOnly
        >
          <Pencil />
        </Button>
        <div>기본 정보</div>
      </div>
      <div className='flex flex-col gap-2'>
        <div className='flex gap-4'>
          <span>이름 :</span>
          <span>{String(userInfo?.name)}</span>
        </div>
        <div className='flex gap-4'>
          <span>닉네임 :</span>
          <span>{String(userInfo?.nickname)}</span>
        </div>
        <div className='flex gap-2'>
          <span>이메일 :</span>
          <span>{String(userInfo?.email)}</span>
        </div>
        <div className='flex gap-2'>
          <span>고등학교 :</span>
          <span>{String(userInfo?.highSchool)}</span>
        </div>
        <div className='flex gap-2'>
          <span>국가 :</span>
          <span>{String(userInfo?.country)}</span>
        </div>
        <div className='flex gap-2'>
          <span>응시 횟수 :</span>
          <span>{userInfo?.admissionCount ?? 0}</span>
        </div>
      </div>
    </div>
  );
};
