import getImgUrl from '@simppl/util/getImgUrl';

type CardProps = {
  disabled?: boolean;
};

export function Card({ disabled = false }: CardProps) {
  return (
    <img
      src={getImgUrl(`/asset/Button/Card${disabled ? '-disabled' : ''}.svg`)}
      alt='카드형'
    />
  );
}
