import * as yup from 'yup';

import { SCHEMA_MESSAGE } from '../message';

export const TEPS_format = [
  {
    subject: 'reading',
    property: [
      {
        content: 'Reading',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 240,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
  {
    subject: 'listening',
    property: [
      {
        content: 'Listening',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 240,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
  {
    subject: 'voca',
    property: [
      {
        content: 'Vocabulary',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 60,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
  {
    subject: 'grammer',
    property: [
      {
        content: 'Grammar',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 60,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
  {
    subject: 'total',
    property: [
      {
        content: 'total',
        alterText: '총점',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 600,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
];

export const TEPS_schema = yup
  .object()
  .shape({
    transcript: yup
      .string()
      .required(
        SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '파일을').customMessage('업로드 해주세요.'),
      ),
    examDate: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '시험일자를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '발급기관을').requiredMessage()),
    reading: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Reading점수는').minMessage(0))
      .max(240, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Reading점수는').maxMessage(240))
      .required(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Reading점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Reading점수를').requiredMessage()),
    listening: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Listening점수는').minMessage(0))
      .max(240, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Listening점수는').maxMessage(240))
      .required(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Listening점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Listening점수를').requiredMessage()),
    voca: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Vocabulary점수는').minMessage(0))
      .max(60, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Vocabulary점수는').maxMessage(60))
      .required(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Vocabulary점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Vocabulary점수를').requiredMessage()),
    grammer: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Grammar점수는').minMessage(0))
      .max(60, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Grammar점수는').maxMessage(60))
      .required(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Grammar점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', 'Grammar점수를').requiredMessage()),
    total: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '총점은').minMessage(0))
      .max(600, SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '총점은').maxMessage(600))
      .required(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '총점을').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TEPS 시험', '총점을').requiredMessage()),
  })
  .default(null)
  .nullable();
