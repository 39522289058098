/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable no-shadow */
import { CategoryModal } from '@component/community/category/CategoryModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useCategories } from '@hook/useCategories';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { category } from '@simppl/repository/categories';
import { ChevronDownIcon, Search } from 'lucide-react';
import { useMemo, useState } from 'react';

const columns = [
  {
    key: 'title',
    label: '카테고리',
  },
  {
    key: 'setting',
    label: '비고',
  },
];

const categoryMap = {
  NOTICE: '공지사항',
  FAQ: '자주 묻는 질문',
  INQUIRY: '1:1문의',
};

export default function CategoryPage() {
  const [selectedKeys, setSelectedKeys] = useState(new Set(['NOTICE']));

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(', ').replace(/_/g, ' '),
    [selectedKeys],
  ) as 'NOTICE' | 'FAQ' | 'INQUIRY';

  const categories = useCategories({
    type: Array.from(selectedKeys)[0] as 'NOTICE' | 'FAQ' | 'INQUIRY',
  });
  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(categories.categories.children, (category, searchValue) =>
      category.title.includes(searchValue),
    );

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; id: string; type: 'NOTICE' | 'FAQ' | 'INQUIRY' }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const deleteNoticeHook = useMutation(category.delete);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && (
          <CategoryModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>카테고리 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                    <Dropdown>
                      <DropdownTrigger className='hidden sm:flex'>
                        <Button endContent={<ChevronDownIcon className='text-small' />}>
                          {categoryMap[selectedValue]}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        disallowEmptySelection
                        aria-label='Table Columns'
                        closeOnSelect={false}
                        selectionMode='single'
                        selectedKeys={selectedKeys}
                        onSelectionChange={setSelectedKeys as any}
                      >
                        {['NOTICE', 'FAQ', 'INQUIRY'].map((categoryItem) => (
                          <DropdownItem
                            key={categoryItem}
                            className='capitalize'
                          >
                            {categoryMap[categoryItem as 'NOTICE' | 'FAQ' | 'INQUIRY']}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <Button
                    color='primary'
                    onPress={() => {
                      setModalState({
                        mode: 'add',
                      });
                      onOpen();
                    }}
                  >
                    카테고리 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader columns={columns}>
              {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
            </TableHeader>
            <TableBody items={items}>
              {(notice) => (
                <TableRow key={notice.id}>
                  <TableCell>{notice.title}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              id: notice.id.toString(),
                              type: Array.from(selectedKeys)[0] as 'NOTICE' | 'FAQ' | 'INQUIRY',
                            });
                            onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            // eslint-disable-next-line no-restricted-globals
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteNoticeHook.mutate(
                              {
                                id: notice.id.toString(),
                              },
                              {
                                onSuccess: () => queryClient.invalidateQueries(['getCategories']),
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
