import { ScoreBlock, ScoreForm } from '@component/score/test';
import { yupResolver } from '@hookform/resolvers/yup';
import { languageTestSchemaMap } from '@schema/language';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { EditScore } from './EditScore';
import { LanguageScoreModeProvider } from './Provider';
import { ViewTest } from './ViewTest';

type ModeType = 'editScore' | 'viewTest';
const languageTests = {
  TOEFL: 'TOEFL',
  TOEIC: 'TOEIC',
  TEPS: 'TEPS',
  IELTS: 'IELTS',
  HSK: 'HSK',
  DELF: 'DELF',
  DELE: 'DELE',
  ETC: 'ETC',
};

const LanguageProficiency = () => {
  const [mode, setMode] = useState<ModeType>('viewTest');

  const methods = useForm({
    resolver: yupResolver(languageTestSchemaMap),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  return (
    <FormProvider {...methods}>
      <ScoreBlock list={languageTests}>
        <LanguageScoreModeProvider
          mode={mode}
          setMode={setMode}
        >
          <ScoreForm testType='language-tests'>
            {mode === 'viewTest' && ((<ViewTest />) as any)}
            {mode === 'editScore' && <EditScore />}
          </ScoreForm>
        </LanguageScoreModeProvider>
      </ScoreBlock>
    </FormProvider>
  );
};

export default LanguageProficiency;
