import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { type Tests, getTestByUserId } from '@simppl/repository/test';

const useTestByUserId = <TType extends keyof Tests>(testType: TType, userId: string) => {
  const { data } = useSuspenseQuery({
    queryKey: [`${testType}-testByUserId`],
    queryFn: () => getTestByUserId(testType, { userId }),
  });

  return data as Tests[TType];
};

export default useTestByUserId;
