import useQuery from '@simppl/react-query/useQuery';
import { competitiveUnits } from '@simppl/repository/competitiveUnits';

export const useCompetitiveUnits = () => {
  const { data, refetch } = useQuery(['getCompetitiveUnits'], () => competitiveUnits.get());
  return {
    data: data?.data ?? [],
    refetch,
  };
};
