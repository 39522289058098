/* eslint-disable no-shadow */
import { useAdmissions } from '@hook/useAdmissions';
import { useAdmissionsUniversities } from '@hook/useAdmissionsUniversity';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Pagination,
  Select,
  SelectItem,
} from '@nextui-org/react';
import Text from '@simppl/component/Text';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { mockUserAdmissions } from '@simppl/repository/admins';
import type { PostMockUserAdmissionsReqType } from '@simppl/repository/admins/req';
import c from '@simppl/util/c';
import { Search, X } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';

type FakeApplicantAddModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
};

type SelectTypeProps = {
  type: InterestType | null;
  onClick: (type: InterestType) => void;
};

type SelectUniversityProps = {
  type: InterestType;
  universityId: string;
  onClick: (university: string) => void;
  goNext: () => void;
};

type SelectSubjectProps = {
  type: InterestType;
  universityId: string;
  selectAdmissionId: string;
  onClick: ({ admissionId }: { admissionId: string }) => void;
  goNext: () => void;
};

type AddSubjectDataProps = {
  onClick: (
    datas: {
      score: number;
      userSerializedScore: PostMockUserAdmissionsReqType['userSerializedScore'];
    }[],
  ) => void;
};

const TYPE = [
  {
    name: '3년 특례',
    value: '3Y',
    disabled: true,
  },
  {
    name: '12년 특례',
    value: '12Y',
    disabled: false,
  },
  {
    name: '수시',
    value: 'EARLY',
    disabled: false,
  }
] as const;

export const FakeApplicantAddModal = ({
  isOpen,
  onOpenChange,
  onClose,
}: FakeApplicantAddModalProps) => {
  const [step, setStep] = useState(0);
  const [type, setType] = useState<InterestType | null>(null);
  const [university, setUniversity] = useState<string>('');
  const [admissionId, setAdmissionId] = useState('');
  const [subject, setSubject] = useState<string>('');
  const queryClient = useQueryClient();

  const goNext = () => {
    if (step === 0 && type === null) return;
    if (step < 3) setStep(step + 1);
  };

  const handleClose = () => {
    setStep(0);
    setType(null);
    setUniversity('');
    setAdmissionId('');
    setSubject('');
    onClose();
  };

  return (
    <Modal
      size='2xl'
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onClose={handleClose}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>가 데이터 추가</ModalHeader>
            <ModalBody>
              {step === 0 && (
                <SelectType
                  type={type}
                  onClick={setType}
                />
              )}
              {step === 1 && type && (
                <SelectUniversity
                  type={type}
                  universityId={university}
                  onClick={setUniversity}
                  goNext={goNext}
                />
              )}
              {step === 2 && type && university && (
                <SelectSubject
                  type={type}
                  universityId={university}
                  selectAdmissionId={admissionId}
                  onClick={({ admissionId: _admissionId }) => {
                    setAdmissionId(_admissionId);
                  }}
                  goNext={goNext}
                />
              )}
              {step === 3 && type && university && admissionId && (
                <AddSubjectData
                  onClick={(datas) => {
                    Promise.all(
                      datas.map(async (data) => {
                        const result = await mockUserAdmissions.post({
                          admissionId,
                          universityId: university,
                          score: data.score,
                          userSerializedScore: data.userSerializedScore,
                        });
                        return result;
                      }),
                    ).then((results) => {
                      if (results.every((result) => result.resultCode === 200)) {
                        queryClient.invalidateQueries(['useMockUserAdmissions']);
                        handleClose();
                      } else {
                        alert('저장중에 오류가 발생했습니다. 다시 시도해주세요.');
                      }
                    });
                  }}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                type='button'
                color='danger'
                variant='light'
                onPress={handleClose}
              >
                취소
              </Button>
              {step < 3 && (
                <Button
                  type='button'
                  form='authorization'
                  color='primary'
                  onClick={goNext}
                >
                  다음으로
                </Button>
              )}
              {step === 3 && (
                <Button
                  type='submit'
                  form='authorization'
                  color='primary'
                >
                  추가하기
                </Button>
              )}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

function AddSubjectData({ onClick }: AddSubjectDataProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const { control, handleSubmit } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: `data`,
  });

  return (
    <div className=''>
      <div className='flex gap-2 overflow-auto'>
        {fields.map(({ id }, index) => (
          <ButtonGroup key={id}>
            <Button
              isDisabled={currentIndex === index}
              onPress={() => setCurrentIndex(index)}
            >
              {index + 1}번
            </Button>
            <Button
              isIconOnly
              color='danger'
              isDisabled={currentIndex === index}
              onPress={() => remove(index)}
            >
              <X />
            </Button>
          </ButtonGroup>
        ))}
        <Button onPress={append}>데이터 추가</Button>
      </div>
      <FormProvider {...methods}>
        <form
          id='authorization'
          className='flex flex-col gap-4'
          onSubmit={handleSubmit((datas) => {
            onClick(
              datas.data.map((data: any) => ({
                score: parseInt(data.score, 10),
                userSerializedScore: {
                  ibSubjectCount: parseInt(data.ibSubjectCount, 10),
                  apSubjectCount: parseInt(data.apSubjectCount, 10),
                  aLevelSubjectCount: parseInt(data.aLevelSubjectCount, 10),
                  localScore: parseInt(data.localScore, 10),
                  toeflScore: parseInt(data.toeflScore, 10),
                  ieltsScore: parseInt(data.ieltsScore, 10),
                  tepsScore: parseInt(data.tepsScore, 10),
                  toeicScore: parseInt(data.toeicScore, 10),
                  hskScore: parseInt(data.hskScore, 10),
                  deleScore: parseInt(data.deleScore, 10),
                  delfScore: parseInt(data.delfScore, 10),
                  ibScore: parseInt(data.ibScore, 10),
                  apScore: parseInt(data.apScore, 10),
                  satScore: parseInt(data.satScore, 10),
                  aLevelScore: parseInt(data.aLevelScore, 10),
                  actScore: parseInt(data.actScore, 10),
                  awardScore: parseInt(data.awardScore, 10),
                  activityScore: parseInt(data.activityScore, 10),
                  deleRank:
                    data.deleRank === null || data.deleRank.length === 0 ? null : data.deleRank,
                  delfRank:
                    data.delfRank === null || data.delfRank.length === 0 ? null : data.delfRank,
                  hskRank:
                    data.hskRank === null || data.hskRank.length === 0
                      ? null
                      : data.hskRank.split('급')[0],
                },
              })),
            );
          })}
        >
          <div className='relative h-[1300px] max-h-[700px] overflow-auto'>
            {fields.map(({ id }, index) => (
              <div
                key={id}
                style={{ visibility: currentIndex === index ? 'visible' : 'hidden' }}
                className='absolute top-0 w-full px-6'
              >
                <h2 className='text-2xl'>{index + 1}번째 데이터</h2>
                <Controller
                  name={`data.${index}.score`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='총점'
                      placeholder='총점'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.localScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='localScore'
                      placeholder='localScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.toeflScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='toeflScore'
                      placeholder='toeflScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.ieltsScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='ieltsScore'
                      placeholder='ieltsScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.tepsScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='tepsScore'
                      placeholder='tepsScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.toeicScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='toeicScore'
                      placeholder='toeicScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.hskRank`}
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelPlacement='outside'
                      label='hskRank'
                      placeholder='hskRank'
                    >
                      {['0', '1급', '2급', '3급', '4급', '5급', '6급'].map((grade, index) => {
                        if (index === 0) {
                          return (
                            <SelectItem
                              key=''
                              value=''
                            >
                              없음
                            </SelectItem>
                          );
                        }
                        return (
                          <SelectItem
                            key={grade}
                            value={grade}
                          >
                            {grade}
                          </SelectItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <Controller
                  name={`data.${index}.hskScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='hskScore'
                      placeholder='hskScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.deleRank`}
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelPlacement='outside'
                      label='deleRank'
                      placeholder='deleRank'
                    >
                      {['0', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade, index) => {
                        if (index === 0) {
                          return (
                            <SelectItem
                              key=''
                              value=''
                            >
                              없음
                            </SelectItem>
                          );
                        }
                        return (
                          <SelectItem
                            key={grade}
                            value={grade}
                          >
                            {grade}
                          </SelectItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <Controller
                  name={`data.${index}.deleScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='deleScore'
                      placeholder='deleScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.delfRank`}
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelPlacement='outside'
                      label='delfRank'
                      placeholder='delfRank'
                    >
                      {['0', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade, index) => {
                        if (index === 0) {
                          return (
                            <SelectItem
                              key=''
                              value=''
                            >
                              없음
                            </SelectItem>
                          );
                        }
                        return (
                          <SelectItem
                            key={grade}
                            value={grade}
                          >
                            {grade}
                          </SelectItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <Controller
                  name={`data.${index}.delfScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='delfScore'
                      placeholder='delfScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.ibScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='ibScore'
                      placeholder='ibScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.ibSubjectCount`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='ibSubjectCount'
                      placeholder='ibSubjectCount'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.apScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='apScore'
                      placeholder='apScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.apSubjectCount`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='apSubjectCount'
                      placeholder='apSubjectCount'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.satScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='satScore'
                      placeholder='satScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.aLevelScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='aLevelScore'
                      placeholder='aLevelScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.aLevelSubjectCount`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='aLevelSubjectCount'
                      placeholder='aLevelSubjectCount'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.actScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='actScore'
                      placeholder='actScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.awardScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='awardScore'
                      placeholder='awardScore'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`data.${index}.activityScore`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      labelPlacement='outside'
                      label='activityScore'
                      placeholder='activityScore'
                      {...field}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

function SelectType({ type, onClick }: SelectTypeProps) {
  return (
    <div className='flex flex-col gap-[20px] text-center'>
      <Text
        as='Headline'
        size={2}
        weight={700}
        color='text-blue-500'
      >
        전형 선택
      </Text>
      <div className='flex justify-evenly px-10'>
        {TYPE.map(({ name, value, disabled }) => (
          <div
            key={value}
            className='flex'
          >
            <Checkbox
              isSelected={type === value}
              onValueChange={() => onClick(value)}
              disabled={disabled}
            />
            <Text
              as='Title'
              size={1}
              weight='Regular'
              color='text-Blue-5'
            >
              {name}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}

function SelectUniversity({ type, universityId, onClick, goNext }: SelectUniversityProps) {
  const [input, setInput] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const { data } = useAdmissionsUniversities({
    // isMain null 전체 대학 조회
    name: input,
    page,
    size: 12,
  });

  const totalPageOffset = data?.page;
  useEffect(() => {
    setPage(1);
  }, [input]);

  return (
    <div className='flex flex-col gap-[40px] text-center'>
      <Text
        as='Headline'
        size={2}
        weight={700}
        color='text-blue-500'
      >
        학교 선택
      </Text>
      <div className='border-Blue-Ct-2 flex h-[374px] w-[630px] flex-col gap-[32px] rounded-[16px] border-[0.5px] p-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
        <div className='relative'>
          <input
            className='h-[40px] w-[556px] rounded-[4px] border-[0.5px] border-blue-700 pl-[56px] pr-[180px] text-blue-500'
            placeholder='학교 검색'
            onInput={(e) => {
              setInput(e.currentTarget.value);
            }}
          />
          <div className='absolute left-[16px] top-[8px] flex h-[24px] w-[24px] items-center justify-center'>
            <Search />
          </div>
        </div>
        <div className='flex w-full flex-col gap-[32px]'>
          <Text
            as='Body'
            size={1}
            weight='Regular'
            color='text-blue-600'
          >
            {input && input.length > 0 ? '검색된 학교' : '주요 학교'}
          </Text>
          <div className='flex h-[108px] w-full flex-wrap'>
            {data?.universities?.map(({ universityId: _universityId, name }) => (
              <div
                key={_universityId}
                className='h-[28px] w-[25%]'
              >
                <div
                  className={c(
                    'group flex h-[28px] w-[128px] items-center justify-center gap-[16px]',
                    universityId === _universityId && 'rounded-[8px] bg-blue-500',
                  )}
                  onClick={() => onClick(_universityId)}
                  onDoubleClick={goNext}
                >
                  <Text
                    className={c(
                      'w-[120px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap',
                      universityId === _universityId ? 'text-white' : 'group-hover:text-blue-500',
                    )}
                    as='Title'
                    size={1}
                    weight='Regular'
                    color='text-black'
                  >
                    {name}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className='m-auto'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page + 1}
              onChange={(page) => setPage(page - 1)}
              total={Math.max(totalPageOffset ?? 1, 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectSubject({
  universityId,
  type,
  selectAdmissionId,
  onClick,
  goNext,
}: SelectSubjectProps) {
  const admissions = useAdmissions({
    universityId,
    type,
    name: '',
    isAvailable: true,
    page: 0,
    size: 9999999,
  }).data.admissionList;

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(
      admissions,
      (userPayment, value) => userPayment.admissionName?.includes(value),
    );

  return (
    <div className='flex flex-col gap-[40px] text-center'>
      <Text
        as='Headline'
        size={2}
        weight={700}
        color='text-blue-500'
      >
        학과 선택
      </Text>
      <div className='border-Blue-Ct-2 flex h-[374px] w-[630px] flex-col gap-[32px] rounded-[16px] border-[0.5px] p-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
        <div className='relative'>
          <input
            className='h-[40px] w-[556px] rounded-[4px] border-[0.5px] border-blue-700 pl-[56px] pr-[180px] text-blue-500'
            placeholder='학교 검색'
            value={filterValue}
            onInput={(e) => onSearchChange(e.currentTarget.value)}
          />
          <div className='absolute left-[16px] top-[8px] flex h-[24px] w-[24px] items-center justify-center'>
            <Search />
          </div>
        </div>
        <div className='flex w-full flex-col gap-[32px]'>
          <Text
            as='Body'
            size={1}
            weight='Regular'
            color='text-blue-600'
          >
            검색된 학과
          </Text>
          <div className='flex h-[108px] w-full flex-wrap'>
            {items?.map(({ admissionId, admissionName }) => (
              <div
                key={admissionId}
                className='w-[25%]'
              >
                <div
                  className={c(
                    'group flex h-[28px] w-[128px] items-center justify-center gap-[16px]',
                    selectAdmissionId === admissionId && 'rounded-[8px] bg-blue-500',
                  )}
                  onClick={() => onClick({ admissionId })}
                  onDoubleClick={goNext}
                >
                  <Text
                    className={c(
                      'w-[120px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap',
                      selectAdmissionId === admissionId
                        ? 'text-white'
                        : 'group-hover:text-blue-500',
                    )}
                    as='Title'
                    size={1}
                    weight='Regular'
                    color='text-Blue-5'
                  >
                    {admissionName}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className='m-auto'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
