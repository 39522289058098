/* eslint-disable no-alert */

/* eslint-disable no-shadow */
import { useCountries } from '@hook/useCountries';
import { useHighschools } from '@hook/useHighschools';
import { useUserInfo } from '@hook/useUserInfo';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import { putUserInfo } from '@simppl/repository/admins';
import type { PutUserInfoByAdminReqType } from '@simppl/repository/admins/req';
import { AtSign } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type EditUserInfoModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  userId: string;
};
const WRITING_MODE = '직접입력';
const notWritingEmailDomains = {
  'gmail.com': 'gmail.com',
  'naver.com': 'naver.com',
  'icloud.com': 'icloud.com',
  'hotmail.com': 'hotmail.com',
};

const emailDomainList = Object.values(notWritingEmailDomains);
const emailDomains = [
  {
    label: WRITING_MODE,
    value: WRITING_MODE,
  },
  ...Object.entries(notWritingEmailDomains).map(([key, value]) => ({ label: key, value })),
];

export function EditUserInfoModal({ isOpen, onOpenChange, userId }: EditUserInfoModalProps) {
  const userInfo = useUserInfo({ userId });

  const [selectedDomain, setSelectedDomain] = useState('');
  const [highSchool, setHighSchool] = useState(userInfo.highSchool);
  const [country, setCountry] = useState(userInfo.country);
  const { countryNameIdMap, countryNames } = useCountries({ isApprove: true });
  const countryId = countryNameIdMap[country];

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const { control, handleSubmit } = methods;

  const { highschoolNames } = useHighschools({
    countryId,
    isApprove: true,
  });

  const [emailId, name, nickname, emailDomain, admissionCount] = [
    'emailId',
    'name',
    'nickname',
    'emailDomain',
    'admissionCount',
  ].map((t) => methods.watch(t as any));

  const setEmailDomain = (value: string) => methods.setValue('emailDomain', value);

  const handleSelectedDomain = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setEmailDomain(value === WRITING_MODE ? '' : value);
    setSelectedDomain(value);
  };

  const email = !!emailId && !!emailDomain ? `${emailId}@${emailDomain}` : '';
  const isWritingMode = selectedDomain === WRITING_MODE;

  useEffect(() => {
    methods.setValue('name', userInfo.name);
    methods.setValue('nickname', userInfo.nickname);
    const [userEmail, userEmailDomain] = userInfo.email.split('@');
    setSelectedDomain(emailDomainList.includes(userEmailDomain) ? userEmailDomain : WRITING_MODE);
    methods.setValue('emailId', userEmail);
    methods.setValue('emailDomain', userEmailDomain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const putUserInfoHook = useMutation((body: PutUserInfoByAdminReqType) =>
    putUserInfo(userId, body),
  );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>기본 정보 수정</ModalHeader>
            <ModalBody>
              <FormProvider {...methods}>
                <form
                  id='authorization'
                  className='flex flex-col gap-4'
                  onSubmit={(e) => {
                    e.preventDefault();
                    putUserInfoHook.mutate(
                      {
                        name,
                        status: userInfo.status as 'A' | 'U',
                        email: `${emailId}@${emailDomain}`,
                        nickname,
                      },
                      {
                        onSuccess: () => onClose(),
                        onError: (error: any) => alert(error.response.data.message),
                      },
                    );
                  }}
                >
                  <Controller
                    name='name'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Input
                        labelPlacement='outside'
                        placeholder='이름'
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name='nickname'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Input
                        labelPlacement='outside'
                        placeholder='닉네임'
                        {...field}
                      />
                    )}
                  />
                  <div className='flex w-full items-start justify-between gap-2'>
                    <Controller
                      name='emailId'
                      defaultValue=''
                      control={control}
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          className='!w-[154px]'
                          placeholder='이메일주소'
                          {...field}
                        />
                      )}
                    />
                    <AtSign className='h-[40px] w-[16px]' />
                    <Controller
                      name='emailDomain'
                      defaultValue=''
                      control={control}
                      render={({ field }) => (
                        <Input
                          labelPlacement='outside'
                          className='w-[130px]'
                          {...field}
                          disabled={!isWritingMode}
                          onChange={(e) => setEmailDomain(e.target.value)}
                        />
                      )}
                    />
                    <Select
                      className='h-[40px] max-w-[140px]'
                      labelPlacement='outside'
                      items={emailDomains}
                      selectedKeys={[selectedDomain]}
                      onChange={handleSelectedDomain}
                    >
                      {(animal) => <SelectItem key={animal.value}>{animal.label}</SelectItem>}
                    </Select>
                  </div>
                  {/* <Button
              type='button'
              onPress={() => handleCheckDuplicate({ email })}
            >
              이메일 중복 확인
            </Button> */}
                  {/* <div className='flex flex-col gap-4'>
              <Autocomplete
                labelPlacement='outside'
                className='max-w-xs'
                onSelectionChange={(value) => {
                  setCountry(value as string);
                }}
              >
                {countryNames.map((country) => (
                  <AutocompleteItem
                    key={country}
                    value={country}
                  >
                    {country}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
              <Autocomplete
                labelPlacement='outside'
                className='max-w-xs'
                onSelectionChange={(value) => {
                  setHighSchool(value as string);
                }}
              >
                {highschoolNames.map((school) => (
                  <AutocompleteItem
                    key={school}
                    value={school}
                  >
                    {school}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
            </div> */}
                </form>
              </FormProvider>
            </ModalBody>
            <ModalFooter>
              <Button
                type='button'
                color='danger'
                variant='light'
                onPress={onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                // onPress={() => {}}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
