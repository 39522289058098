/* eslint-disable react/no-children-prop */
import ShadowDOM from '@component/ShadowDOM';
import { PostModal } from '@component/community/post/PostModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useCommunityCategories } from '@hook/useCommunityCategories';
import { usePostsByBoards } from '@hook/usePostsByBoardIds';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Select,
  SelectItem,
  type Selection,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import boardApi from '@simppl/repository/board';
import { getTime } from '@simppl/util/date';
import { Search } from 'lucide-react';
import { useState } from 'react';

type Category = {
  id: string;
  title: string;
  children: Array<Category>;
};

function CategorySelectBox({
  categories,
  setCategoryId,
  selectedCategories,
}: {
  categories: Array<Category>;
  selectedCategories: Selection;
  setCategoryId: (id: Selection) => void;
}) {
  return (
    <>
      <Select
        isRequired
        showScrollIndicators={false}
        selectionMode='multiple'
        className='max-w-xs overflow-hidden text-ellipsis'
        selectedKeys={selectedCategories}
        onSelectionChange={setCategoryId}
      >
        {categories.map((category) => (
          <SelectItem
            key={category.id}
            className='capitalize'
          >
            {category.title}
          </SelectItem>
        ))}
      </Select>
      <Button onPress={() => setCategoryId(new Set(categories.map((category) => category.id)))}>
        전체 선택
      </Button>
      <Button onPress={() => setCategoryId(new Set())}>전체 선택 취소</Button>
    </>
  );
}

const columns = [
  { key: 'category', label: '카테고리' },
  { key: 'title', label: '주제' },
  { key: 'createdAt', label: '생성일' },
  { key: 'viewCount', label: '조회수' },
  { key: 'commentCount', label: '댓글수' },
  { key: 'likeCount', label: '좋아요수' },
  { key: 'isAnonymous', label: '작성자' },
  { key: 'settings', label: '액션' },
];

export default function PostPage() {
  const [selectedBoardContent, setSelectedBoardContent] = useState<string | null>(null);
  const { categories } = useCommunityCategories();

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; boardId: string; categoryId: string }
    | null
  >(null);
  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();

  const flattenedCategories = (categories as never as Category[]).reduce<Category[]>(
    (acc, category) => {
      acc.push(category);
      category.children.forEach((childCategory) => {
        acc.push(childCategory);
      });

      return acc;
    },
    [],
  );

  const [selectedCategories, setSelectedCategories] = useState<Selection>(
    new Set(flattenedCategories.map((category) => category.id)),
  );

  const boards = usePostsByBoards(Array.from(selectedCategories) as string[]);
  const flattenedBoards = boards
    .map((board) => board.posts)
    .flat()
    .sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(flattenedBoards, (category, searchValue) =>
      category.title.includes(searchValue),
    );

  const queryClient = useQueryClient();
  const deletePostHook = useMutation(boardApi.boardId.posts.postId.delete);

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && (
          <PostModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>게시글 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <div className='flex flex-col gap-4'>
            <div className='flex w-full flex-row justify-between'>
              <div className='flex max-w-sm flex-1 flex-row items-center'>
                <Input
                  labelPlacement='outside'
                  startContent={<Search />}
                  value={filterValue}
                  onClear={() => onClear()}
                  onValueChange={onSearchChange}
                />
                <div className='flex gap-2'>
                  <CategorySelectBox
                    categories={flattenedCategories}
                    selectedCategories={selectedCategories}
                    setCategoryId={setSelectedCategories}
                  />
                </div>
              </div>
              <Button
                color='primary'
                onPress={() => {
                  setModalState({ mode: 'add' });
                  onOpen();
                }}
              >
                게시글 추가
              </Button>
            </div>
          </div>
          <div className='flex gap-4'>
            <Table layout='auto'>
              <TableHeader columns={columns}>
                {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
              </TableHeader>
              <TableBody>
                {...items
                  .map((post) => {
                    const elements = [
                      <TableRow
                        key={post.id}
                        onClick={() =>
                          setSelectedBoardContent((prev) => (prev === post.id ? null : post.id))
                        }
                        className='cursor-pointer hover:opacity-30'
                      >
                        <TableCell>{post.category.title}</TableCell>
                        <TableCell>{post.title}</TableCell>
                        <TableCell>{getTime({ createdAt: post.createdAt }).fullTime}</TableCell>
                        <TableCell>{post.viewCount}</TableCell>
                        <TableCell>{post.commentCount}</TableCell>
                        <TableCell>{post.likeCount}</TableCell>
                        <TableCell>{post.isAnonymous ? '익명' : post.user.nickname}</TableCell>
                        <TableCell>
                          <Dropdown>
                            <DropdownTrigger>
                              <Button
                                isIconOnly
                                size='sm'
                                variant='light'
                              >
                                <VerticalDotsIcon className='text-default-300' />
                              </Button>
                            </DropdownTrigger>
                            <DropdownMenu>
                              <DropdownItem
                                key='edit'
                                onPress={() => {
                                  setModalState({
                                    mode: 'edit',
                                    boardId: post.id.toString(),
                                    categoryId: post.category.id,
                                  });
                                  onOpen();
                                }}
                              >
                                수정
                              </DropdownItem>
                              <DropdownItem
                                key='delete'
                                className='text-red-500'
                                onPress={() => {
                                  // eslint-disable-next-line no-restricted-globals
                                  if (!confirm('정말 삭제하시겠습니까?')) return;
                                  deletePostHook.mutate(
                                    {
                                      postId: post.id.toString(),
                                    },
                                    {
                                      onSuccess: () => queryClient.invalidateQueries(['posts']),
                                    },
                                  );
                                }}
                              >
                                삭제
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </TableCell>
                      </TableRow>,
                    ];

                    if (selectedBoardContent === post.id) {
                      elements.push(
                        <TableRow key={`${post.id}content`}>
                          <TableCell colSpan={8}>
                            <ShadowDOM
                              className='w-full p-4'
                              html={post.content}
                              css={`
                                img {
                                  max-width: 100%;
                                }
                              `}
                            />
                          </TableCell>
                          <TableCell
                            className='hidden'
                            children={undefined}
                          />
                          <TableCell
                            className='hidden'
                            children={undefined}
                          />
                          <TableCell
                            className='hidden'
                            children={undefined}
                          />
                          <TableCell
                            className='hidden'
                            children={undefined}
                          />
                          <TableCell
                            className='hidden'
                            children={undefined}
                          />
                          <TableCell
                            className='hidden'
                            children={undefined}
                          />
                          <TableCell
                            className='hidden'
                            children={undefined}
                          />
                        </TableRow>,
                      );
                    }
                    return elements;
                  })
                  .flat()}
              </TableBody>
            </Table>
          </div>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
