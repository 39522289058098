import useQuery from '@simppl/react-query/useQuery';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getAdminInquiries } from '@simppl/repository/inquiries';
import type { GetInquiriesReqType } from '@simppl/repository/inquiries/req';

export const useInquiries = (body: GetInquiriesReqType) => {
  const data = useQuery(['getInquiries', body.page], () => getAdminInquiries(body), {
    suspense: true,
    keepPreviousData: true,
  }).data?.data;
  return { total: data?.total ?? 1, posts: data?.posts ?? [] };
};
