import { useUserAdmissions } from '@hook/useUserAdmissions';
import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteUserAdmissions } from '@simppl/repository/admission';
import type { GetUserAdmissionsResType } from '@simppl/repository/admission/res';
import { useContext } from 'react';

export const UserAdmissionTab = () => {
  const { userId } = useContext(UserIdContext);
  const userAdmissions = useUserAdmissions({ userId });

  const admissions = userAdmissions.resultList.reduce(
    (acc, cur) => {
      if (acc[cur.userId] === undefined) acc[cur.userId] = { userName: cur.userName, data: {} };
      if (acc[cur.userId] !== undefined && acc[cur.userId].data[cur.createdAt] === undefined)
        acc[cur.userId].data[cur.createdAt] = [];
      acc[cur.userId].data[cur.createdAt].push(cur);
      return acc;
    },
    {} as Record<
      string,
      { userName: string; data: Record<string, GetUserAdmissionsResType['data']> }
    >,
  );

  const queryClient = useQueryClient();
  const deleteUserAdmissionHook = useMutation((userAdmissionId: string) =>
    deleteUserAdmissions(userAdmissionId),
  );

  return (
    <Table layout='auto'>
      <TableHeader>
        <TableColumn
          className='w-[150px]'
          key='name'
        >
          이름
        </TableColumn>
        <TableColumn
          className='w-[220px]'
          key='appliedAt'
          allowsSorting
        >
          지원일자
        </TableColumn>
        <TableColumn
          className='w-[220px]'
          key='isApproved'
          allowsSorting
        >
          승인여부
        </TableColumn>
        <TableColumn
          className='w-[230px]'
          key='rejectionReason'
        >
          반려사유
        </TableColumn>
        <TableColumn
          className=''
          key='setting'
        >
          비고
        </TableColumn>
      </TableHeader>
      <TableBody items={Object.values(admissions)}>
        {(admission) => (
          <TableRow key='expanded'>
            <TableCell
              colSpan={5}
              aria-colspan={5}
              className='p-0'
            >
              <Accordion className='p-0'>
                <AccordionItem
                  indicator={<></>}
                  startContent={
                    <div className='relative flex text-start'>
                      <div className='w-[150px] px-3'>{admission.userName}</div>
                      <div className='w-[220px] px-3'>
                        {new Date(Object.keys(admission.data)[0]).toLocaleDateString(
                          'ko-KR',
                          {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          },
                        )}
                      </div>
                      <div className='w-[220px] px-3'>
                        {Object.values(admission.data).reduce(
                          (acc, value) =>
                            acc + value.filter(({ isApproved }) => !isApproved).length,
                          0,
                        ) > 0
                          ? '반려'
                          : '승인'}
                      </div>
                    </div>
                  }
                >
                  {Object.entries(admission.data).map(([key, value]) => (
                    <Card
                      key={key}
                      className='m-2 flex flex-col gap-4'
                    >
                      <CardHeader className='px-6 py-4'>
                        {`지원일자: ${new Date(key).toLocaleDateString('ko-KR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                        })}`}
                      </CardHeader>
                      <CardBody className='flex flex-col gap-8'>
                        {value.map((admissionData) => (
                          <div key={admissionData.admissionId as any}>
                            <Table className='w-[900px] p-2'>
                              <TableHeader>
                                <TableColumn key='university'>지원대학</TableColumn>
                                <TableColumn key='subject'>지원학과</TableColumn>
                                <TableColumn key='subject'>승인 여부</TableColumn>
                                <TableColumn key='subject'>반려 사유</TableColumn>
                              </TableHeader>
                              <TableBody>
                                <TableRow key={admissionData.universityId}>
                                  <TableCell>{admissionData.universityName}</TableCell>
                                  <TableCell>{admissionData.admissionName}</TableCell>
                                  <TableCell>
                                    {admissionData.isApproved ? '승인' : '반려'}
                                  </TableCell>
                                  <TableCell>
                                    {admissionData.rejectionReason || '없음'}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            <Table className='w-[900px] p-2'>
                              <TableHeader>
                                {[
                                  'score',
                                  ...Object.keys(admissionData.serializedScore),
                                ].map((column) => (
                                  <TableColumn key={column}>{column}</TableColumn>
                                ))}
                              </TableHeader>
                              <TableBody>
                                <TableRow
                                  className='relative'
                                  key={admissionData.userId}
                                >
                                  {[
                                    admissionData.score,
                                    ...Object.values(admissionData.serializedScore),
                                  ].map((data) => (
                                    <TableCell
                                      className='text-center'
                                      key={data}
                                    >
                                      {data}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  ))}
                </AccordionItem>
              </Accordion>
            </TableCell>
            <TableCell className='hidden'> </TableCell>
            <TableCell className='hidden'> </TableCell>
            <TableCell className='hidden'> </TableCell>
            <TableCell className='hidden'> </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
