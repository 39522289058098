/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { competitiveUnits } from '@simppl/repository/competitiveUnits';
import { useEffect, useState } from 'react';

type CompetitiveUnitModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; competitiveUnitId: string }
);

export function CompetitiveUnitModal(props: CompetitiveUnitModalProps) {
  const [high, setHigh] = useState<number>(0.2);
  const [middle, setMiddle] = useState<number>(0.6);
  const [low, setLow] = useState<number>(0.2);
  const [selectedKeys, setSelectedKeys] = useState('');

  const item =
    props.mode === 'edit'
      ? useCompetitiveUnits().data.find(
          (competitiveUnit) => competitiveUnit.competitiveUnitId === props.competitiveUnitId,
        )
      : null;

  const [input, setInput] = useState('');
  const [admissionCapacity, setAdmissionCapacity] = useState<number>(0);

  const simulatedApplication = useSimulatedApplication();
  const queryClient = useQueryClient();

  const postUniversitiesHook = useMutation(competitiveUnits.post);
  const patchUniversitiesHook = useMutation(competitiveUnits.patch);

  useEffect(() => {
    if (props.mode === 'edit' && item) {
      setInput(item.name);
      setSelectedKeys(item.simulatedApplicationId);
      setAdmissionCapacity(item.admissionCapacity);
      setHigh(item.high);
      setMiddle(item.middle);
      setLow(item.low);
    }
  }, [props, item]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '경쟁단위 추가' : '경쟁단위 수정'}
            </ModalHeader>
            <ModalBody>
              <Input
                value={input as any}
                onChange={(e) => setInput(e.target.value)}
                label='경쟁단위 이름'
                labelPlacement='outside'
              />
              <Input
                type='number'
                value={admissionCapacity as any}
                onChange={(e) => setAdmissionCapacity(parseInt(e.target.value, 10))}
                label='모집인원'
                labelPlacement='outside'
              />
              <Autocomplete
                selectedKey={selectedKeys}
                onSelectionChange={setSelectedKeys as any}
              >
                {simulatedApplication.data.map((categoryItem) => (
                  <AutocompleteItem
                    key={categoryItem.simulatedApplicationId}
                    value={categoryItem.simulatedApplicationId}
                    className='capitalize'
                  >
                    {categoryItem.name}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
              <Input
                type='number'
                value={high as any}
                onChange={(e) => setHigh(parseFloat(e.target.value))}
                label='high'
                labelPlacement='outside'
              />
              <Input
                type='number'
                value={middle as any}
                onChange={(e) => setMiddle(parseFloat(e.target.value))}
                label='middle'
                labelPlacement='outside'
              />
              <Input
                type='number'
                value={low as any}
                onChange={(e) => setLow(parseFloat(e.target.value))}
                label='low'
                labelPlacement='outside'
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postUniversitiesHook.mutate(
                      {
                        name: input,
                        simulatedApplicationId: selectedKeys,
                        admissionCapacity,
                        high,
                        middle,
                        low,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getCompetitiveUnits']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit')
                    patchUniversitiesHook.mutate(
                      {
                        competitiveUnitId: props.competitiveUnitId,
                        name: input,
                        simulatedApplicationId: selectedKeys,
                        admissionCapacity,
                        high,
                        middle,
                        low,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getCompetitiveUnits']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
