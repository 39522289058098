import * as yup from 'yup';

import { SCHEMA_MESSAGE } from '../message';

export const AWARD_format = {
  division: {
    in: '교내',
    out: '교외',
  },
  type: {
    curriculum: '교과',
    'extra-curricular': '비교과',
  },
};

export const AWARD_schema = yup
  .object()
  .shape({
    transcript: yup.string().required('파일을 업로드 해주세요.'),
    division: yup
      .mixed()
      .oneOf(Object.keys(AWARD_format.division))
      .required('교내외 구분을 필수로 선택해주세요.'),
    type: yup
      .mixed()
      .oneOf(Object.keys(AWARD_format.type))
      .required('교과선택을 필수로 선택해주세요.'),
    awardDate: yup
      .string()
      .required('수상일을 필수로 입력해주세요.')
      .typeError('수상일을 필수로 입력해주세요.'),
    content: yup
      .string()
      .required('수상 내용을 필수로 입력해주세요.')
      .max(300, SCHEMA_MESSAGE.maxMessage(300)),
    publishingOrganization: yup.string().required('발급기관을 필수로 입력해주세요.'),
  })
  .default(null)
  .nullable();
