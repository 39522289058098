/* eslint-disable react/no-array-index-key */
import useHighschoolScore from '@hook/useHighschoolScore';
import useTestByUserId from '@hook/useTestByUserId';
import {
  Accordion,
  AccordionItem,
  Button,
  Spacer,
  Tab,
  Tabs,
  useDisclosure,
} from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import { Pencil } from 'lucide-react';
import { useContext } from 'react';

import { EditScoreModal } from './modal/EditScoreModal';
import DownloadButton from './score/DownloadPdfButton';
import { activityAward } from './score/activityAward';
import { HighSchool } from './score/highSchool';
import { language } from './score/language';
import { standarized } from './score/standarized';

export const UserScoreTab = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  return (
    <div className='flex flex-col'>
      {isOpen && (
        <EditScoreModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          onClose={onOpenChange}
        />
      )}
      <Button
        onPress={onOpen}
        isIconOnly
      >
        <Pencil />
      </Button>
      <Spacer y={1} />
      <Tabs>
        <Tab
          key='report-cards'
          title='고교 성적'
        >
          <HighSchoolTest />
        </Tab>
        <Tab
          key='language-tests'
          title='어학 성적'
        >
          <LanguageTest />
        </Tab>
        <Tab
          key='standarized-tests'
          title='표준화 성적'
        >
          <StandardizedTest />
        </Tab>
        <Tab
          key='activities-awards'
          title='활동 성적'
        >
          <ActivitiesAwardTest />
        </Tab>
      </Tabs>
    </div>
  );
};

type LinkTextType = {
  title: string;
  content: string;
  type?: 'link' | 'text';
};

type FomatType =
  | LinkTextType
  | {
      type: 'array';
      children: {
        subjectName: string;
        content: {
          title: string;
          content: string;
        }[];
      }[];
    };

const HighSchoolTest = () => {
  const highSchoolTest = useHighschoolScore();

  return (
    <div className='flex flex-col gap-4'>
      <DownloadButton
        pdfUrl={highSchoolTest.transcript}
        pdfName='고교성적'
      />
      {highSchoolTest.reportCardList.map((semesterData) => (
        <Accordion
          key={semesterData.reportCardId}
          variant='bordered'
        >
          <AccordionItem title={`${semesterData.grade}학년 ${semesterData.semester}학기`}>
            <tr className='h-10'>
              <th className='w-20'>국가</th>
              <td>{semesterData.countryName}</td>
            </tr>
            <tr className='h-10'>
              <th className='w-20'>고등학교</th>
              <td>{semesterData.highschoolName}</td>
            </tr>
            <tr className='h-10'>
              <th className='w-20'>평균평점</th>
              <td>
                {semesterData.gpa} / {semesterData.perfectScore}
              </td>
            </tr>
            <tr className='h-10'>
              <th className='flex w-20 flex-col'>
                평균평점<span className='text-[10px]'>(100점 환산)</span>
              </th>
              <td>
                {Math.ceil((semesterData.gpa / (semesterData.perfectScore ?? 100)) * 100 * 100) /
                  100}
                / 100
              </td>
            </tr>
            <Accordion>
              <AccordionItem title={semesterData.curriculum}>
                <HighSchool
                  testName={semesterData.curriculum}
                  data={semesterData.reportCardDetailList}
                />
              </AccordionItem>
            </Accordion>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};

const LanguageTest = () => {
  const { userId } = useContext(UserIdContext);
  const languageTest = useTestByUserId('language-tests', userId);

  return (
    <div className='flex flex-col gap-4'>
      {Object.entries(languageTest).map(([key, value]) => (
        <Accordion
          key={key}
          variant='bordered'
        >
          <AccordionItem title={key}>
            {key === 'ETC'
              ? language[key as keyof typeof language](value as any).map((subject, index) => (
                  <div
                    key={`${subject}-${index}`}
                    className='pl-8'
                  >
                    <Accordion>
                      <AccordionItem title={`과목${index + 1}`}>
                        {(subject as LinkTextType[]).map(({ title, content, type }) =>
                          type === 'link' ? (
                            <tr
                              key={`${key}-${title}`}
                              className='w-full'
                            >
                              <DownloadButton
                                pdfUrl={content}
                                pdfName={key}
                              />
                            </tr>
                          ) : (
                            <tr
                              key={`${key}-${title}`}
                              className='h-10'
                            >
                              <th className='w-20'>{title}</th>
                              <td>{content}</td>
                            </tr>
                          ),
                        )}
                      </AccordionItem>
                    </Accordion>
                  </div>
                ))
              : (language[key as keyof typeof language](value as any) as LinkTextType[]).map(
                  ({ title, content, type }) =>
                    type === 'link' ? (
                      <tr
                        key={`${key}-${title}`}
                        className='w-full'
                      >
                        <DownloadButton
                          pdfUrl={content}
                          pdfName={key}
                        />
                      </tr>
                    ) : (
                      <tr
                        key={`${key}-${title}`}
                        className='h-10'
                      >
                        <th className='w-20'>{title}</th>
                        <td>{content}</td>
                      </tr>
                    ),
                )}
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};

const StandardizedTest = () => {
  const { userId } = useContext(UserIdContext);
  const standarizedTest = useTestByUserId('standarized-tests', userId);

  return (
    <div className='flex flex-col gap-4'>
      {Object.entries(standarizedTest)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => (
          <Accordion
            key={key}
            variant='bordered'
          >
            <AccordionItem title={key}>
              {key === 'ETC'
                ? standarized[key as keyof typeof standarized](value as any).map(
                    (subject, index) => (
                      <div
                        key={`${subject}-${index}`}
                        className='pl-8'
                      >
                        <Accordion>
                          <AccordionItem title={`과목${index + 1}`}>
                            {(subject as LinkTextType[]).map(({ title, content, type }) =>
                              type === 'link' ? (
                                <tr
                                  key={`${key}-${title}`}
                                  className='w-full'
                                >
                                  <DownloadButton
                                    pdfUrl={content}
                                    pdfName={key}
                                  />
                                </tr>
                              ) : (
                                <tr
                                  key={`${key}-${title}`}
                                  className='h-10'
                                >
                                  <th className='w-20'>{title}</th>
                                  <td>{content}</td>
                                </tr>
                              ),
                            )}
                          </AccordionItem>
                        </Accordion>
                      </div>
                    ),
                  )
                : (standarized[key as keyof typeof standarized](value as any) as FomatType[]).map(
                    (field) => {
                      if (field.type === 'array') {
                        return field.children.map((subject) => (
                          <div
                            key={subject.subjectName}
                            className='pl-8'
                          >
                            <Accordion>
                              <AccordionItem title={subject.subjectName}>
                                {subject.content.map(({ title, content }, index) => (
                                  <tr
                                    key={`${key}-${index}-${title}`}
                                    className='h-10'
                                  >
                                    <th className='w-20'>{title}</th>
                                    <td>{content}</td>
                                  </tr>
                                ))}
                              </AccordionItem>
                            </Accordion>
                          </div>
                        ));
                      }
                      const { title, content, type } = field;
                      return type === 'link' ? (
                        <tr
                          key={`${key}-${title}`}
                          className='w-full'
                        >
                          <DownloadButton
                            pdfUrl={content}
                            pdfName={key}
                          />
                        </tr>
                      ) : (
                        <tr
                          key={`${key}-${title}`}
                          className='h-10'
                        >
                          <th className='w-20'>{title}</th>
                          <td>{content}</td>
                        </tr>
                      );
                    },
                  )}
            </AccordionItem>
          </Accordion>
        ))}
    </div>
  );
};

const ActivitiesAwardTest = () => {
  const { userId } = useContext(UserIdContext);
  const activitiesAwardTest = useTestByUserId('activities-awards', userId);

  const activitiesAwardTestData = activitiesAwardTest.activityAwardList.reduce<{
    AWARD: Award[];
    ACTIVITY: Activity[];
  }>(
    (acc, item) => {
      if ('awardDate' in item) {
        acc.AWARD.push({
          awardDate: item.awardDate,
          content: item.content,
          division: item.division,
          type: item.type,
          publishingOrganization: item.publishingOrganization,
          transcript: item.transcript,
        });
      } else {
        acc.ACTIVITY.push({
          endDate: item.endDate,
          startDate: item.startDate,
          content: item.content,
          division: item.division,
          type: item.type,
          publishingOrganization: item.publishingOrganization,
          transcript: item.transcript,
        });
      }
      return acc;
    },
    { AWARD: [], ACTIVITY: [] },
  );
  return (
    <div className='flex flex-col gap-4'>
      {Object.entries(activitiesAwardTestData).map(([key, value]) => (
        <Accordion
          key={key}
          variant='bordered'
        >
          <AccordionItem title={key === 'AWARD' ? '수상' : '활동'}>
            {value.map((subject, index) => (
              <div
                key={`${key}-${index}`}
                className='pl-8'
              >
                <Accordion>
                  <AccordionItem title={`${key === 'AWARD' ? '수상' : '활동'}${index + 1}`}>
                    {(
                      activityAward[key as keyof typeof activityAward](
                        subject as any,
                      ) as LinkTextType[]
                    ).map(({ title, content, type }) =>
                      type === 'link' ? (
                        <tr
                          key={`${key}-${title}`}
                          className='w-full'
                        >
                          <DownloadButton
                            pdfUrl={content}
                            pdfName={key}
                          />
                        </tr>
                      ) : (
                        <tr
                          key={`${key}-${title}`}
                          className='h-10'
                        >
                          <th className='w-20'>{title}</th>
                          <td>{content}</td>
                        </tr>
                      ),
                    )}
                  </AccordionItem>
                </Accordion>
              </div>
            ))}
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};
