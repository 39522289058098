import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { postFiles } from '@simppl/repository/files';
import { type PostFilesReqType } from '@simppl/repository/files/req';
import { uploadReportCardTranscript } from '@simppl/repository/test';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export function UploadFile({
  name,
  testType,
  testName,
  onClose,
}: {
  name: string;
  testType: string;
  testName: string;
  onClose: () => void;
}) {
  const postFilesHook = useMutation((props: PostFilesReqType) => postFiles(props));
  const postReportCardTranscriptHook = useMutation(uploadReportCardTranscript);

  const queryClient = useQueryClient();

  const methods = useFormContext();
  const { id } = useParams();

  return (
    <input
      type='file'
      id={`${name}.uploadFile`}
      accept='.pdf'
      multiple={false}
      placeholder='파일 첨부'
      className='hidden'
      {...methods.register(`${name}.uploadFile`)}
      onClick={() => {
        onClose();
      }}
      onChange={(e) => {
        if (!e.target.files || e.target.files?.length === 0) {
          onClose();
          return;
        }
        const file = e.target.files[0];
        if (file.size > 0) {
          if (file.size > 8 * 1024 * 1024) {
            alert('8MB 이하의 파일만 첨부 가능합니다.');
            return;
          }
          const formData = new FormData();
          formData.append('file', file);
          formData.append('userId', id || '');
          formData.append('testType', testType);
          formData.append('testName', testName);
          formData.append('fileName', file.name);
          postFilesHook.mutate(formData, {
            onSuccess: (data) => {
              if (testType === 'high-school-tests')
                postReportCardTranscriptHook.mutate(
                  {
                    userId: id || '',
                    transcript: data.fileUri,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries(['report-cards-testByUserId']);
                      onClose();
                    },
                  },
                );
              else {
                methods.setValue(`${name}.uploadFile`, file.name);
                methods.setValue(`${name}.transcript`, data.fileUri);
              }
              e.target.value = ''; // 동일 파일 업로드시 업로드 되지 않는 문제 수정
            },
          });
        } else {
          alert('파일이 업로드되지 않았습니다. 고객센터로 문의부탁드립니다.');
        }
      }}
    />
  );
}
