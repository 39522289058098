import { Button } from '@nextui-org/react';

export default function DownloadButton({ pdfUrl, pdfName }: { pdfUrl: string; pdfName: string }) {
  const handleDownload = () => {
    fetch(pdfUrl, { method: 'GET' })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${pdfName}.pdf`;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
      })
      .catch((err) => {
        console.error('err: ', err);
      });
  };

  return (
    <Button onClick={handleDownload}>
      <div>pdf 다운로드</div>
    </Button>
  );
}
