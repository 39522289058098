import * as yup from 'yup';

import { SCHEMA_MESSAGE } from '../message';

export const APSubject = [
  '2-D Art and Design',
  '3-D Art and Design',
  'Art and Design Program',
  'Art History',
  'Biology',
  'Calculus AB',
  'Calculus BC',
  'Chemistry',
  'Chinese Language and Culture',
  'Comparative Government and Politics',
  'Computer Science A',
  'Computer Science Principles',
  'Drawing',
  'English Language and Composition',
  'English Literature and Composition',
  'Environmental Science',
  'European History',
  'French Language and Culture',
  'German Language and Culture',
  'Human Geography',
  'Italian Language and Culture',
  'Japanese Language and Culture',
  'Latin',
  'Macroeconomics',
  'Microeconomics',
  'Music Theory',
  'Physics 1 : Algebra-based',
  'Physics 2 : Algebra-based',
  'Physics C: Electricity and Magnetism',
  'Physics C: Mechanics',
  'Precalculus',
  'Psychology',
  'Research',
  'Seminar',
  'Spanish Language and Culture',
  'Spanish Literature and Culture',
  'Statistics',
  'United States Government and Politics',
  'United States History',
  'World History : Modern',
];

export const AP_schema = yup
  .object()
  .shape({
    transcript: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('AP 시험', '파일을').customMessage('업로드 해주세요.')),
    examDate: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('AP 시험', '시험일자를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('AP 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('AP 시험', '발급기관을').requiredMessage()),
    subjectList: yup
      .array()
      .min(
        1,
        SCHEMA_MESSAGE.scoreMessage('AP 시험', '과목을').customMessage(
          '최소 하나 이상 선택해주세요.',
        ),
      )
      .of(
        yup.object().shape({
          name: yup
            .mixed()
            .oneOf(APSubject, '')
            .required(SCHEMA_MESSAGE.scoreMessage('AP 시험', '과목의 이름을').requiredMessage()),
          score: yup
            .number()
            .min(1, SCHEMA_MESSAGE.scoreMessage('AP 시험', '점수는').minMessage(1))
            .max(5, SCHEMA_MESSAGE.scoreMessage('AP 시험', '점수는').maxMessage(5))
            .required(SCHEMA_MESSAGE.scoreMessage('AP 시험', '점수를').requiredMessage()),
        }),
      ),
  })
  .default(null)
  .nullable();
