import Text from '@simppl/component/Text';
import type { HighSchoolTestResType } from '@simppl/repository/test/res';

type ALevelViewProps = {
  reportCardDetailList: HighSchoolTestResType['reportCardList'][0]['reportCardDetailList'];
};

export function ALevelView({ reportCardDetailList }: ALevelViewProps) {
  return (
    <div className='flex flex-col justify-center'>
      {reportCardDetailList.map((reportCard: any) => (
        <div
          key={reportCard.reportCardSubjectId}
          className='flex justify-between'
        >
          <div className='w-[240px] px-[16px] py-[8px]'>
            <Text
              color='text-blue-700'
              weight='Medium'
              as='Label'
              size={1}
            >
              {reportCard.reportCardSubjectName}
            </Text>
          </div>
          <div className='flex w-[112px] justify-between px-[16px] py-[8px]'>
            <Text
              color='text-blue-500'
              weight='Regular'
              as='Label'
              size={1}
            >
              점수
            </Text>
            <Text
              color='text-Blue-5'
              weight='Regular'
              as='Label'
              size={1}
            >
              {reportCard.aLevelScore}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
}
