/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useUserPayments } from '@hook/useUserPayment';
import { useUsers } from '@hook/useUsers';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { paymentsHistories } from '@simppl/repository/paymentHistories';
import { useEffect, useState } from 'react';

type UserPaymentModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  paymentHistoryId: string;
};

export function UpdateUserPaymentModal(props: UserPaymentModalProps) {
  const users = useUsers();
  const [count, setCount] = useState<number>(0);
  const userPayment = useUserPayments().data.find(
    (payment) => payment.paymentHistoryId === props.paymentHistoryId,
  );

  useEffect(() => {
    setCount(userPayment?.count ?? 0);
  }, [userPayment]);

  const postUserPaymentHook = useMutation(paymentsHistories.patch);
  const queryClient = useQueryClient();

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>유저에게 상품 추가</ModalHeader>
            <ModalBody>
              <Input
                type='number'
                labelPlacement='outside'
                placeholder='상품개수'
                value={count.toString()}
                onChange={(e) => setCount(Number(e.target.value))}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  postUserPaymentHook.mutate(
                    {
                      paymentHistoryId: props.paymentHistoryId,
                      count,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['userPayments']);
                        props.onClose();
                      },
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
