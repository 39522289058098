/* eslint-disable react/no-array-index-key */

/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select, Title } from '@component/score/common/form';
import { DELE_format } from '@schema/language/dele';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type DELEFormProps = {
  content: string;
};

export function DELEForm({ content }: DELEFormProps) {
  const methods = useFormContext();

  useEffect(() => {
    const languageTestId = methods.watch(`${content}.languageTestId`);
    if (languageTestId) methods.setValue(`${content}.testId`, languageTestId);
  }, []);

  return (
    <div className='grid gap-[24px] [grid-template-areas:"grade_others"]'>
      <div className='[grid-area:grade]'>
        <Select
          category={{
            A1: 'A1',
            A2: 'A2',
            B1: 'B1',
            B2: 'B2',
            C1: 'C1',
            C2: 'C2',
          }}
          content='등급'
          name={`${content}.grade`}
          type='select'
        />
      </div>
      <div className='flex flex-col gap-[24px] [grid-area:others]'>
        {DELE_format.map(({ subject, property }, index) => (
          <div
            key={index}
            className='flex gap-[24px]'
          >
            {property.map((propertyItem) => (
              <>
                {propertyItem.type === 'input' && (
                  <Input
                    key={propertyItem.content}
                    {...propertyItem}
                    name={`${content}.${subject}`}
                  />
                )}
                {propertyItem.type === 'title' && (
                  <Title
                    key={propertyItem.content}
                    {...propertyItem}
                  />
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
