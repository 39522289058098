import Text from '@simppl/component/Text';
import { useFormContext } from 'react-hook-form';

type DeleViewProps = {
  content: string;
};

export function DeleView({ content }: DeleViewProps) {
  const methods = useFormContext();
  const data = methods.watch(`${content}`);

  return (
    <div className='relative flex flex-col justify-between'>
      <div className='absolute left-[-40px] top-[0px] flex h-[40px] flex-col justify-center'>
        <Text
          color='text-blue-700'
          weight='Medium'
          as='Label'
          size={1}
        >
          {data.grade}
        </Text>
      </div>
      {Object.entries({
        듣기: data.listening,
        독해: data.reading,
        쓰기: data.writing,
        회화: data.speaking,
      }).map(([key, value]) => (
        <div
          className='flex justify-between'
          key={key}
        >
          <div className='w-[240px] px-[16px] py-[8px]'>
            <Text
              color='text-blue-700'
              weight='Medium'
              as='Label'
              size={1}
            >
              {key}
            </Text>
          </div>
          <div className='flex w-[112px] justify-between px-[16px] py-[8px]'>
            <Text
              color='text-blue-500'
              weight='Regular'
              as='Label'
              size={1}
            >
              점수
            </Text>
            <Text
              color='text-Blue-5'
              weight='Regular'
              as='Label'
              size={1}
            >
              {value}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
}
