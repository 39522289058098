import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getReportCardSubjects, getTestSubjectIds } from '@simppl/repository/test';

export function useReportCardSubjectsIds() {
  const { data } = useSuspenseQuery({
    queryKey: ['report-card-subjects'],
    queryFn: () => getReportCardSubjects(),
  });

  return data?.reduce((acc, { id, name }) => ({ ...acc, [id]: name, [name]: id }), {}) ?? {};
}
