import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { userInterestAdmissions } from '@simppl/repository/user';

export const useAdmissionsInterestByUserId = (userId: string) => {
  const { data } = useSuspenseQuery({
    queryKey: ['useAdmissionsInterestByUserId'],
    queryFn: () => userInterestAdmissions.get({ userId }),
  });

  return { resultList: data };
};
