import useQuery from '@simppl/react-query/useQuery';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { notice } from '@simppl/repository/notice';
import type { GetNoticesReqType } from '@simppl/repository/notice/req';

export const useNotices = (body: GetNoticesReqType) => {
  const data = useQuery(['getNotices', body.page, body.search], () => notice.getAdmin(body), {
    suspense: true,
    keepPreviousData: true,
  }).data?.data;
  return { total: data?.total ?? 1, posts: data?.posts ?? [] };
};
