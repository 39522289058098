/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useStandarizedSubjects } from '@hook/useStandarizedSubjects';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { category } from '@simppl/repository/categories';
import { standarizedSubjects } from '@simppl/repository/test';
import { ChevronDownIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type StandarizedSubjectModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
    | {
      mode: 'add';
    }
    | { mode: 'edit'; subjectId: string; type: 'AP' | 'A-LEVEL' | 'IB' }
  );

const subjectMap = {
  AP: 'AP',
  'A-LEVEL': 'A-LEVEL',
  IB: 'IB',
};

export function StandarizedSubjectModal(props: StandarizedSubjectModalProps) {
  const [selectedKeys, setSelectedKeys] = useState(new Set(['NOTICE']));
  const [name, setName] = useState('');

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(', ').replace(/_/g, ' '),
    [selectedKeys],
  ) as 'NOTICE' | 'FAQ' | 'INQUIRY';

  const item =
    props.mode === 'edit'
      ? useStandarizedSubjects()[props.type].find((v) => v.id === props.subjectId)
      : null;

  const queryClient = useQueryClient();

  const postItemHook = useMutation(standarizedSubjects.post);
  const patchItemHook = useMutation(standarizedSubjects.patch);

  useEffect(() => {
    if (props.mode === 'edit' && item) {
      setName(item.name);
      setSelectedKeys(new Set([props.type]));
    }
  }, [props]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '표준화 과목 추가' : '표준화 과목 수정'}
            </ModalHeader>
            <ModalBody>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                label='과목명'
                labelPlacement='outside'
              />

              <Dropdown>
                <DropdownTrigger className='hidden sm:flex'>
                  <Button endContent={<ChevronDownIcon className='text-small' />}>
                    {subjectMap[selectedValue as 'AP' | 'A-LEVEL' | 'IB']}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  aria-label='Table Columns'
                  closeOnSelect={false}
                  selectionMode='single'
                  selectedKeys={selectedKeys}
                  onSelectionChange={setSelectedKeys as any}
                >
                  {['AP', 'A-LEVEL', 'IB'].map((categoryItem) => (
                    <DropdownItem
                      key={categoryItem}
                      className='capitalize'
                    >
                      {subjectMap[categoryItem as 'AP' | 'A-LEVEL' | 'IB']}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postItemHook.mutate(
                      {
                        name,
                        subject: Array.from(selectedKeys)[0] as 'AP' | 'A-LEVEL' | 'IB',
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['standarizedSubjects']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && item?.id)
                    patchItemHook.mutate(
                      {
                        subjectId: item.id.toString(),
                        name,
                        subject: Array.from(selectedKeys)[0] as 'AP' | 'A-LEVEL' | 'IB',
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['standarizedSubjects']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
