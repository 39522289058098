import SwitchCase from '@simppl/component/SwitchCase';

import { ActView } from './ACT';
import { ALevelView } from './ALevel';
import { ApView } from './AP';
import { EtcView } from './ETC';
import { IbView } from './IB';
import { SatView } from './SAT';

type StandardrizedFormProps = {
  testName: string;
  content: string;
};
export function StandardrizedListView({ testName, content }: StandardrizedFormProps) {
  return (
    <SwitchCase
      value={testName}
      caseBy={{
        'A-LEVEL': <ALevelView content={content} />,
        AP: <ApView content={content} />,
        IB: <IbView content={content} />,
        ETC: <EtcView content={content} />,
        SAT: <SatView content={content} />,
        ACT: <ActView content={content} />,
      }}
    />
  );
}
