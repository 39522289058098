import useQuery from '@simppl/react-query/useQuery';
import { universitiesMajors } from '@simppl/repository/admins';
import type { GetUniversitiesMajorsReqType } from '@simppl/repository/universities/req';

export const useUniversitiesMajors = (body: GetUniversitiesMajorsReqType) => {
  const { data, refetch } = useQuery(
    ['unversitiesMajors', body.universityId],
    () => universitiesMajors.get(body),
    {
      enabled: body.universityId !== undefined,
    },
  );

  return {
    data: data?.data ?? [],
    refetch,
  };
};
