type AwardDomData = {
  subject: 'AWARD';
} & Award;

type ActivityDomData = {
  subject: 'ACTIVITY';
} & Activity;

type ActivityAwardDomData = Array<AwardDomData | ActivityDomData>;

export const convertToDivideActivityAwardData = (data: ActivityAwardDomData) =>
  data.reduce<{ awardList: Award[]; activityList: Activity[] }>(
    (acc, item) => {
      if (item.subject === 'ACTIVITY') {
        acc.activityList.push({
          endDate: item.endDate,
          startDate: item.startDate,
          content: item.content,
          division: item.division,
          type: item.type,
          publishingOrganization: item.publishingOrganization,
          transcript: item.transcript,
        });
      } else {
        acc.awardList.push({
          awardDate: item.awardDate,
          content: item.content,
          division: item.division,
          type: item.type,
          publishingOrganization: item.publishingOrganization,
          transcript: item.transcript,
        });
      }
      return acc;
    },
    { awardList: [], activityList: [] },
  );
