/* eslint-disable react/no-array-index-key */

/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select, Title } from '@component/score/common/form';
import { HSK_format } from '@schema/language/hsk';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type HSKFormProps = {
  content: string;
};

export function HSKForm({ content }: HSKFormProps) {
  const methods = useFormContext();

  useEffect(() => {
    if (methods.watch(`${content}.grade`))
      methods.setValue(`${content}.grade`, parseInt(methods.watch(`${content}.grade`), 10));

    const languageTestId = methods.watch(`${content}.languageTestId`);
    if (languageTestId) {
      methods.setValue(`${content}.testId`, languageTestId);
      const [listening, reading, writing] = methods.getValues([
        `${content}.listening`,
        `${content}.reading`,
        `${content}.writing`,
      ]);
      methods.setValue(`${content}.total`, listening + reading + writing);
    }
  }, []);

  return (
    <div className='grid gap-[24px] [grid-template-areas:"grade_others"]'>
      <div className='[grid-area:grade]'>
        <Select
          category={{
            1: '1급',
            2: '2급',
            3: '3급',
            4: '4급',
            5: '5급',
            6: '6급',
          }}
          content='등급'
          name={`${content}.grade`}
          type='select'
          onChange={(value) => methods.setValue(`${content}.grade`, parseInt(value, 10))}
        />
      </div>
      <div className='flex flex-col gap-[24px] [grid-area:others]'>
        {HSK_format.map(({ subject, property }, index) => (
          <div
            key={index}
            className='flex gap-[24px]'
          >
            {property.map((propertyItem) => (
              <>
                {propertyItem.type === 'input' && (
                  <Input
                    key={propertyItem.content}
                    {...propertyItem}
                    name={`${content}.${subject}`}
                  />
                )}
                {propertyItem.type === 'title' && (
                  <Title
                    key={propertyItem.content}
                    {...propertyItem}
                  />
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
