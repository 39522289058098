import useQuery from '@simppl/react-query/useQuery';
import { simulatedApplications } from '@simppl/repository/simulatedApplications';

export const useSimulatedApplication = () => {
  const { data, refetch } = useQuery(['getSimulatedApplications'], () =>
    simulatedApplications.get(),
  );
  return {
    data: data?.data ?? [],
    refetch,
  };
};
