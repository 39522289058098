/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import { useUniversities } from '@hook/useUniversities';
import { parseDate } from '@internationalized/date';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  DatePicker,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { simulatedApplications } from '@simppl/repository/simulatedApplications';
import { extractDateFromTimestamp } from '@simppl/util/date';
import { generateStorage } from '@simppl/util/storage';
import { useEffect, useState } from 'react';

type SimulatedApplicationModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
    | {
      mode: 'add';
    }
    | { mode: 'edit'; simulatedApplicationId: string }
  );

export function SimulatedApplicationModal(props: SimulatedApplicationModalProps) {
  const storage = generateStorage();
  const { data } = useUniversities({
    // isMain null 전체 대학 조회
    name: '',
    page: 1,
    size: 100000,
  });

  const [input, setInput] = useState('');
  const [startDate, setStartDate] = useState(
    parseDate(extractDateFromTimestamp(new Date().toISOString())),
  );
  const [endDate, setEndDate] = useState(
    parseDate(extractDateFromTimestamp(new Date().toISOString())),
  );
  const [sort, setSort] = useState<'3Y' | '12Y' | 'EARLY'>('3Y');
  const [examSort, setExamSort] = useState<'exam' | 'document'>('exam');
  const [universityId, setUniversityId] = useState<string>('');

  const simulatedApplication =
    props.mode === 'edit'
      ? useSimulatedApplication().data?.find(
        (university) => university.simulatedApplicationId === props.simulatedApplicationId,
      )
      : null;

  const queryClient = useQueryClient();

  const postUniversitiesHook = useMutation(simulatedApplications.post);
  const patchUniversitiesHook = useMutation(simulatedApplications.patch);

  useEffect(() => {
    const simulatedApplicationDate = JSON.parse(storage.get('simulatedApplicationDate') ?? '{}');

    if ('startDate' in simulatedApplicationDate && 'endDate' in simulatedApplicationDate) {
      setStartDate(parseDate(extractDateFromTimestamp(simulatedApplicationDate.startDate)));
      setEndDate(parseDate(extractDateFromTimestamp(simulatedApplicationDate.endDate)));
    }

    if (props.mode === 'edit' && simulatedApplication) {
      setInput(simulatedApplication.name);
      setStartDate(parseDate(extractDateFromTimestamp(simulatedApplication.startDate)));
      setEndDate(parseDate(extractDateFromTimestamp(simulatedApplication.endDate)));
      setSort(simulatedApplication.sort);
      setUniversityId(simulatedApplication.universityId);
      setExamSort(simulatedApplication.examSort);
    }
  }, [props, simulatedApplication]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '전형 추가' : '전형 수정'}
            </ModalHeader>
            <ModalBody>
              <Input
                value={input as any}
                onChange={(e) => setInput(e.target.value)}
                label='전형 이름'
                labelPlacement='outside'
              />
              <Autocomplete
                label='대학을 선택해주세요.'
                className='max-w-xs'
                onSelectionChange={(value) => {
                  setUniversityId(value as string);
                }}
              >
                {data.map((university) => (
                  <AutocompleteItem
                    key={university.universityId}
                    value={university.universityId}
                  >
                    {university.name}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
              <DatePicker
                label='시작일'
                labelPlacement='outside'
                value={startDate as any}
                onChange={setStartDate as any}
              />
              <DatePicker
                label='마감일'
                labelPlacement='outside'
                value={endDate as any}
                onChange={setEndDate as any}
              />
              <RadioGroup
                label='전형 구분'
                value={sort}
                onValueChange={setSort as any}
              >
                <Radio value='3Y'>3Y</Radio>
                <Radio value='12Y'>12Y</Radio>
              </RadioGroup>
              <RadioGroup
                label='시험 구분'
                value={examSort}
                onValueChange={setExamSort as any}
              >
                <Radio value='exam'>집필</Radio>
                <Radio value='document'>서류</Radio>
              </RadioGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postUniversitiesHook.mutate(
                      {
                        name: input,
                        startDate: startDate.toString(),
                        endDate: endDate.toString(),
                        sort,
                        universityId,
                        examSort,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getSimulatedApplications']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit')
                    patchUniversitiesHook.mutate(
                      {
                        name: input,
                        startDate: startDate.toString(),
                        endDate: endDate.toString(),
                        sort,
                        simulatedApplicationId: props.simulatedApplicationId,
                        universityId,
                        examSort,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getSimulatedApplications']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
