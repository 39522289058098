/* eslint-disable no-shadow */
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  Textarea,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import { pathHighschools } from '@simppl/repository/highschools';
import type { PatchHighSchoolsReq } from '@simppl/repository/highschools/req';
import { useState } from 'react';

type HighSchoolManageModalProps = {
  highSchoolId: string;
  name: string;
  isAprroved: boolean;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
};

export const HighSchoolManageModal = ({
  highSchoolId,
  name,
  isAprroved,
  isOpen,
  onOpenChange,
}: HighSchoolManageModalProps) => {
  const [isApprove, setIsApprove] = useState(isAprroved);
  const [highSchoolName, setHighSchoolName] = useState(name);

  const patchHighSchoolHook = useMutation((body: PatchHighSchoolsReq & { highSchoolId: string }) =>
    pathHighschools(body.highSchoolId, body),
  );
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>고등학교 승인 관리</ModalHeader>
            <ModalBody>
              <div className='flex flex-col gap-4'>
                <div className='flex gap-4'>
                  <p>승인 여부</p>
                  <Switch
                    defaultSelected={false}
                    color='default'
                    isSelected={isApprove}
                    onValueChange={setIsApprove}
                  />
                </div>
                <div className='flex flex-col'>
                  <p>학교 이름</p>
                  <Textarea
                    placeholder='학교 이름을 적어주세요.'
                    value={highSchoolName}
                    onValueChange={setHighSchoolName}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  patchHighSchoolHook.mutate(
                    {
                      highSchoolId,
                      isApprove,
                      name: highSchoolName,
                    },
                    {
                      onSuccess: () => onClose(),
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
