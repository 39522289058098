/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { UploadFile } from '@component/Uploader';
import { useUniversities } from '@hook/useUniversities';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { useEffect, useState } from 'react';
import { patchUniversity, postUniversity } from '@simppl/repository/admins';

type UniversitytModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; universityId: string }
);

export function UniversityModal(props: UniversitytModalProps) {
  const [input, setInput] = useState('');
  const [image, setImage] = useState('');
  const [isMain, setIsMain] = useState(false);
  // const [selectedExam, setSelectedExam] = useState<'exam' | 'document'>('exam');

  const university =
    props.mode === 'edit'
      ? useUniversities({
          name: '',
          // isMain null 전체 대학 조회
          page: 1,
          size: 1000000,
        }).data?.find((university) => university.universityId === props.universityId)
      : null;

  const queryClient = useQueryClient();

  const postUniversitiesHook = useMutation(postUniversity);
  const patchUniversitiesHook = useMutation(patchUniversity);

  useEffect(() => {
    if (props.mode === 'edit' && university) {
      setInput(university.name);
      setImage(university.image);
      setIsMain(university.isMain);
    }
  }, [props, university]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '대학 추가' : '대학 수정'}
            </ModalHeader>
            <ModalBody>
              <Input
                value={input as any}
                onChange={(e) => setInput(e.target.value)}
                label='대학 이름'
                labelPlacement='outside'
              />
              <UploadFile
                name={image}
                accept=''
                testType='university'
                testName={input}
                setName={setImage}
              />
              <Checkbox
                isSelected={isMain}
                onValueChange={setIsMain}
              >
                주요 대학 여부
              </Checkbox>
              {/* <RadioGroup
                label='시험 종류'
                value={selectedExam}
                onValueChange={setSelectedExam as any}
              >
                <Radio value='exam'>지필</Radio>
                <Radio value='document'>면접</Radio>
              </RadioGroup> */}
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postUniversitiesHook.mutate(
                      {
                        name: input,
                        image,
                        isMain,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['universities']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && university?.universityId)
                    patchUniversitiesHook.mutate(
                      {
                        universityId: university.universityId,
                        name: input,
                        image,
                        isMain,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['universities']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
