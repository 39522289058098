import SwitchCase from '@simppl/component/SwitchCase';
import type {
  AP_Curriculum,
  A_LEVEL_Curriculum,
  ETC_Curriculum,
  IB_Curriculum,
} from '@simppl/repository/test/res';

import { Ap } from './AP';
import { Alevel } from './Alevel';
import { Etc } from './Etc';
import { Ib } from './Ib';

type HighSchoolProps = {
  testName: string;
  data:
    | AP_Curriculum['AP']
    | IB_Curriculum['IB']
    | A_LEVEL_Curriculum['A-LEVEL']
    | ETC_Curriculum['ETC'];
};
export function HighSchool({ testName, data }: HighSchoolProps) {
  return (
    <SwitchCase
      value={testName}
      caseBy={{
        'A-LEVEL': <Alevel {...(data as A_LEVEL_Curriculum['A-LEVEL'])} />,
        AP: <Ap {...(data as AP_Curriculum['AP'])} />,
        IB: <Ib {...(data as IB_Curriculum['IB'])} />,
        ETC: <Etc {...(data as ETC_Curriculum['ETC'])} />,
      }}
    />
  );
}
