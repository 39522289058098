import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { noticeComment } from '@simppl/repository/notice';
import type { GetNoticesCommentsReqType } from '@simppl/repository/notice/req';

export const useNoticeComments = (body: GetNoticesCommentsReqType) => {
  const {
    data: { total, comments },
  } = useSuspenseQuery({
    queryKey: ['getNoticeComments'],
    queryFn: () => noticeComment.get(body),
  });
  return { total, comments };
};
