export function ALevel(data: ALEVEL_ExamInfo) {
  return [
    {
      title: '업로드 url',
      type: 'link',
      content: data.transcript,
    },
    {
      title: '응시일자',
      content: data.examDate,
    },
    {
      title: '발행기관',
      content: data.publishingOrganization,
    },
    {
      type: 'array',
      children: data.subjectList.map((subject) => ({
        subjectName: subject.name,
        content: [
          {
            title: '과목명',
            content: subject.name,
          },
          {
            title: '점수',
            content: subject.score,
          },
        ],
      })),
    },
  ];
}
