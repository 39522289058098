/* eslint-disable react/no-array-index-key */
import { Card, List } from '@component/score/common';
import { AddSubjectView } from '@component/score/common/AddSubjectView';
import { CardView } from '@component/score/common/CardView';
import { ListView } from '@component/score/common/ListView';
import { scoreBlockContext } from '@component/score/test';
import { StandardrizedListView } from '@component/score/test/standarized/listView';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import c from '@simppl/util/c';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { StandardTestScoreModeContext } from './Provider';
import { subjectToScore } from './util';

export function ViewTest() {
  const { category } = useContext(scoreBlockContext);
  const { changeMode } = useContext(StandardTestScoreModeContext);
  const [mode, setMode] = useState<'list' | 'card'>('card');
  const methods = useFormContext();

  // eslint-disable-next-line no-shadow
  const handleChangeMode = (mode: 'list' | 'card') => {
    setMode(mode);
  };

  return (
    <>
      <div className='flex flex-col gap-[32px]'>
        <div className='border-Blue-Ct-2 flex w-[886px] flex-col rounded-[16px] border-[1px] px-[51px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
          <div className='flex w-full items-center justify-center gap-[15px]'>
            <Text
              color='text-Blue-4'
              weight='Bold'
              as='Label'
              size={1}
            >
              표준화 성적
            </Text>
          </div>
          <Spacing height='32px' />
          <div className='flex w-full justify-center gap-[22px]'>
            <button
              type='button'
              onClick={() => handleChangeMode('card')}
            >
              <Card disabled={mode !== 'card'} />
            </button>
            <button
              type='button'
              onClick={() => handleChangeMode('list')}
            >
              <List disabled={mode !== 'list'} />
            </button>
          </div>
          <Spacing height='32px' />
          <div
            className={c(
              'flex w-[784px] flex-wrap gap-[32px]',
              mode === 'list' && 'flex-col justify-center',
            )}
          >
            {Array.from(category.keys())?.map((testName, index: number) => {
              const data = methods.watch(testName);

              return mode === 'card' ? (
                <CardView
                  key={`${testName}-${index}`}
                  type='etc'
                  onClick={() => {
                    changeMode('editScore', {
                      formName: testName,
                    });
                  }}
                  semester=''
                  testName={testName}
                  score={subjectToScore(testName, data)}
                  countOfSubjects={data?.subjectList?.length || 1}
                />
              ) : (
                <ListView
                  type='etc'
                  key={`${testName}-${index}`}
                  onClick={() => {
                    changeMode('editScore', {
                      formName: testName,
                    });
                  }}
                  semester=''
                  testName={testName}
                  score={subjectToScore(testName, data)}
                  countOfSubjects={data?.subjectList?.length || 1}
                >
                  <StandardrizedListView
                    testName={testName}
                    content={testName}
                  />
                </ListView>
              );
            })}
            <AddSubjectView
              title='성적 추가'
              mode={mode}
              onClick={() => {
                // 학기 추가시 마지막 커리큘럼을 추가하기 위한 로직
                changeMode('editScore', {
                  formName: `test.${methods.watch(`test`)?.length}`,
                });
              }}
            />
          </div>
        </div>
        <Spacing height='32px' />
      </div>
    </>
  );
}
