/* eslint-disable no-nested-ternary */
import { Listbox } from '@headlessui/react';
import c from '@simppl/util/c';
import { ChevronDown } from 'lucide-react';

import GovermentIcon from './GovermentIcon';
import SwitchCase from './SwitchCase';
import Text from './Text';

type SelectBoxProps = {
  className?: string;
  selectedColor?: `text-${string}`;
  textAlign?: `text-${string}`;
  textColor?: `text-${string}`;
  textWeight?: 'Bold' | 'Medium' | 'Regular' | number;
  disables?: string[];
  currentValue: Nullable<string>;
  onClick: (value: string) => void;
  options: Record<string, string>;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  placeholderColor?: `text-${string}`;
  icon?: 'arrow' | 'goverment';
  isRotate?: boolean;
  isError?: boolean;
};

export default function SelectBox({
  className,
  selectedColor = 'text-Base-Deep-Gray',
  textColor,
  textWeight,
  options = {},
  currentValue,
  textAlign = 'text-start',
  onClick,
  disables = [],
  disabled,
  placeholder,
  icon = 'arrow',
  isRotate = true,
  placeholderColor,
  isError = false,
}: SelectBoxProps) {
  const isDisable = (value: string) => disables.some((v) => v === value);
  const value = currentValue ?? null;

  return (
    <div
      className={
        className
          ? `relative flex h-[40px] min-w-[124px] items-center rounded-[4px] ${className}`
          : 'relative flex h-[40px] min-w-[124px] items-center rounded-[4px] border-[1px] border-[#A6A6A6]'
      }
    >
      <Listbox
        as='ul'
        className='h-full w-full'
        value={value}
        onChange={onClick}
        disabled={disabled}
      >
        {({ open }: { open: boolean }) => (
          <>
            <Listbox.Button
              type='button'
              className={c(
                'h-full w-full rounded-[4px] pl-[22px] pr-[45px] outline-none',
                disabled ? 'bg-gray-100' : '',
                'flex items-center justify-between',
                textAlign || 'text-center',
              )}
            >
              {value == null && placeholder ? (
                <Text
                  as='Label'
                  size={1}
                  weight={textWeight ?? 'Regular'}
                  color={isError ? 'text-Others-Red-2' : (placeholderColor ?? 'text-Blue-1')}
                  truncate
                >
                  {value ?? placeholder ?? ''}
                </Text>
              ) : (
                <Text
                  as='Label'
                  size={1}
                  weight={textWeight ?? 'Medium'}
                  color={textColor ?? 'text-Blue-2'}
                  truncate
                >
                  {value ?? placeholder ?? ''}
                </Text>
              )}

              <span
                className={`${
                  isError ? 'text-Others-Red-2' : 'text-Blue-1'
                } absolute right-[16px] top-[50%] translate-y-[-50%] ${
                  isRotate && open ? 'rotate-180' : ''
                }`}
              >
                <SwitchCase
                  value={icon}
                  caseBy={{
                    arrow: <ChevronDown />,
                    goverment: <GovermentIcon isError={isError} />,
                  }}
                />
              </span>
            </Listbox.Button>
            <Listbox.Options
              className={c(
                'absolute left-0 top-[40px] z-[999]',
                'cursor-pointer rounded-[4px] border-[0.5px] border-[#D6E0FD] bg-white py-[8px] pl-[16px]',
                'flex flex-col gap-[8px]',
                'max-h-[232px] w-full overflow-y-auto',
              )}
            >
              {Object.entries(options).map(([key, option]) => (
                <Listbox.Option
                  as='li'
                  key={option}
                  value={key}
                  disabled={disables.includes(option)}
                  className='group'
                >
                  <Text
                    className='group-hover:text-Blue-1 cursor-pointer'
                    color={
                      value === option
                        ? selectedColor
                        : isDisable(option)
                          ? 'text-Base-Deep-Gray'
                          : 'text-Blue-5'
                    }
                    weight='Regular'
                    as='Label'
                    size={1}
                  >
                    {option}
                  </Text>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
}
