import { Accordion, AccordionItem } from '@nextui-org/react';
import type { A_LEVEL_Curriculum } from '@simppl/repository/test/res';

export function Alevel(data: A_LEVEL_Curriculum['A-LEVEL']) {
  return Object.values(data).map((item) => (
    <div
      className='pl-8'
      key={item.name}
    >
      <Accordion>
        <AccordionItem title={item.name}>
          <tr className='h-10'>
            <th className='w-20'>점수</th>
            <td>{item.aLevelScore}</td>
          </tr>
        </AccordionItem>
      </Accordion>
    </div>
  ));
}
