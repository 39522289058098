import { ErrorMessage } from '@hookform/error-message';
import Text from '@simppl/component/Text';
import getImgUrl from '@simppl/util/getImgUrl';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { UploadFile } from './UploadFile';
import { UploaderPopUp } from './UploaderPopUp';

export function UploaderButton({
  name,
  testName,
  testType,
}: {
  name: string;
  testName: string;
  testType: string;
}) {
  const [open, setOpen] = useState(false);
  const methods = useFormContext();
  return (
    <div className='border-Blue-Ct-2 relative flex h-[118px] w-full flex-col items-center justify-center gap-[16px] rounded-[16px] border-[1px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${name}.transcript`}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
      <div
        className=' flex cursor-pointer gap-[8px]'
        onClick={() => setOpen(true)}
      >
        <img
          src={getImgUrl('/asset/Button/Upload.svg')}
          alt='upload'
        />
        {methods.watch(`${name}.transcript`) ? (
          <Text
            color='text-blue-500'
            weight='Medium'
            as='Title'
            size={1}
          >
            {methods.watch(`${name}.transcript`)?.length > 0
              ? decodeURIComponent(methods.watch(`${name}.transcript`).split('/').at(-1))
              : '고교성적 증명서.pdf'}
          </Text>
        ) : (
          <Text
            color='text-blue-500'
            weight='Medium'
            as='Title'
            size={1}
          >
            파일 첨부
          </Text>
        )}
      </div>
      {!methods.watch(`${name}.transcript`) && (
        <Text
          color='text-blue-500'
          weight='Medium'
          as='Body'
          size={2}
        >
          증빙서류로 성적표 파일이 반드시 첨부되어야 하며, 8MB 이하의 단일 pdf 파일로 합쳐서 제출해주세요.
        </Text>
      )}
      <UploaderPopUp
        name={name}
        open={open}
        onClose={() => setOpen(false)}
      />
      <UploadFile
        name={name}
        testName={testName}
        testType={testType}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}
