import SwitchCase from '@simppl/component/SwitchCase';

import { ACTForm } from './ACT';
import { ALevelForm } from './ALevel';
import { APForm } from './AP';
import { IBForm } from './IB';
import { SATForm } from './SAT';

type StandardFormProps = {
  testName: string;
};
export function StandardForm({ testName }: StandardFormProps) {
  const content = 'edit';
  return (
    <SwitchCase
      value={testName}
      caseBy={{
        ACT: <ACTForm content={content} />,
        'A-LEVEL': <ALevelForm content={content} />,
        AP: <APForm content={content} />,
        SAT: <SATForm content={content} />,
        IB: <IBForm content={content} />,
      }}
    />
  );
}
