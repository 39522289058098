import useQuery from '@simppl/react-query/useQuery';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { faq } from '@simppl/repository/faq';
import type { GetNoticesReqType } from '@simppl/repository/notice/req';

export const useFaqs = (body: GetNoticesReqType) => {
  const { data } = useSuspenseQuery({
    queryKey: ['getFaqs', body.page, body.search],
    queryFn: () => faq.get(body),
    queryOptions: {
      keepPreviousData: true,
    }
  });
  return { total: data.total, posts: data.posts };
};
