/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useUsers } from '@hook/useUsers';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import { paymentsHistories } from '@simppl/repository/paymentHistories';
import { useState } from 'react';

type ItemModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  itemId: string;
};

export function AddItemModal(props: ItemModalProps) {
  const users = useUsers();
  const [user, setUser] = useState<string>('');

  const postUserPaymentHook = useMutation(paymentsHistories.post);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>유저에게 상품 추가</ModalHeader>
            <ModalBody>
              <Autocomplete
                label='유저를 선택해주세요.'
                className='max-w-xs'
                onSelectionChange={(value) => {
                  setUser(value as string);
                }}
              >
                {users.map((user) => (
                  <AutocompleteItem
                    key={user.id}
                    value={user.id}
                  >
                    {user.name}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  postUserPaymentHook.mutate(
                    {
                      userId: user,
                      itemId: props.itemId,
                    },
                    {
                      onSuccess: () => {
                        props.onClose();
                      },
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
