export function Activity(data: Activity) {
  return [
    {
      title: '업로드 url',
      type: 'link',
      content: data.transcript,
    },
    {
      title: '활동 시작일자',
      content: data.startDate,
    },
    {
      title: '활동 종료일자',
      content: data.endDate,
    },
    {
      title: '발행기관',
      content: data.publishingOrganization,
    },
    {
      title: '교내/교외',
      content: data.division,
    },
    {
      title: '성격',
      content: data.type,
    },
    {
      title: '내용',
      content: data.content,
    },
  ];
}
