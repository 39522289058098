import { ErrorMessage } from '@hookform/error-message';
import SelectBox from '@simppl/component/SelectBox';
import c from '@simppl/util/c';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { type CommonType } from './CommonType';

export type Select = CommonType & {
  defaultValue?: string;
  className?: string;
  type: 'select';
  category: Record<string, string>;
  placeholder?: string;
  size?: 'short' | 'long';
  onChange?: (value: string) => void;
  disabled?: boolean;
};

type SelectProps = Select & { name: string };

export function Select({
  defaultValue,
  content,
  category,
  name,
  placeholder,
  size = 'short',
  className,
  onChange,
  disabled = false,
}: SelectProps) {
  const methods = useFormContext();

  useEffect(() => {
    methods.register(name);
    if (defaultValue && !methods.watch(name)) {
      methods.setValue(name, defaultValue);
    }
  }, [methods, defaultValue, name]);

  return (
    <div className='relative'>
      <SelectBox
        disabled={disabled}
        className={c(
          'border-blue-500 text-blue-500',
          size === 'short' ? '' : 'w-[252px]',
          className,
        )}
        selectedColor='text-blue-500'
        currentValue={methods.watch(name) ?? content ?? placeholder}
        options={category}
        onClick={(value) => {
          methods.setValue(name, value);
          onChange?.(value);
        }}
      />
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute top-[40px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}
