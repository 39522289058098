/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-shadow */
import { Input } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import { ErrorMessage } from '@hookform/error-message';
import useMutation from '@simppl/react-query/useMutation';
import {
  deleteTestById,
  deleteUserReportCardDetails,
  postReportCardSubjects,
  reportCardDetail,
  reportCardDetailAdmin,
} from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type ETCFormProps = {
  content: string;
};

export function ETCForm({ content }: ETCFormProps) {
  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${content}.reportCardDetailList`,
  });

  return (
    <>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${content}.reportCardDetailList`}
        render={({ message }) => <p className='text-[12px] text-red-600'>{message}</p>}
      />
      <div className='flex w-full flex-col gap-2'>
        {fields.map(({ id }, index) => (
          <Form
            content={content}
            index={index}
            remove={remove}
            key={id}
          />
        ))}
      </div>
      <div className='pl-[148px]'>
        <button
          type='button'
          className='flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]'
          onClick={() =>
            append({
              reportCardSubjectId: null,
              totalScore: null,
              etcScore: null,
            })
          }
        >
          <Plus color='white' />
          과목추가 (선택)
        </button>
      </div>
    </>
  );
}

type FormProps = {
  content: string;
  index: number;
  remove: (index: number) => void;
};

function Form({ content, index, remove }: FormProps) {
  const methods = useFormContext();
  const subjectIds = useReportCardSubjectsIds();
  const postReportCardSubjectsHook = useMutation((name: string) => postReportCardSubjects(name));
  const deleteUserReportCardDetailHook = useMutation(reportCardDetailAdmin.delete);

  useEffect(() => {
    const reportCardSubjectId =
      methods.watch(`${content}.reportCardDetailList.${index}.reportCardSubjectId`) ?? null;
    const name = methods.watch(`${content}.reportCardDetailList.${index}.name`) ?? null;

    if (reportCardSubjectId === null && name !== null) {
      methods.setValue(
        `${content}.reportCardDetailList.${index}.reportCardSubjectId`,
        subjectIds[name as keyof typeof subjectIds] ?? '',
      );
    }
    if (reportCardSubjectId !== null && name === null) {
      methods.setValue(
        `${content}.reportCardDetailList.${index}.name`,
        subjectIds[reportCardSubjectId as keyof typeof subjectIds],
      );
    }
  }, []);

  return (
    <div className='mr-[82px] flex justify-end gap-[24px]'>
      <Controller
        name={`${content}.reportCardDetailList.${index}`}
        control={methods.control}
        render={({ field }) => (
          <>
            <div className='flex justify-end gap-[24px]'>
              <>
                <Input
                  type='input'
                  {...field}
                  content='name'
                  alterText='과목명'
                  inputType='stringInput'
                  size={155}
                  name={`${field.name}.name`}
                  onBlur={() => {
                    setTimeout(() => {
                      const reportCardSubjectId = methods.watch(
                        `${field.name}.reportCardSubjectId`,
                      );
                      if (reportCardSubjectId) {
                        deleteTestById('report-cards', reportCardSubjectId);
                      } else {
                        const name = methods.watch(`${field.name}.name`);
                        postReportCardSubjectsHook.mutate(name, {
                          onSuccess: ({ reportCardSubjectId }) => {
                            methods.setValue(
                              `${field.name}.reportCardSubjectId`,
                              reportCardSubjectId,
                            );
                          },
                        });
                      }
                    }, 100);
                  }}
                />
                <Input
                  type='input'
                  {...field}
                  content='etcScore'
                  alterText='점수'
                  size={155}
                  defaultValue='0'
                  name={`${field.name}.etcScore`}
                  inputType='stringInput'
                  onBlur={() => {
                    methods.setValue(
                      `${field.name}.etcScore`,
                      methods.watch(`${field.name}.etcScore`).toString(10),
                    );
                  }}
                />
                <Input
                  type='input'
                  {...field}
                  content='totalScore'
                  alterText='만점'
                  size={155}
                  defaultValue='0'
                  name={`${field.name}.totalScore`}
                  inputType='stringInput'
                  onBlur={() => {
                    methods.setValue(
                      `${field.name}.totalScore`,
                      methods.watch(`${field.name}.totalScore`).toString(10),
                    );
                  }}
                />
              </>
            </div>
            <button
              className=' flex   h-[40px]   min-w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700'
              type='button'
              onClick={() => {
                const reportCardDetailId = methods.watch(
                  `${content}.reportCardDetailList.${index}.reportCardDetailId`,
                );
                if (reportCardDetailId)
                  deleteUserReportCardDetailHook.mutate(
                    { reportCardDetailId },
                    {
                      onSuccess: () => remove(index),
                    },
                  );
                else {
                  remove(index);
                }
              }}
            >
              <Minus />
            </button>
          </>
        )}
      />
    </div>
  );
}
