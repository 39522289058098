import Text from '@simppl/component/Text';
import { useFormContext } from 'react-hook-form';

type SatViewProps = {
  content: string;
};

export function SatView({ content }: SatViewProps) {
  const methods = useFormContext();
  const data = methods.watch(`${content}`);

  return (
    <div className='flex flex-col justify-center'>
      <div className='flex justify-between'>
        <div className='w-[240px] px-[16px] py-[8px]'>
          <Text
            color='text-blue-700'
            weight='Medium'
            as='Label'
            size={1}
          >
            Reading/Writing
          </Text>
        </div>
        <div className='flex w-[112px] justify-between px-[16px] py-[8px]'>
          <Text
            color='text-blue-500'
            weight='Regular'
            as='Label'
            size={1}
          >
            점수
          </Text>
          <Text
            color='text-Blue-5'
            weight='Regular'
            as='Label'
            size={1}
          >
            {data.ebrw}
          </Text>
        </div>
      </div>
      <div className='flex justify-between'>
        <div className='w-[240px] px-[16px] py-[8px]'>
          <Text
            color='text-blue-700'
            weight='Medium'
            as='Label'
            size={1}
          >
            math
          </Text>
        </div>
        <div className='flex w-[112px] justify-between px-[16px] py-[8px]'>
          <Text
            color='text-blue-500'
            weight='Regular'
            as='Label'
            size={1}
          >
            점수
          </Text>
          <Text
            color='text-Blue-5'
            weight='Regular'
            as='Label'
            size={1}
          >
            {data.math}
          </Text>
        </div>
      </div>
    </div>
  );
}
