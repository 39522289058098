import SwitchCase from '@simppl/component/SwitchCase';

import { DeleView } from './DELE';
import { DelfView } from './DELF';
import { EtcView } from './ETC';
import { HskView } from './HSK';
import { IeltsView } from './IELTS';
import { TepsView } from './TEPS';
import { ToeflView } from './TOEFL';
import { ToeicView } from './TOEIC';

type LanguageFormProps = {
  testName: string;
  content: string;
};
export function LanguageListView({ testName, content }: LanguageFormProps) {
  return (
    <SwitchCase
      value={testName}
      caseBy={{
        ETC: <EtcView content={content} />,
        TOEIC: <ToeicView content={content} />,
        TOEFL: <ToeflView content={content} />,
        TEPS: <TepsView content={content} />,
        DELE: <DeleView content={content} />,
        DELF: <DelfView content={content} />,
        HSK: <HskView content={content} />,
        IELTS: <IeltsView content={content} />,
      }}
    />
  );
}
