import Text from '@simppl/component/Text';
import type { HighSchoolTestResType } from '@simppl/repository/test/res';

type KoreaViewProps = {
  reportCardDetailList: HighSchoolTestResType['reportCardList'][0]['reportCardDetailList'];
};

export function KoreaView({ reportCardDetailList }: KoreaViewProps) {
  return (
    <div className='flex w-[360px] flex-col items-center justify-center'>
      {/* {data.map((reportCard: any) => (
        <div
          key={reportCard.reportCardSubjectId}
          className='flex justify-between'
        >
          <div className='w-[240px] px-[16px] py-[8px]'>
            <Text
              color='text-blue-700'
              weight='Medium'
              as='Label'
              size={1}
            >
              {reportCard.name}
            </Text>
          </div>
          <div className='flex w-[112px] justify-between px-[16px] py-[8px]'>
            <Text
              color='text-blue-500'
              weight='Regular'
              as='Label'
              size={1}
            >
              점수
            </Text>
            <Text
              color='text-Blue-5'
              weight='Regular'
              as='Label'
              size={1}
            >
              {reportCard.etcScore}
            </Text>
          </div>
        </div>
      ))} */}
      <Text
        className=' whitespace-pre text-center'
        color='text-Blue-5'
        weight='Regular'
        as='Label'
        size={1}
      >
        {`한국학교의 경우, 제출하신 성적표로\n 성적 입력을 진행하게 됩니다.`}
      </Text>
    </div>
  );
}
