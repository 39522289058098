import Text from '@simppl/component/Text';
import c from '@simppl/util/c';
import type { PropsWithChildren } from 'react';

import { Edit } from './icon';

type ListViewProps = {
  type?: 'highSchool' | 'etc';
  semester: string;
  testName: string;
  countOfSubjects: number;
  score: number;
  onClick?: () => void;
} & PropsWithChildren;
export function ListView({
  testName,
  onClick,
  semester,
  countOfSubjects,
  score,
  type = 'highSchool',
  children,
}: ListViewProps) {
  return (
    <div
      className='relative flex min-h-[248px] w-[784px] items-center justify-between rounded-[8px] border-[0.5px] border-[#B0C4E4] px-[23px] py-[24px]'
      onClick={onClick}
    >
      <button className='absolute right-[23px] top-[24px] flex h-[40px] items-center justify-center'>
        <Edit />
      </button>
      <div className='flex h-full w-full justify-between px-[26px]'>
        <div
          className={c(
            'flex h-[176px] w-[176px] flex-col items-center justify-between gap-[16px]  text-center',
            type !== 'highSchool' ? 'py-[26px]' : '',
          )}
        >
          {type === 'highSchool' && (
            <>
              <Text
                color='text-Blue-4'
                weight={0}
                as='Body'
                size={1}
              >
                {semester}
              </Text>
              <div className='h-[2px] w-[46px] bg-[#E6ECF6]' />
            </>
          )}
          <Text
            color='text-blue-600'
            weight={0}
            as='Title'
            size={2}
          >
            {testName}
          </Text>
          <div className='flex w-full justify-center gap-[32px]'>
            <div className='flex flex-col gap-[8px]'>
              <Text
                color='text-Blue-4'
                weight={0}
                as='Body'
                size={1}
              >
                과목 수
              </Text>
              <div className='h-[2px] w-[46px] bg-[#E6ECF6]' />
              <Text
                color='text-Blue-4'
                weight={0}
                as='Title'
                size={2}
              >
                {countOfSubjects}
              </Text>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <Text
                color='text-Blue-4'
                weight={0}
                as='Body'
                size={1}
              >
                총점
              </Text>
              <div className='h-[2px] w-[46px] bg-[#E6ECF6]' />
              <Text
                color='text-Blue-4'
                weight={0}
                as='Title'
                size={2}
              >
                {score}
              </Text>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
