import { CompetitiveUnitModal } from '@component/admission/CompetitiveUnitModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { competitiveUnits } from '@simppl/repository/competitiveUnits';
import { Search } from 'lucide-react';
import { useState } from 'react';

export function CompetitiveUnitPage() {
  const { data } = useCompetitiveUnits();
  const simulatedApplicationMap = useSimulatedApplication().data.reduce(
    (acc, item) => ({ ...acc, [item.simulatedApplicationId]: item.name }),
    {},
  );

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(data, (userPayment, value) => userPayment.name?.includes(value));

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; competitiveUnitId: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const deleteUserPaymentsHook = useMutation(competitiveUnits.delete);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && (
          <CompetitiveUnitModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>경쟁단위 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      setModalState({ mode: 'add' });
                      onOpen();
                    }}
                  >
                    경쟁단위 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='userName'>이름</TableColumn>
              <TableColumn key='userName'>전형 이름</TableColumn>
              <TableColumn key='userName'>모집 인원</TableColumn>
              <TableColumn key='userName'>high 비율</TableColumn>
              <TableColumn key='userName'>middle 비율</TableColumn>
              <TableColumn key='userName'>low 비율</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={items}>
              {(item) => (
                <TableRow
                  as={Link}
                  href={`/admission/competitiveUnit/${item.competitiveUnitId}`}
                  className='relative cursor-pointer'
                  key={item.competitiveUnitId}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {simulatedApplicationMap[item.simulatedApplicationId as never]}
                  </TableCell>
                  <TableCell>{item.admissionCapacity}</TableCell>
                  <TableCell>{item.high}</TableCell>
                  <TableCell>{item.middle}</TableCell>
                  <TableCell>{item.low}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              competitiveUnitId: item.competitiveUnitId,
                            });
                            onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            deleteUserPaymentsHook.mutate(
                              { competitiveUnitId: item.competitiveUnitId },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['getCompetitiveUnits']);
                                },
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
