import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getNoticesId } from '@simppl/repository/notice';
import type { GetNoticesIdReqType } from '@simppl/repository/notice/req';

export const useNoticeId = (body: GetNoticesIdReqType) => {
  const { data } = useSuspenseQuery({
    queryKey: [`getNotices/${body.id}`],
    queryFn: () => getNoticesId(body),
  });
  return { data };
};
