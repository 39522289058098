/* eslint-disable no-shadow */

/* eslint-disable react-hooks/exhaustive-deps */
import { type PropsWithChildren, createContext, useMemo, useState } from 'react';

type ModeType = 'noData' | 'editSchool' | 'editScore' | 'viewTest';
type HighSchoolModeContextType = {
  mode: ModeType;
  modeState: any;
  changeMode: (mode: ModeType, state?: any) => void;
};

export const HighSchoolModeContext = createContext<HighSchoolModeContextType>({
  mode: 'noData',
  modeState: null,
  changeMode: () => null,
});

export const HighSchoolModeProvider = ({
  children,
  mode,
  setMode,
}: PropsWithChildren<{
  mode: HighSchoolModeContextType['mode'];
  setMode: React.Dispatch<React.SetStateAction<HighSchoolModeContextType['mode']>>;
}>) => {
  const [modeState, setModeState] = useState<any>();
  const changeMode = (mode: ModeType, state: any) => {
    setMode(mode);
    setModeState(state);
  };

  const value = useMemo(
    () => ({
      mode,
      modeState,
      changeMode,
    }),
    [mode, modeState, changeMode],
  );

  return <HighSchoolModeContext.Provider value={value}>{children}</HighSchoolModeContext.Provider>;
};
