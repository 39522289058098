import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { reportCards } from '@simppl/repository/test';

const useHighschoolScore = () => {
  const data = useSuspenseQuery({
    queryKey: [`report-cards`],
    queryFn: () => reportCards.get(),
    queryOptions: {
      keepPreviousData: true,
    },
  });
  return data.data.data;
};

export default useHighschoolScore;
