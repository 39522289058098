
import useQuery from '@simppl/react-query/useQuery';
import { getBoardsByBoardIds } from '@simppl/repository/admins';


export const usePostsByBoards = (ids: string[]) => {

  const { data } = useQuery(['posts', JSON.stringify(ids)],
    () => getBoardsByBoardIds({ boardIds: ids }),
    { enabled: !!ids.length, }
  );


  return data?.data.boards ?? [];
};
