import Text from '@simppl/component/Text';
import c from '@simppl/util/c';

type AddSubjectViewProps = {
  mode: 'card' | 'list';
  onClick?: () => void;
  title: string;
};
export function AddSubjectView({ title, onClick, mode }: AddSubjectViewProps) {
  return (
    <div
      className={c(
        'flex h-[248px] cursor-pointer flex-col items-center justify-center gap-[8px] rounded-[8px] border-[0.5px] border-dashed border-[#B0C4E4] px-[32px] py-[24px]',
        mode === 'list' ? 'w-[784px]' : 'w-[240px]',
      )}
      onClick={onClick}
    >
      <div className='flex flex-col items-center gap-[16px]'>
        <svg
          width='36'
          height='36'
          viewBox='0 0 36 36'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            width='36'
            height='36'
            rx='18'
            fill='#F8F9FC'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.3354 11.3333C19.3354 10.597 18.7385 10 18.0021 10C17.2657 10 16.6688 10.597 16.6688 11.3333V16.6677H11.3333C10.597 16.6677 10 17.2647 10 18.001C10 18.7374 10.597 19.3344 11.3333 19.3344H16.6687V24.6667C16.6687 25.403 17.2657 26 18.0021 26C18.7385 26 19.3354 25.403 19.3354 24.6667V19.3344H24.6667C25.403 19.3344 26 18.7374 26 18.001C26 17.2647 25.403 16.6677 24.6667 16.6677H19.3354V11.3333Z'
            fill='#1F64DC'
          />
        </svg>
        <Text
          color='text-blue-500'
          weight={0}
          as='Title'
          size={2}
        >
          {title}
        </Text>
      </div>
    </div>
  );
}
