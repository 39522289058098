/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import ShadowDOM from '@component/ShadowDOM';
import { useInquiriesAnswers } from '@hook/useInquiriesAnswers';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { inquiryAnswer } from '@simppl/repository/inquiries';
import { useEffect, useState } from 'react';

type InquiryAnswerModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  id: string;
};

export function InquiryAnswerModal(props: InquiryAnswerModalProps) {
  const [content, setContent] = useState('' as string);
  const answer = props.id ? useInquiriesAnswers({ id: props.id }) : undefined;

  const queryClient = useQueryClient();

  const postItemHook = useMutation(inquiryAnswer.post);
  const patchItemHook = useMutation(inquiryAnswer.patch);

  useEffect(() => {
    if (answer && content === '' && answer.data.answer) {
      setContent(answer.data.answer.content);
    }
  }, [props, answer]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>답변 달기</ModalHeader>
            <ModalBody>
              <Card>
                <CardHeader>
                  <h4>문의 내용</h4>
                </CardHeader>
                <CardBody>
                  <ShadowDOM html={answer?.data.content ?? ''} />
                </CardBody>
              </Card>
              <Editor
                initialData={content}
                onChange={setContent}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (answer && answer.data.answer)
                    patchItemHook.mutate(
                      {
                        id: props.id,
                        answer: content,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getInquiriesAnswers', props.id]);
                          props.onClose();
                        },
                      },
                    );
                  else
                    postItemHook.mutate(
                      {
                        id: props.id,
                        answer: content,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getInquiriesAnswers', props.id]);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
