import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getInquiries, inquiryAnswer } from '@simppl/repository/inquiries';
import type { GetInquiriesAnswersReqType } from '@simppl/repository/inquiries/req';

export const useInquiriesAnswers = (body: GetInquiriesAnswersReqType) => {
  const {
    data: { data },
  } = useSuspenseQuery({
    queryKey: ['getInquiriesAnswers', body.id],
    queryFn: () => inquiryAnswer.get(body),
  });
  return { data };
};
