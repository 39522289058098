import Text from '@simppl/component/Text';
import { useFormContext } from 'react-hook-form';

type IeltsViewProps = {
  content: string;
};

export function IeltsView({ content }: IeltsViewProps) {
  const methods = useFormContext();
  const data = methods.watch(`${content}`);

  return (
    <div className='flex flex-col justify-between'>
      {Object.entries({
        listening: data.listening,
        reading: data.reading,
        writing: data.writing,
        speaking: data.speaking,
      }).map(([key, value]) => (
        <div
          className='flex justify-between'
          key={key}
        >
          <div className='w-[240px] px-[16px] py-[8px]'>
            <Text
              color='text-blue-700'
              weight='Medium'
              as='Label'
              size={1}
            >
              {key}
            </Text>
          </div>
          <div className='flex w-[112px] justify-between px-[16px] py-[8px]'>
            <Text
              color='text-blue-500'
              weight='Regular'
              as='Label'
              size={1}
            >
              점수
            </Text>
            <Text
              color='text-Blue-5'
              weight='Regular'
              as='Label'
              size={1}
            >
              {value}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
}
