/* eslint-disable no-shadow */
import {
  UserAdmissionInterestTab,
  UserAdmissionTab,
  UserDefaultTab,
  UserScoreTab,
} from '@component/user/detail';
import { useUsers } from '@hook/useUsers';
import { Card, CardBody, CardHeader, Divider, Tab, Tabs } from '@nextui-org/react';
import { createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function UserDetailPage() {
  const { id } = useParams();
  const [selected, setSelected] = useState<'default' | 'score' | 'admissions' | 'application'>(
    'default',
  );
  const users = useUsers();
  const user = users.find((user) => user.id === id);

  if (!user) {
    return <div>유저 정보가 없습니다.</div>;
  }

  return (
    <div className='flex min-h-[80%] flex-col'>
      <UserIdProvider userId={id ?? ''}>
        <Card className='grow'>
          <CardHeader className='flex justify-between px-8'>
            <div className='flex items-center'>
              <h1 className='text-2xl'>유저 정보</h1>
              <p>-</p>
              <p>{user.name}</p>
            </div>
          </CardHeader>
          <Divider />
          <CardBody>
            <Tabs
              selectedKey={selected}
              onSelectionChange={(key) =>
                setSelected(key as 'default' | 'score' | 'admissions' | 'application')
              }
            >
              <Tab
                key='default'
                title='기본 정보'
              >
                <UserDefaultTab />
              </Tab>
              <Tab
                key='score'
                title='성적'
              >
                <UserScoreTab />
              </Tab>
              <Tab
                key='admissions'
                title='관심 대학'
              >
                <UserAdmissionInterestTab />
              </Tab>
              <Tab
                key='application'
                title='모의 지원'
              >
                <UserAdmissionTab />
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </UserIdProvider>
    </div>
  );
}

export const UserIdContext = createContext<{ userId: string }>({
  userId: '',
});

function UserIdProvider({ children, userId }: { children: React.ReactNode; userId: string }) {
  const value = useMemo(
    () => ({
      userId,
    }),
    [userId],
  );

  return <UserIdContext.Provider value={value}>{children}</UserIdContext.Provider>;
}
