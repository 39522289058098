import getImgUrl from '@simppl/util/getImgUrl';

type ListProps = {
  disabled?: boolean;
};

export function List({ disabled = false }: ListProps) {
  return (
    <img
      src={getImgUrl(`/asset/Button/List${disabled ? '-disabled' : ''}.svg`)}
      alt='리스트형'
    />
  );
}
