import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { items } from '@simppl/repository/items';

export const useItem = () => {
  const { data } = useSuspenseQuery({
    queryKey: ['items'],
    queryFn: () => items.get(),
  });

  return { data };
};
