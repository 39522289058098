/* eslint-disable no-shadow */

/* eslint-disable react-hooks/exhaustive-deps */
import { type PropsWithChildren, createContext, useMemo, useState } from 'react';

export type ModeType = 'editScore' | 'viewTest';
type HighSchoolModeContextType = {
  mode: ModeType;
  modeState: any;
  changeMode: (mode: ModeType, state?: any) => void;
};

export const StandardTestScoreModeContext = createContext<HighSchoolModeContextType>({
  mode: 'viewTest',
  modeState: null,
  changeMode: () => null,
});

export const StandardTestScoreModeProvider = ({
  children,
  mode,
  setMode,
}: PropsWithChildren<{
  mode: HighSchoolModeContextType['mode'];
  setMode: React.Dispatch<React.SetStateAction<HighSchoolModeContextType['mode']>>;
}>) => {
  const [modeState, setModeState] = useState<any>();
  const changeMode = (mode: ModeType, state: any) => {
    setMode(mode);
    setModeState(state);
  };

  const value = useMemo(
    () => ({
      mode,
      modeState,
      changeMode,
    }),
    [mode, modeState, changeMode],
  );

  return (
    <StandardTestScoreModeContext.Provider value={value}>
      {children}
    </StandardTestScoreModeContext.Provider>
  );
};
