/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useReportCardSubjects } from '@hook/useReportCardSubjects';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { reportCardSubjects } from '@simppl/repository/report-card-subjects';
import { useEffect, useState } from 'react';

type HighSchoolSubjectModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
    | {
      mode: 'add';
    }
    | { mode: 'edit'; subjectId: string }
  );

const subjectMap = {
  AP: 'AP',
  'A-LEVEL': 'A-LEVEL',
  IB: 'IB',
};

export function HighSchoolSubjectModal(props: HighSchoolSubjectModalProps) {
  const [name, setName] = useState('');
  const [isApprove, setIsApprove] = useState(false);

  const item =
    props.mode === 'edit' ? useReportCardSubjects().find((v) => v.id === props.subjectId) : null;

  const queryClient = useQueryClient();

  const postItemHook = useMutation(reportCardSubjects.post);
  const patchItemHook = useMutation(reportCardSubjects.patch);

  useEffect(() => {
    if (props.mode === 'edit' && item) {
      setName(item.name);
      setIsApprove(item.isApprove);
    }
  }, [props]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '표준화 과목 추가' : '표준화 과목 수정'}
            </ModalHeader>
            <ModalBody>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                label='과목명'
                labelPlacement='outside'
              />
              <div className='flex gap-4'>
                {props.mode === 'edit' && (
                  <>
                    <p>승인 여부</p>
                    <Switch
                      defaultSelected={false}
                      color='default'
                      isSelected={isApprove}
                      onValueChange={setIsApprove}
                    />
                  </>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postItemHook.mutate(
                      {
                        name,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['reportCardSubjects']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && item?.id)
                    patchItemHook.mutate(
                      {
                        reportCardSubjectId: props.subjectId.toString(),
                        name,
                        isApprove,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['reportCardSubjects']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
