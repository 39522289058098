import { type PropsWithChildren, createContext, useCallback, useMemo, useState } from 'react';

type ScoreBlocksProps<TData extends string | number | symbol> = {
  list: Record<TData, TData>;
};

type ScoreBlockContextType<TData extends string = any> = {
  category: Map<TData, boolean>;
  list: Record<TData, TData>;

  handleCheck: (test: TData) => void;
  handleChange: (prev: TData, next: TData) => void;
  handleRemove: (test: TData) => void;
  handleReset: () => void;
};

export const scoreBlockContext = createContext<ScoreBlockContextType>({
  category: new Map(),
  list: {},
  handleCheck: () => null,
  handleChange: () => null,
  handleRemove: () => null,
  handleReset: () => null,
});

function ScoreBlockProvider<TData extends string>({
  children,
  list,
}: PropsWithChildren<ScoreBlocksProps<TData>>) {
  const [category, setCategory] = useState<Map<TData, boolean>>(new Map());

  const handleCheck = useCallback(
    (test: TData) =>
      setCategory((prev) => {
        if (prev.has(test)) {
          const newState = new Map(prev);
          newState.delete(test);
          return newState;
        }

        return new Map([...prev, [test, !prev.has(test)]]);
      }),
    [],
  );

  const handleRemove = useCallback(
    (test: TData) =>
      setCategory((prev) => {
        if (category.has(test)) {
          const newState = new Map(prev);
          newState.delete(test);
          return newState;
        }
        return prev;
      }),
    [category],
  );

  const handleChange = useCallback(
    (prevTest: TData, nextTest: TData) =>
      setCategory((prev) => {
        if (!category.has(prevTest)) return prev;

        const newState = new Map(prev);
        newState.delete(prevTest);

        if (category.has(nextTest)) {
          return newState;
        }

        return new Map([...newState, [nextTest, !category.has(nextTest)]]);
      }),
    [category],
  );

  const handleReset = useCallback(() => setCategory(new Map()), []);

  const value = useMemo(
    () => ({
      category,
      list,
      handleCheck,
      handleChange,
      handleRemove,
      handleReset,
    }),
    [list, handleCheck, category, handleChange, handleRemove, handleReset],
  );

  return (
    <scoreBlockContext.Provider value={value}>
      <div className='flex flex-col gap-20'>{children}</div>
    </scoreBlockContext.Provider>
  );
}

export function ScoreBlock<TData extends string>({
  children,
  list,
}: PropsWithChildren<ScoreBlocksProps<TData>>) {
  return <ScoreBlockProvider list={list}>{children}</ScoreBlockProvider>;
}
