/* eslint-disable no-extra-boolean-cast */
import { Combobox } from '@headlessui/react';
import { ErrorMessage } from '@hookform/error-message';
import c from '@simppl/util/c';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { type CommonType } from './CommonType';
import { Title } from './Title';

export type ComboBox = CommonType & {
  type: 'combobox';
  placeholder?: string;
  category: string[];
  onChange?: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
};

type ComboBoxProps = ComboBox & {
  name: string;
  titleDisable?: boolean;
  forceDisable?: boolean;
  useFlag?: boolean;
};

export function ComboBox({
  name,
  content,
  category,
  titleDisable = false,
  forceDisable = false,
  placeholder = '',
  onChange,
  onBlur = undefined,
  disabled,
  useFlag = true,
}: ComboBoxProps) {
  const [flag, setFlag] = useState(false);
  const methods = useFormContext();

  const filteredCategory = category.filter((option) =>
    option.toLowerCase().includes(methods.watch(name)?.toLowerCase()),
  );

  return (
    <div>
      {(category.includes(methods.watch(name)) && titleDisable === false) ||
      flag ||
      forceDisable ? (
        <div className='relative'>
          <Title
            size='long'
            content={methods.watch(name)}
            type='title'
          />
          <ErrorMessage
            errors={methods.formState.errors}
            name={name}
            render={({ message }) => (
              <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
            )}
          />
        </div>
      ) : (
        <div
          className={c(
            'relative flex h-[40px] w-[252px] items-center justify-between rounded-[4px] border-[1px] border-blue-600',
          )}
        >
          <Combobox
            value={content}
            onChange={(value) => {
              methods.setValue(name, value);
              onChange?.(value || '');
              onBlur?.();
            }}
            disabled={disabled}
          >
            <Combobox.Input
              className='h-full w-full text-ellipsis rounded-[4px] pl-[16px] text-blue-600 outline-none'
              placeholder={placeholder}
              autoComplete='off'
              onChange={(event) => methods.setValue(name, event.target.value)}
              onBlur={(event) => {
                if (!!onBlur) {
                  onBlur();
                  if (useFlag && event.target.value !== '') {
                    setFlag(true);
                  }
                }
                return event;
              }}
            />
            <Combobox.Options className='absolute left-0 top-[40px] z-[999] flex max-h-[300px] w-full cursor-pointer flex-col gap-[8px] overflow-auto rounded-[4px] border-[0.5px] border-[#D6E0FD] bg-[#FFFFFF]'>
              {filteredCategory.map((option) => (
                <Combobox.Option
                  className='font-Pretendard-Regular text-Base-Black p-[8px] hover:bg-[#D6E0FD]'
                  key={option}
                  value={option}
                  onClick={() => {
                    methods.setValue(name, option);
                  }}
                >
                  {option}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox>
          {/* <ErrorMessage
            errors={methods.formState.errors}
            name={name}
            render={({ message }) => (
              <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
            )}
          /> */}
        </div>
      )}
    </div>
  );
}
