import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getUserAdmissions } from '@simppl/repository/admission';
import type { GetUserAdmissionsReqType } from '@simppl/repository/admission/req';

export const useUserAdmissions = (body: GetUserAdmissionsReqType) => {
  const { data } = useSuspenseQuery({
    queryKey: ['useUserAdmissions'],
    queryFn: () => getUserAdmissions(body),
  });

  return { resultList: data };
};
