/* eslint-disable no-alert */
import { BackButton } from '@component/score/common';
import { ComboBox } from '@component/score/common/form/ComboWithNoTItle';
import { useCountries } from '@hook/useCountries';
import { useHighschools } from '@hook/useHighschools';
import useTestByUserId from '@hook/useTestByUserId';
import { highSchoolSchemaMap } from '@schema/highSchool';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { postHighschools } from '@simppl/repository/highschools';
import type { PostHighschoolsReq } from '@simppl/repository/highschools/req';
import { reportCards, reportCardsAdmin } from '@simppl/repository/test';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { HighSchoolModeContext } from './Provider';

type EditHighSchoolProps = {
  removeHighSchool: Function;
  updateHighSchool: Function;
};

export function EditHighSchool({ removeHighSchool, updateHighSchool }: EditHighSchoolProps) {
  const methods = useFormContext();
  const formName = 'editHighSchool';
  const { modeState, changeMode } = useContext(HighSchoolModeContext);
  const { appendHighSchool, mode, editData } = modeState;
  const queryClient = useQueryClient();

  const { id } = useParams();
  const { reportCardList } = useTestByUserId('report-cards', id!);

  const { countryNames, countryNameIdMap } = useCountries({ isApprove: true });
  const { highschoolNames, highschoolNameIdMap } = useHighschools({
    countryId: countryNameIdMap[methods.watch(`${formName}.countryName`)],
    isApprove: true,
  });

  const postHighSchoolsHook = useMutation(({ countryId, highschoolName }: PostHighschoolsReq) =>
    postHighschools({ countryId, highschoolName }),
  );

  useEffect(() => {
    if (editData !== undefined && methods.watch(`${formName}.countryName`) === undefined) {
      methods.setValue(`${formName}.countryName`, editData.countryName);
      methods.setValue(`${formName}.schoolName`, editData.schoolName);
    }
  }, []);

  return (
    <div className='border-Blue-Ct-2 relative flex w-full flex-col rounded-[16px] border-[1px] px-[42px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
      <div className='absolute left-[42px] top-[32px]'>
        <BackButton
          onClick={() => {
            if (mode === 'new')
              removeHighSchool(highschoolNameIdMap?.[methods.watch(`${formName}.schoolName`)]);
            if (mode === 'edit') removeHighSchool(editData.highschoolId);
            queryClient.invalidateQueries(['report-cards-testByUserId']);
            changeMode('viewTest');
          }}
        />
      </div>
      <div className='flex w-full flex-col items-center gap-[48px]'>
        <Text
          color='text-blue-500'
          weight='Bold'
          as='Title'
          size={1}
        >
          학교 입력
        </Text>
        <div className='flex gap-[37px]'>
          <>
            <ComboBox
              placeholder='소재 국가 입력'
              content={methods.watch(`${formName}.countryName`)}
              type='combobox'
              category={countryNames}
              name={`${formName}.countryName`}
            />
            <ComboBox
              placeholder='학교명 입력'
              content={methods.watch(`${formName}.schoolName`)}
              type='combobox'
              category={highschoolNames}
              name={`${formName}.schoolName`}
              disabled={!methods.watch(`${formName}.countryName`)}
            />
          </>
        </div>
        <button
          type='button'
          className='h-[40px] w-[172px] rounded-md border-[1px] border-blue-500 bg-blue-500 text-[white]'
          onClick={async () => {
            try {
              const data = methods.watch(formName);

              await highSchoolSchemaMap.validateSync(data);

              const countryId = parseInt(
                countryNameIdMap[methods.watch(`${formName}.countryName`)],
                10,
              );
              const highschoolName = methods.watch(`${formName}.schoolName`);
              if (highschoolNameIdMap?.[highschoolName] !== undefined) {
                if (mode === 'new') {
                  appendHighSchool({
                    highschoolId: highschoolNameIdMap[highschoolName],
                    schoolName: highschoolName,
                    countryId,
                    countryName: methods.watch(`${formName}.countryName`),
                  });
                  changeMode('viewTest');
                }
                if (mode === 'edit') {
                  const res = await Promise.all(
                    reportCardList?.map((reportCard) =>
                      reportCardsAdmin.patch({
                        userId: id!,
                        reportCardId: reportCard.reportCardId,
                        highschoolId: highschoolNameIdMap[highschoolName],
                        gpa: reportCard.gpa,
                        perfectScore: reportCard.perfectScore,
                        grade: reportCard.grade,
                        semester: reportCard.semester,
                        curriculum: reportCard.curriculum,
                      }),
                    ),
                  );

                  if (res.filter((r) => !(r.status === 200 || r.status === 201)).length > 0) {
                    alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                  } else {
                    queryClient.invalidateQueries(['report-cards-testByUserId']);
                    updateHighSchool(editData.index, {
                      highschoolId: highschoolNameIdMap[highschoolName],
                      schoolName: highschoolName,
                      countryId,
                      countryName: methods.watch(`${formName}.countryName`),
                    });
                    changeMode('viewTest');
                  }
                }
              } else if (highschoolName.length > 0) {
                postHighSchoolsHook.mutate(
                  { countryId: countryId.toString(), highschoolName },
                  {
                    onSuccess: async ({ highschoolId }) => {
                      if (highschoolId !== undefined) {
                        if (mode === 'new') {
                          appendHighSchool({
                            highschoolId,
                            schoolName: highschoolName,
                            countryId,
                            countryName: methods.watch(`${formName}.countryName`),
                          });
                          changeMode('viewTest');
                        }

                        if (mode === 'edit') {
                          const res = await Promise.all(
                            reportCardList?.map((reportCard) =>
                              reportCardsAdmin.patch({
                                userId: id!,
                                reportCardId: reportCard.reportCardId,
                                highschoolId,
                                gpa: reportCard.gpa,
                                perfectScore: reportCard.perfectScore,
                                grade: reportCard.grade,
                                semester: reportCard.semester,
                                curriculum: reportCard.curriculum,
                              }),
                            ),
                          );

                          if (
                            res.filter(
                              (r: { status: number }) => !(r.status === 200 || r.status === 201),
                            ).length > 0
                          ) {
                            alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                          } else {
                            queryClient.invalidateQueries(['report-cards-testByUserId']);
                            updateHighSchool(editData.index, {
                              highschoolId,
                              schoolName: highschoolName,
                              countryId,
                              countryName: methods.watch(`${formName}.countryName`),
                            });
                            changeMode('viewTest');
                          }
                        }
                      }
                    },
                  },
                );
              }
            } catch (e: any) {
              alert(e.message);
            }
          }}
        >
          저장
        </button>
      </div>
    </div>
  );
}
