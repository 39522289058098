import * as yup from 'yup';

import { SCHEMA_MESSAGE } from '../message';

export const IELTS_format = [
  {
    subject: 'reading',
    property: [
      {
        content: 'reading',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 9,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'listening',
    property: [
      {
        content: 'listening',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 9,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'speaking',
    property: [
      {
        content: 'speaking',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 9,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'writing',
    property: [
      {
        content: 'writing',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 9,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'score',
    property: [
      {
        content: 'score',
        alterText: '총점',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 9,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
];

export const IELTS_schema = yup
  .object()
  .shape({
    transcript: yup
      .string()
      .required(
        SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '파일을').customMessage('업로드 해주세요.'),
      ),
    examDate: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '시험일자를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '발급기관을').requiredMessage()),
    reading: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '독해점수는').minMessage(0))
      .max(9, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '독해점수는').maxMessage(9))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '독해점수는').multipleOfMessage(0.5),
        (num) => (num ?? 0.1) % 0.5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '독해점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '독해점수를').requiredMessage()),
    listening: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '듣기점수는').minMessage(0))
      .max(9, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '듣기점수는').maxMessage(9))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '듣기점수는').multipleOfMessage(0.5),
        (num) => (num ?? 0.1) % 0.5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '듣기점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '듣기점수를').requiredMessage()),
    speaking: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '회화점수는').minMessage(0))
      .max(9, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '회화점수는').maxMessage(9))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '회화점수는').multipleOfMessage(0.5),
        (num) => (num ?? 0.1) % 0.5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '회화점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '회화점수를').requiredMessage()),
    writing: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '작문점수는').minMessage(0))
      .max(9, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '작문점수는').maxMessage(9))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '작문점수는').multipleOfMessage(0.5),
        (num) => (num ?? 0.1) % 0.5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '작문점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '작문점수를').requiredMessage()),
    score: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '총점은').minMessage(0))
      .max(9, SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '총점은').maxMessage(9))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '총점은').multipleOfMessage(0.5),
        (num) => (num ?? 0.1) % 0.5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '총점을').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('IELTS 시험', '총점을').requiredMessage()),
  })
  .default(null)
  .nullable();
