import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';

const ShadowDOM = ({
  html,
  css = '',
  className,
  bodyClassName,
}: {
  html: string;
  css?: string;
  className?: string;
  bodyClassName?: string;
}): ReactNode => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && !containerRef.current.shadowRoot) {
      // Create a shadow DOM
      const shadowRoot = containerRef.current.attachShadow({ mode: 'open' });

      // Create a style element for reset CSS
      const style = createDomElement('style', { textContent: `${css}` });
      // Append the style to the shadow DOM
      shadowRoot.appendChild(style);

      // Create a body for the content
      const content = createDomElement('body', { innerHTML: html, className: bodyClassName });
      // Append the content to the shadow DOM
      shadowRoot.appendChild(content);
    }
  }, [html, css, bodyClassName]);

  return (
    <div
      ref={containerRef}
      className={className}
    />
  );
};
export default ShadowDOM;

const createDomElement = (
  tag: keyof JSX.IntrinsicElements,
  attributes?: { [key: string]: any },
) => {
  const element = document.createElement(tag);

  if (attributes)
    Object.entries(attributes).forEach(([key, value]) => {
      if (key in element) (element as any)[key] = value;
      else element.setAttribute(key, value);
    });

  return element;
};
