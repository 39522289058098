/* eslint-disable no-restricted-globals */

/* eslint-disable react-hooks/rules-of-hooks */
import { UniversityMajorModal } from '@component/database/university/UniversityMajorModal';
import { UniversityModal } from '@component/database/university/UniversityModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useUniversities } from '@hook/useUniversities';
import { useUniversitiesMajors } from '@hook/useUniversitiesMajors';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Input,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { universitiesMajors } from '@simppl/repository/admins';
import { deleteUniversity } from '@simppl/repository/admins';
import { Search } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export default function UniversityPage() {
  const { universityId } = useParams();
  const isUniversityPage = !universityId;
  const isSubjectPage = !!universityId;

  return (
    <div className='flex min-h-[80%] flex-col'>
      {isUniversityPage && <UniversityTable />}
      {isSubjectPage && <SubjectTable />}
    </div>
  );
}

function UniversityTable() {
  const { data } = useUniversities({
    // isMain null 전체 대학 조회
    name: '',
    page: 1,
    size: 1000000,
  });

  if (!data) return null;

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(data, (university, value) => university.name.includes(value));
  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; universityId: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const deleteUniversitiesHook = useMutation(deleteUniversity);
  const queryClient = useQueryClient();

  return (
    <Card className='grow'>
      {isOpen && modalState !== null && (
        <UniversityModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          {...modalState}
          onClose={() => {
            onClose();
            setModalState(null);
          }}
        />
      )}
      <CardHeader>
        <h1 className='text-2xl'>대학교 관리</h1>
      </CardHeader>
      <Divider />
      <CardBody className='flex flex-col gap-4'>
        <Table
          layout='auto'
          topContent={
            <div className='flex flex-col gap-4'>
              <div className='flex w-full flex-row justify-between'>
                <div className='flex max-w-sm flex-1 flex-row items-center'>
                  <Input
                    labelPlacement='outside'
                    startContent={<Search />}
                    value={filterValue}
                    onClear={() => onClear()}
                    onValueChange={onSearchChange}
                  />
                </div>
                <Button
                  color='primary'
                  onPress={() => {
                    setModalState({ mode: 'add' });
                    onOpen();
                  }}
                >
                  대학 추가
                </Button>
              </div>
            </div>
          }
          topContentPlacement='outside'
        >
          <TableHeader>
            <TableColumn key='name'>대학 이름</TableColumn>
            <TableColumn key='image'>대학 이미지</TableColumn>
            <TableColumn key='setting'>비고</TableColumn>
          </TableHeader>
          <TableBody items={items}>
            {(university) => (
              <TableRow
                className='relative cursor-pointer'
                key={university.universityId}
                as={Link}
                href={`/database/university/${university.universityId}`}
              >
                <TableCell>{university.name}</TableCell>
                <TableCell>
                  <Image
                    src={university.image}
                    alt={university.name}
                    width={50}
                    height={50}
                  />
                </TableCell>
                <TableCell>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button
                        isIconOnly
                        size='sm'
                        variant='light'
                      >
                        <VerticalDotsIcon className='text-default-300' />
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownItem
                        key='edit'
                        onPress={() => {
                          setModalState({ mode: 'edit', universityId: university.universityId });
                          onOpen();
                        }}
                      >
                        수정
                      </DropdownItem>
                      <DropdownItem
                        key='delete'
                        className='text-red-500'
                        onPress={() => {
                          deleteUniversitiesHook.mutate(
                            { universityId: university.universityId },
                            {
                              onSuccess: () => {
                                queryClient.invalidateQueries(['universities']);
                              },
                            },
                          );
                        }}
                      >
                        삭제
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <div className='flex w-full flex-col items-center'>
          <Pagination
            isCompact
            showControls
            showShadow
            page={page}
            onChange={setPage}
            total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
          />
        </div>
      </CardFooter>
    </Card>
  );
}

function SubjectTable() {
  const { universityId } = useParams();
  if (!universityId) return null;
  const { data } = useUniversitiesMajors({
    universityId,
  });

  if (!data) return null;

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(data, (university, value) => university.name.includes(value));

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; universityMajorId: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const deleteUniversitiesHook = useMutation(universitiesMajors.delete);
  const queryClient = useQueryClient();

  return (
    <Card className='grow'>
      {isOpen && modalState !== null && (
        <UniversityMajorModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          {...modalState}
          onClose={() => {
            onClose();
            setModalState(null);
          }}
        />
      )}
      <CardHeader>
        <h1 className='text-2xl'>학과 관리</h1>
      </CardHeader>
      <Divider />
      <CardBody className='flex flex-col gap-4'>
        <Table
          layout='auto'
          topContent={
            <div className='flex flex-col gap-4'>
              <div className='flex w-full flex-row justify-between'>
                <div className='flex max-w-sm flex-1 flex-row items-center'>
                  <Input
                    labelPlacement='outside'
                    startContent={<Search />}
                    value={filterValue}
                    onClear={() => onClear()}
                    onValueChange={onSearchChange}
                  />
                </div>
                <Button
                  color='primary'
                  onPress={() => {
                    setModalState({ mode: 'add' });
                    onOpen();
                  }}
                >
                  학과 추가
                </Button>
              </div>
            </div>
          }
          topContentPlacement='outside'
        >
          <TableHeader>
            <TableColumn key='name'>학과 이름</TableColumn>
            <TableColumn key='setting'>비고</TableColumn>
          </TableHeader>
          <TableBody items={items}>
            {(major) => (
              <TableRow
                className='relative cursor-pointer'
                key={major.universityMajorId}
              >
                <TableCell>{major.name}</TableCell>
                <TableCell>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button
                        isIconOnly
                        size='sm'
                        variant='light'
                      >
                        <VerticalDotsIcon className='text-default-300' />
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownItem
                        key='edit'
                        onPress={() => {
                          setModalState({
                            mode: 'edit',
                            universityMajorId: major.universityMajorId,
                          });
                          onOpen();
                        }}
                      >
                        수정
                      </DropdownItem>
                      <DropdownItem
                        key='delete'
                        className='text-red-500'
                        onPress={() => {
                          if (!confirm('정말 삭제하시겠습니까?')) return;

                          deleteUniversitiesHook.mutate(
                            {
                              universityId,
                              universityMajorId: major.universityMajorId,
                            },
                            {
                              onSuccess: () => {
                                queryClient.invalidateQueries(['unversitiesMajors']);
                              },
                            },
                          );
                        }}
                      >
                        삭제
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardBody>
      <CardFooter>
        <div className='flex w-full flex-col items-center'>
          <Pagination
            isCompact
            showControls
            showShadow
            page={page}
            onChange={setPage}
            total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
          />
        </div>
      </CardFooter>
    </Card>
  );
}
