import SwitchCase from '@simppl/component/SwitchCase';

import { ALevelForm } from './ALevel';
import { APForm } from './AP';
import { ETCForm } from './ETC';
import { IBForm } from './IB';
import { KOREAForm } from './KOREA';

type HightSchoolFormProps = {
  testName: string;
  content: string;
};
export function HightSchoolForm({ testName, content }: HightSchoolFormProps) {
  return (
    <SwitchCase
      value={testName}
      caseBy={{
        'A-LEVEL': <ALevelForm content={content} />,
        AP: <APForm content={content} />,
        IB: <IBForm content={content} />,
        ETC: <ETCForm content={content} />,
        KOREA: <KOREAForm content={content} />,
      }}
    />
  );
}
