/* eslint-disable no-shadow */
import ShadowDOM from '@component/ShadowDOM';
import { FaqModal } from '@component/community/faq/FaqModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useFaqs } from '@hook/useFaqs';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { faq } from '@simppl/repository/faq';
import { Search } from 'lucide-react';
import { useCallback, useState } from 'react';

const columns = [
  {
    key: 'category',
    label: '카테고리',
  },
  {
    key: 'question',
    label: '질문',
  },
  {
    key: 'answer',
    label: '답변',
  },
  {
    key: 'setting',
    label: '비고',
  },
];

export default function FaqPage() {
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const faqs = useFaqs({
    searchType: 'title',
    search: filterValue,
    page,
    size: 10,
  });

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue('');
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue('');
    setPage(1);
  }, []);

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; page: number; id: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const deleteFaqHook = useMutation(faq.delete);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && (
          <FaqModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>FaQ 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      setModalState({
                        mode: 'add',
                      });
                      onOpen();
                    }}
                  >
                    FaQ 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader columns={columns}>
              {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
            </TableHeader>
            <TableBody items={faqs.posts}>
              {(notice) => (
                <TableRow key={notice.id}>
                  <TableCell>{notice.category.title}</TableCell>
                  <TableCell>
                    <ShadowDOM html={notice.question} />
                  </TableCell>
                  <TableCell>
                    <ShadowDOM html={notice.answer} />
                  </TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              id: notice.id.toString(),
                              page,
                            });
                            onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            // eslint-disable-next-line no-restricted-globals
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteFaqHook.mutate(
                              {
                                id: notice.id.toString(),
                              },
                              {
                                onSuccess: () => queryClient.invalidateQueries(['getFaqs']),
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(faqs.total / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
