import Text from '@simppl/component/Text';
import { useFormContext } from 'react-hook-form';

type ApViewProps = {
  content: string;
};

export function ApView({ content }: ApViewProps) {
  const methods = useFormContext();
  const data = methods.watch(`${content}.subjectList`);

  return (
    <div className='flex flex-col justify-center'>
      {data.map((reportCard: any) => (
        <div
          key={reportCard.standarizedApId}
          className='flex justify-between'
        >
          <div className='w-[240px] px-[16px] py-[8px]'>
            <Text
              color='text-blue-700'
              weight='Medium'
              as='Label'
              size={1}
            >
              {reportCard.name}
            </Text>
          </div>
          <div className='flex w-[112px] justify-between px-[16px] py-[8px]'>
            <Text
              color='text-blue-500'
              weight='Regular'
              as='Label'
              size={1}
            >
              점수
            </Text>
            <Text
              color='text-Blue-5'
              weight='Regular'
              as='Label'
              size={1}
            >
              {reportCard.score}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
}
