import { AddAdmissionModal } from '@component/admission/AddAdmissionModal';
import { AddAdmissionToCompetitiveUnitModal } from '@component/admission/AddAdmissionToCompetitiveUnitModal';
import { UpdateAdmissionModal } from '@component/admission/UpdateAdmissionModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useAdmissions } from '@hook/useAdmissions';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admission, admissionCompetitiveUnit } from '@simppl/repository/admission';
import { Search } from 'lucide-react';
import { useState } from 'react';

export function AdmissionPage() {
  const {
    data: { admissionList },
  } = useAdmissions();

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(
      admissionList,
      (userPayment, value) =>
        userPayment.simulatedApplicationName?.includes(value) ||
        userPayment.competitiveUnitName?.includes(value) ||
        userPayment.universityName?.includes(value) ||
        userPayment.admissionName?.includes(value),
    );

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; admissionId: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpenChange: onAddOpenChange,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const updateModal = useDisclosure();
  const deleteUserPaymentsHook = useMutation(admission.delete);
  const removeFromCompetitiveUnitHook = useMutation(admissionCompetitiveUnit.patch);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && modalState.mode === 'add' && (
          <AddAdmissionModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        {updateModal && modalState !== null && modalState.mode === 'edit' && (
          <AddAdmissionToCompetitiveUnitModal
            isOpen={isAddOpen}
            onOpenChange={onAddOpenChange}
            admissionId={modalState.admissionId}
            onClose={() => {
              onAddClose();
              setModalState(null);
            }}
          />
        )}
        {updateModal.isOpen && modalState !== null && modalState.mode === 'edit' && (
          <UpdateAdmissionModal
            isOpen={updateModal.isOpen}
            onOpenChange={updateModal.onOpenChange}
            admissionId={modalState.admissionId}
            onClose={() => {
              updateModal.onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>모집단위 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      setModalState({ mode: 'add' });
                      onOpen();
                    }}
                  >
                    모집단위 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='userName'>전형 이름</TableColumn>
              <TableColumn key='userName'>경쟁단위 이름</TableColumn>
              <TableColumn key='userName'>모집단위 이름</TableColumn>
              <TableColumn key='userName'>대학 이름</TableColumn>
              <TableColumn key='userName'>학과 이름</TableColumn>
              <TableColumn key='count'>지원 인원수</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={items}>
              {(item) => (
                <TableRow
                  className='relative cursor-pointer'
                  key={item.admissionId}
                >
                  <TableCell>{item.simulatedApplicationName}</TableCell>
                  <TableCell>{item.competitiveUnitName}</TableCell>
                  <TableCell>{item.admissionName}</TableCell>
                  <TableCell>{item.universityName}</TableCell>
                  <TableCell>{item.admissionName}</TableCell>
                  <TableCell>{item.appliedCount}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              admissionId: item.admissionId,
                            });
                            updateModal.onOpen();
                          }}
                        >
                          편집
                        </DropdownItem>
                        <DropdownItem
                          key='add_addmission'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              admissionId: item.admissionId,
                            });
                            onAddOpen();
                          }}
                        >
                          경쟁단위에 추가
                        </DropdownItem>
                        <DropdownItem
                          key='delete_admission'
                          className='text-red-500'
                          onPress={() => {
                            removeFromCompetitiveUnitHook.mutate(
                              { admissionId: item.admissionId, competitiveUnitId: null },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['getAdmissions']);
                                },
                              },
                            );
                          }}
                        >
                          경쟁단위에서 삭제
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            deleteUserPaymentsHook.mutate(
                              { admissionId: item.admissionId },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['getAdmissions']);
                                },
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
