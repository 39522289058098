import { Accordion, AccordionItem, Button, Card, Divider, Link } from '@nextui-org/react';
import { postUsersLogout } from '@simppl/repository/user';
import { generateStorage } from '@simppl/util/storage';

function SidebarMenu() {
  return (
    <div className='flex flex-col overscroll-none'>
      <Accordion
        itemClasses={{
          trigger: 'py-4',
          title: 'text-medium font-bold',
        }}
      >
        <AccordionItem
          key='1'
          aria-label='회원 관리'
          title='회원 관리'
        >
          <Card>
            <Button
              color='primary'
              variant='light'
              href='/user'
              as={Link}
            >
              모든 회원 관리
            </Button>
          </Card>
        </AccordionItem>
        <AccordionItem
          key='2'
          aria-label='모의 지원자 관리'
          title='모의 지원자 관리'
        >
          <Card>
            <Button
              color='primary'
              variant='light'
              href='/mock/applicant'
              as={Link}
            >
              모의 지원자 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/mock/fakeApplicant'
              as={Link}
            >
              모의지원 가데이터 관리
            </Button>
          </Card>
        </AccordionItem>
        <AccordionItem
          key='3'
          aria-label='커뮤니티 관리'
          title='커뮤니티 관리'
        >
          <Card>
            <Button
              color='primary'
              variant='light'
              href='/community/category'
              as={Link}
            >
              카테고리 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/community/post'
              as={Link}
            >
              게시글 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/community/notice'
              as={Link}
            >
              공지사항 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/community/faq'
              as={Link}
            >
              FAQ 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/community/inquiry'
              as={Link}
            >
              1:1 문의 관리
            </Button>
          </Card>
        </AccordionItem>
        <AccordionItem
          key='4'
          aria-label='모의지원 관리'
          title='모의지원 관리'
        >
          <Card>
            <Button
              color='primary'
              variant='light'
              href='/admission/simulatedApplication'
              as={Link}
            >
              전형 생성
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/admission/admission'
              as={Link}
            >
              모집단위 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/admission/competitiveUnit'
              as={Link}
            >
              경쟁단위 관리
            </Button>
          </Card>
        </AccordionItem>
        <AccordionItem
          key='5'
          aria-label='데이터베이스 관리'
          title='데이터베이스 관리'
        >
          <Card>
            <Button
              color='primary'
              variant='light'
              href='/database/userPayment'
              as={Link}
            >
              유저 상품 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/database/item'
              as={Link}
            >
              상품 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/database/university'
              as={Link}
            >
              대학교 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/database/highschool'
              as={Link}
            >
              고등학교 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/database/country'
              as={Link}
            >
              국가 관리
            </Button>
            <Button
              color='primary'
              variant='light'
              href='/database/admissionUserCount'
              as={Link}
            >
              모의 지원 인원 관리
            </Button>
          </Card>
        </AccordionItem>
        <AccordionItem
          key='6'
          aria-label='과목 관리'
          title='과목 관리'
        >
          <Card>
            <Button
              color='primary'
              variant='light'
              href='/subject/standardizedSubject'
              as={Link}
            >
              표준화 과목 관리
            </Button>
            {/* <Button
              color='primary'
              variant='light'
              href='/subject/highSchoolSubject'
              as={Link}
            >
              고등학교 과목 관리
            </Button> */}
          </Card>
        </AccordionItem>
        <AccordionItem
          key='7'
          aria-label='시스템 관리'
          title='시스템 관리'
        >
          <Card>
            <Button
              color='primary'
              variant='light'
              href='/system/setting'
              as={Link}
            >
              시스템 설정
            </Button>
          </Card>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default function SideBar() {
  const storage = generateStorage();
  const devMode = storage.get('devMode') === 'true';
  return (
    <div className='sticky left-0 top-0 h-screen w-auto'>
      <aside className='bg-content3 z-50 flex h-full w-48 flex-col gap-4 p-4 shadow-xl'>
        <div className='flex justify-center'>
          <Link
            href='/'
            className='flex flex-col'
          >
            <p className='text-base font-bold'>Simppl Admin</p>
            <p className='text-sm'>{devMode ? 'devMode' : 'prodMode'}</p>
          </Link>
        </div>
        <div className='flex-grow overflow-y-auto'>
          <SidebarMenu />
        </div>
        <Button
          color='danger'
          className='flex-shrink-0'
          onPress={() => postUsersLogout()}
        >
          로그아웃
        </Button>
      </aside>
    </div>
  );
}
