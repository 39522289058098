/* eslint-disable react-hooks/exhaustive-deps */
import { Plus } from '@component/score/common';
import useTestByUserId from '@hook/useTestByUserId';
import { yupResolver } from '@hookform/resolvers/yup';
import { activityAwardTestSchemaMap } from '@schema/activityAward';
import Text from '@simppl/component/Text';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EditScore } from './EditScore';
import { ActivityAwardScoreModeProvider, type ModeType } from './Provider';
import { ViewTest } from './ViewTest';

const ActivityAwardPage = () => {
  const [mode, setMode] = useState<ModeType>('viewTest');
  const { id } = useParams();
  const userData = useTestByUserId('activities-awards', id!);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (userData === undefined) return;

    methods.setValue('activityAward', userData.activityAwardList as any);
    userData.activityAwardList.forEach((data, index) => {
      methods.setValue(
        `activityAward.${index}.subject` as any,
        'awardDate' in data ? 'AWARD' : 'ACTIVITY',
      );
    });
  }, [userData]);

  return (
    <FormProvider {...methods}>
      <ActivityAwardScoreModeProvider
        mode={mode}
        setMode={setMode}
      >
        <form
          id='test'
          className='flex w-[915px] flex-col items-center'
        >
          {mode === 'viewTest' && <ViewTest />}
          {mode === 'editScore' && <EditScore />}
        </form>
      </ActivityAwardScoreModeProvider>
    </FormProvider>
  );
};

export default ActivityAwardPage;
