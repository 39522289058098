/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useAdmissions } from '@hook/useAdmissions';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admission } from '@simppl/repository/admission';
import { useEffect, useState } from 'react';

type UpdateAdmissionModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  admissionId: string;
};

export function UpdateAdmissionModal(props: UpdateAdmissionModalProps) {
  const [isDupplication, setIsDupplication] = useState(false);
  const [name, setName] = useState('');
  const [appliedCount, setAppliedCount] = useState<number>(0);

  const data = useAdmissions().data.admissionList.find(
    (admission) => admission.admissionId === props.admissionId,
  );

  const queryClient = useQueryClient();

  const patchUniversitiesHook = useMutation(admission.patch);

  useEffect(() => {
    if (data) {
      setName(data.admissionName);
      setAppliedCount(data.appliedCount);
      setIsDupplication(data.isDupplication);
    }
  }, [data]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>모집단위 추가</ModalHeader>
            <ModalBody>
              <Input
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                label='이름'
                labelPlacement='outside'
              />
              <Input
                type='number'
                value={appliedCount as any}
                onChange={(e) => setAppliedCount(parseInt(e.target.value, 10))}
                label='지원인원'
                labelPlacement='outside'
              />

              <Checkbox
                isSelected={isDupplication}
                onValueChange={setIsDupplication}
              >
                중복 지원 여부
              </Checkbox>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  patchUniversitiesHook.mutate(
                    {
                      admissionId: props.admissionId,
                      isDupplication: false,
                      appliedCount,
                      name,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['getAdmissions']);
                        props.onClose();
                      },
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
