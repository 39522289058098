import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getTestSubjectIds, standarizedSubjects } from '@simppl/repository/test';

export function useStandarizedSubjects() {
  const ib = useSuspenseQuery({
    queryKey: ['standarizedSubjects', 'ib-subjects'],
    queryFn: () => getTestSubjectIds('IB'),
  });

  const ap = useSuspenseQuery({
    queryKey: ['standarizedSubjects', 'ap-subjects'],
    queryFn: () => getTestSubjectIds('AP'),
  });

  const aLevel = useSuspenseQuery({
    queryKey: ['standarizedSubjects', 'alevel-subjects'],
    queryFn: () => getTestSubjectIds('A-LEVEL'),
  });

  return {
    IB: ib.data.map((v) => ({ ...v, subject: 'IB' as const })),
    AP: ap.data.map((v) => ({ ...v, subject: 'AP' as const })),
    'A-LEVEL': aLevel.data.map((v) => ({ ...v, subject: 'A-LEVEL' as const })),
  };
}
