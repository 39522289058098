/* eslint-disable react-hooks/rules-of-hooks */
import ShadowDOM from '@component/ShadowDOM';
import { InquiryAnswerModal } from '@component/community/inquiry/InquiryAnswerModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useInquiries } from '@hook/useInquiries';
import { useInquiriesAnswers } from '@hook/useInquiriesAnswers';
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { DeleteInquiriesId, inquiryAnswer } from '@simppl/repository/inquiries';
import { getTime } from '@simppl/util/date';
import { Suspense, useCallback, useState } from 'react';

export default function InquiryPage() {
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');

  const inquiries = useInquiries({
    page,
    size: 10,
  });

  inquiries.posts.forEach((inquiry) => {
    useInquiriesAnswers({ id: inquiry.id.toString() });
  });

  const [modalState, setModalState] = useState<{ id: string } | null>(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && (
          <InquiryAnswerModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>1:1문의 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  {/* <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div> */}
                  {/* <Button
                    color='primary'
                    onPress={() => {
                      setModalState({
                        mode: 'add',
                      });
                      onOpen();
                    }}
                  >
                    1:1문의 추가
                  </Button> */}
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn
                key='title'
                className='w-[400px]'
              >
                1:1문의 제목
              </TableColumn>
              <TableColumn
                key='createdAt'
                className='w-[300px]'
              >
                문의일시
              </TableColumn>
              <TableColumn
                key='createdAt'
                className='w-[300px]'
              >
                작성자
              </TableColumn>
              <TableColumn
                key='name'
                className='w-[300px]'
              >
                이메일
              </TableColumn>
              <TableColumn
                key='email'
                className='w-[150px]'
              >
                답변여부
              </TableColumn>
              <TableColumn
                key='isRead'
                className='w-[150px]'
              >
                읽음여부
              </TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={inquiries.posts}>
              {(notice) => (
                <TableRow
                  className='cursor-pointer'
                  key={notice.id}
                >
                  <TableCell
                    colSpan={7}
                    aria-colspan={7}
                    className='p-0'
                  >
                    <Accordion className='p-0'>
                      <AccordionItem
                        indicator={<></>}
                        startContent={
                          <div
                            className='relative flex text-start'
                            key={notice.id}
                          >
                            <div className='w-[400px] px-3'>{notice.title}</div>
                            <div className='w-[300px] px-3'>
                              {getTime({ createdAt: notice.createdAt }).fullTime}
                            </div>
                            <div className='w-[300px] px-3'>{notice?.writer?.name ?? ''}</div>
                            <div className='w-[300px] px-3'>{notice?.writer?.email ?? ''}</div>
                            <div className='w-[150px] px-3'>
                              {notice.hasAnswer ? '답변 완료' : '답변 미완료'}
                            </div>
                            <div className='w-[150px] px-3'>
                              {notice.isRead ? '읽음' : '읽지 않음'}
                            </div>
                            <div>
                              <InquiryDropdown
                                id={notice.id.toString()}
                                setModalState={setModalState}
                                onOpen={onOpen}
                              />
                            </div>
                          </div>
                        }
                      >
                        <div className='flex flex-col gap-4 p-4'>
                          <Card>
                            <CardHeader>
                              <h4>문의 내용</h4>
                            </CardHeader>
                            <CardBody>
                              <ShadowDOM html={notice.content} />
                            </CardBody>
                          </Card>
                          <Card>
                            <CardHeader>
                              <h4>답변 내용</h4>
                            </CardHeader>
                            <CardBody>
                              <Suspense fallback={<>...loading</>}>
                                <InquiryAnswer id={notice.id.toString()} />
                              </Suspense>
                            </CardBody>
                          </Card>
                        </div>
                      </AccordionItem>
                    </Accordion>
                  </TableCell>
                  <TableCell className='hidden'> </TableCell>
                  <TableCell className='hidden'> </TableCell>
                  <TableCell className='hidden'> </TableCell>
                  <TableCell className='hidden'> </TableCell>
                  <TableCell className='hidden'> </TableCell>
                  <TableCell className='hidden'> </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(inquiries.total / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}

type InquiryAnswerType = {
  id: string;
};
function InquiryAnswer({ id }: InquiryAnswerType) {
  const answer = useInquiriesAnswers({ id });

  return <ShadowDOM html={answer.data.answer?.content ?? '답변이 없습니다.'} />;
}

type InquiryDropdownType = {
  id: string;
  setModalState: (state: { id: string } | null) => void;
  onOpen: () => void;
};
function InquiryDropdown({ id, setModalState, onOpen }: InquiryDropdownType) {
  const answer = useInquiriesAnswers({ id });

  const deleteAnswerHook = useMutation(inquiryAnswer.delete);
  const deleteInquiryHook = useMutation(DeleteInquiriesId);
  const queryClient = useQueryClient();

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          isIconOnly
          size='sm'
          variant='light'
        >
          <VerticalDotsIcon className='text-default-300' />
        </Button>
      </DropdownTrigger>
      <DropdownMenu>
        <DropdownItem
          key='answer'
          onPress={() => {
            setModalState({ id });
            onOpen();
          }}
        >
          답변 달기
        </DropdownItem>
        <DropdownItem
          key='delete_answer'
          className='text-red-500'
          onPress={() => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('정말 삭제하시겠습니까?')) return;

            if (!answer.data.answer) return;
            deleteAnswerHook.mutate(
              {
                id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(['getInquiriesAnswers', id]);
                },
              },
            );
          }}
        >
          답변 삭제하기
        </DropdownItem>
        <DropdownItem
          key='delete_question'
          className='text-red-500'
          onPress={() => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('정말 삭제하시겠습니까?')) return;
            deleteInquiryHook.mutate(
              { id: parseInt(id, 10) },
              {
                onSuccess: () => queryClient.invalidateQueries(['getInquiries']),
              },
            );
          }}
        >
          문의 삭제
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
