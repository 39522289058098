import * as yup from 'yup';

import { ETC_schema } from '../etc';
import { DELE_schema } from './dele';
import { DELF_schema } from './delf';
import { HSK_schema } from './hsk';
import { IELTS_schema } from './ielts';
import { TEPS_schema } from './teps';
import { TOEFL_schema } from './toefl';
import { TOEIC_schema } from './toeic';

export const languageTestSchemaMap = yup.object().shape({
  TOEFL: TOEFL_schema,
  TOEIC: TOEIC_schema,
  IELTS: IELTS_schema,
  TEPS: TEPS_schema,
  HSK: HSK_schema,
  DELF: DELF_schema,
  DELE: DELE_schema,
  ETC: ETC_schema,
});
