export function Toefl(data: TOEFL_ExamInfo) {
  return [
    {
      title: '업로드 url',
      type: 'link',
      content: data.transcript,
    },
    {
      title: '응시일자',
      content: data.examDate,
    },
    {
      title: '발행기관',
      content: data.publishingOrganization,
    },
    {
      title: 'writing',
      content: data.writing,
    },
    {
      title: 'speaking',
      content: data.speaking,
    },
    {
      title: 'listening',
      content: data.listening,
    },
    {
      title: 'reading',
      content: data.reading,
    },
  ];
}
