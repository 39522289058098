import { ScoreBlock, ScoreForm } from '@component/score/test';
import { yupResolver } from '@hookform/resolvers/yup';
import { standardizedTestSchemaMap } from '@schema/standardized';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { EditScore } from './EditScore';
import { type ModeType, StandardTestScoreModeProvider } from './Provider';
import { ViewTest } from './ViewTest';

const standardizedTests = {
  ACT: 'ACT',
  AP: 'AP',
  'A-LEVEL': 'A-LEVEL',
  IB: 'IB',
  SAT: 'SAT',
  ETC: 'ETC',
};

const StandardPage = () => {
  const [mode, setMode] = useState<ModeType>('viewTest');
  const methods = useForm({
    // resolver: yupResolver(standardizedTestSchemaMap),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  return (
    <FormProvider {...methods}>
      <ScoreBlock list={standardizedTests}>
        <StandardTestScoreModeProvider
          mode={mode}
          setMode={setMode}
        >
          <ScoreForm testType='standarized-tests'>
            {mode === 'viewTest' && ((<ViewTest />) as any)}
            {mode === 'editScore' && <EditScore />}
          </ScoreForm>
        </StandardTestScoreModeProvider>
      </ScoreBlock>
    </FormProvider>
  );
};

export default StandardPage;
