/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-param-reassign */

/* eslint-disable dot-notation */
type EtcDomType = {
  subjectList: ETC_ExamInfo[];
};
type LangaugeTestDomType = {
  TOEFL: TOEFL_ExamInfo;
  TOEIC: TOEIC_ExamInfo;
  HSK: HSK_ExamInfo;
  TEPS: TEPS_ExamInfo;
  IELTS: IELTS_ExamInfo;
  DELF: DELF_ExamInfo;
  DELE: DELE_ExamInfo;
  ETC: EtcDomType;
};

type StandardizedTestDomType = {
  ACT: ACT_ExamInfo;
  AP: AP_ExamInfo;
  'A-LEVEL': ALEVEL_ExamInfo;
  IB: IB_ExamInfo;
  SAT: SAT_ExamInfo;
  ETC: EtcDomType;
};

export const convertToETCAndotherData = <
  TType extends LangaugeTestDomType | StandardizedTestDomType,
>(
  data: TType,
) => {
  return Object.fromEntries(
    Object.entries(data)
      .filter(
        ([testType, value]) =>
          value !== null &&
          value !== undefined &&
          testType !== 'testType' &&
          testType !== 'edit' &&
          Object.keys(value).length > 0,
      )
      ?.reduce<any>((acc, [test, value]) => {
        if (test === 'ETC') {
          delete value['uploadFile'];
          const subjectList = value.subjectList.map((subject: any) => {
            delete subject['uploadFile'];
            subject['totalScore'] = subject['totalScore'].toString();
            subject['score'] = subject['score'].toString();
            return subject;
          });
          acc.push([test, subjectList]);
        } else {
          if (test === 'IELTS') {
            if (value.score === undefined) {
              value.score = value.totalScore;
            }
          }
          if (test === 'HSK') {
            value.speaking = 0;
            value.grade = parseInt(value.grade, 10);
          }
          if (test === 'A-LEVEL') {
            value.subjectList = value.subjectList.map((subject: any) => {
              // if (subject.id === undefined) subject.id = subject.standarizedALevelId;
              return subject;
            });
          }

          if (test === 'AP') {
            value.subjectList = value.subjectList.map((subject: any) => {
              subject.id = subject.standarizedApId;
              return subject;
            });
          }

          if (test === 'IB') {
            value.subjectList = value.subjectList.map((subject: any) => {
              subject.id = subject.standarizedIbId;
              return subject;
            });
          }

          delete value['uploadFile'];
          acc.push([test as any, value]);
        }

        return acc;
      }, []),
  );
};

export const convertToETCStandardData = <
  TType extends LangaugeTestDomType | StandardizedTestDomType,
>(
  A_LEVEL: Record<string, number>,
  AP: Record<string, number>,
  IB: Record<string, number>,
  data: TType,
) => {
  return Object.fromEntries(
    Object.entries(data)
      .filter(
        ([testType, value]) =>
          value !== null &&
          value !== undefined &&
          testType !== 'testType' &&
          testType !== 'edit' &&
          Object.keys(value).length > 0,
      )
      .reduce<any>((acc, [test, value]) => {
        if (test === 'ETC') {
          delete value['uploadFile'];
          const subjectList = value.subjectList.map((subject: any) => {
            delete subject['uploadFile'];
            subject['totalScore'] = subject['totalScore'].toString();
            subject['score'] = subject['score'].toString();
            return subject;
          });
          acc.push([test, subjectList]);
        } else {
          if (test === 'A-LEVEL') {
            value.subjectList = value.subjectList.map((subject: any) => {
              subject.id = A_LEVEL[subject.name as keyof typeof A_LEVEL];
              return subject;
            });
          }

          if (test === 'AP') {
            value.subjectList = value.subjectList.map((subject: any) => {
              subject.id = AP[subject.name as keyof typeof AP];
              return subject;
            });
          }

          if (test === 'IB') {
            value.subjectList = value.subjectList.map((subject: any) => {
              subject.id = IB[subject.name as keyof typeof IB];
              return subject;
            });
          }

          delete value['uploadFile'];
          acc.push([test as any, value]);
        }

        return acc;
      }, []),
  );
};
