import getImgUrl from '@simppl/util/getImgUrl';

export function Edit() {
  return (
    <img
      src={getImgUrl('/asset/Button/Edit.svg')}
      alt='수정'
    />
  );
}
