/* eslint-disable no-shadow */
import { SimulatedApplicationModal } from '@component/admission/SimulatedApplicationModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  type Selection,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import {
  createAllAdmissionsBySimulatedApplicationId,
  createAllCompetitiveUnitsBySimulatedApplicationId,
} from '@simppl/repository/admins';
import { simulatedApplications } from '@simppl/repository/simulatedApplications';
import { ChevronDownIcon, Search } from 'lucide-react';
import { useState } from 'react';

const statusOptions = [
  { name: '3특', uid: '3Y' },
  { name: '12특', uid: '12Y' },
];

export function SimulatedApplicationPage() {
  const { data } = useSimulatedApplication();
  const [statusFilter, setStatusFilter] = useState<Selection>('all');

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(
      data,
      (userPayment, value) => userPayment.name.includes(value),
      (userPayment) => {
        if (statusFilter !== 'all' && Array.from(statusFilter).length !== statusOptions.length) {
          return Array.from(statusFilter).includes(userPayment.sort);
        }
        return true;
      },
    );

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; simulatedApplicationId: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const deleteUserPaymentsHook = useMutation(simulatedApplications.delete);
  const queryClient = useQueryClient();

  const createAdmissionsBulkHook = useMutation(createAllAdmissionsBySimulatedApplicationId);
  const createCompetitiveUnitsBulkHook = useMutation(
    createAllCompetitiveUnitsBySimulatedApplicationId,
  );

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isOpen && modalState !== null && (
          <SimulatedApplicationModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>전형 생성</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center gap-4'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                    <Dropdown>
                      <DropdownTrigger className='hidden sm:flex'>
                        <Button
                          endContent={<ChevronDownIcon className='text-small' />}
                          variant='flat'
                        >
                          전형 구분
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        disallowEmptySelection
                        aria-label='Table Columns'
                        closeOnSelect={false}
                        selectedKeys={statusFilter}
                        selectionMode='multiple'
                        onSelectionChange={setStatusFilter}
                      >
                        {statusOptions.map((status) => (
                          <DropdownItem
                            key={status.uid}
                            className='capitalize'
                          >
                            {status.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      setModalState({ mode: 'add' });
                      onOpen();
                    }}
                  >
                    전형 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='userName'>전형 이름</TableColumn>
              <TableColumn key='itemName'>대학정보</TableColumn>
              <TableColumn key='itemPrice'>시작일</TableColumn>
              <TableColumn key='price'>마감일</TableColumn>
              <TableColumn key='sort'>전형 구분</TableColumn>
              <TableColumn key='examSort'>시험 구분</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={items}>
              {(item) => (
                <TableRow
                  className='relative cursor-pointer'
                  key={item.simulatedApplicationId}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.universityName}</TableCell>
                  <TableCell>{item.startDate}</TableCell>
                  <TableCell>{item.endDate}</TableCell>
                  <TableCell>{item.sort}</TableCell>
                  <TableCell>{item.examSort === 'exam' ? '집필' : '서류'}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='enroll_create'
                          onClick={() => {
                            createAdmissionsBulkHook.mutate(
                              parseInt(item.simulatedApplicationId, 10),
                              {
                                onSuccess: () => {
                                  alert('모집단위가 일괄 생성되었습니다.');
                                  queryClient.invalidateQueries(['getAdmissions']);
                                },
                                onError: (error: any) => {
                                  alert(error.response.data.message);
                                },
                              },
                            );
                          }}
                        >
                          모집단위 일괄 생성
                        </DropdownItem>
                        <DropdownItem
                          key='admission_create'
                          onClick={() => {
                            createCompetitiveUnitsBulkHook.mutate(
                              parseInt(item.simulatedApplicationId, 10),
                              {
                                onSuccess: () => {
                                  alert('경쟁단위가 일괄 생성되었습니다.');
                                  queryClient.invalidateQueries(['getAdmissions']);
                                },
                                onError: (error: any) => {
                                  alert(error.response.data.message);
                                },
                              },
                            );
                          }}
                        >
                          경쟁단위 일괄 생성
                        </DropdownItem>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              simulatedApplicationId: item.simulatedApplicationId,
                            });
                            onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            deleteUserPaymentsHook.mutate(
                              { simulatedApplicationId: item.simulatedApplicationId },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['getSimulatedApplications']);
                                },
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
