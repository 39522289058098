import * as yup from 'yup';

import { SCHEMA_MESSAGE } from '../message';

export const ALEVELSubject = [
  'Accounting',
  'Art & Design',
  'Biology',
  'Business',
  'Chemistry',
  'Chinese - Language & Literature',
  'Chinese',
  'Computer Science',
  'Design & Technology',
  'Drama',
  'Economics',
  'English - Literature',
  'English Language',
  'French',
  'French Language & Literature',
  'Geography',
  'German',
  'German Language & Literature',
  'History',
  'Law',
  'Mathematics',
  'Mathematics - Further',
  'Physics',
  'Psychology',
  'Sociology',
  'Spanish - Language & Literature',
  'Spanish',
];

export const ALEVEL_schema = yup
  .object()
  .shape({
    transcript: yup
      .string()
      .required(
        SCHEMA_MESSAGE.scoreMessage('a-level 시험', '파일을').customMessage('업로드 해주세요.'),
      ),
    examDate: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('a-level 시험', '시험일자를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('a-level 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('a-level 시험', '발급기관을').requiredMessage()),
    subjectList: yup
      .array()
      .min(
        1,
        SCHEMA_MESSAGE.scoreMessage('a-level 시험', '과목을').customMessage(
          '최소 하나 이상 선택해주세요.',
        ),
      )
      .of(
        yup.object().shape({
          name: yup
            .mixed()
            .oneOf(ALEVELSubject, '')
            .required(
              SCHEMA_MESSAGE.scoreMessage('a-level 시험', '과목의 이름을').requiredMessage(),
            ),
          score: yup
            .mixed()
            .oneOf(['A*', 'A', 'B', 'C', 'D', 'E', 'U'], '')
            .required(
              SCHEMA_MESSAGE.scoreMessage('a-level 시험', '등급을').requiredMessage('선택'),
            ),
        }),
      ),
  })
  .default(null)
  .nullable();
