import CheckBoxComponent from '@simppl/component/CheckBox';
import Text from '@simppl/component/Text';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { type CommonType } from './CommonType';

export type CheckBox = CommonType & {
  title?: string;
  type: 'checkbox';
};

type CheckBoxProps = CheckBox & { name: string };

export function CheckBox({ name, title }: CheckBoxProps) {
  const methods = useFormContext();

  useEffect(() => {
    methods.register(name, { value: false });
  }, [methods]);
  return (
    <div className='flex w-[122px] items-center gap-[16px]'>
      <button
        type='button'
        className='h-[24px] w-[24px]'
        onClick={() => methods.setValue(name, !methods.watch(name))}
      >
        <CheckBoxComponent checked={methods.watch(name)} />
      </button>
      {title && (
        <Text
          className=' inline-block overflow-hidden text-ellipsis whitespace-nowrap'
          color='text-White'
          weight='Regular'
          as='Label'
          size={1}
        >
          {title}
        </Text>
      )}
    </div>
  );
}
