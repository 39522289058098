/* eslint-disable react-hooks/exhaustive-deps */
import { ComboBox, Input, Select, Title } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useStandarizedTestIds } from '@hook/useStandarizedTestIds';
import { ErrorMessage } from '@hookform/error-message';
import { IBSubject } from '@schema/standardized/ib';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteUserStandardizedTestSubject } from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type IBFormProps = {
  content: string;
};

export function IBForm({ content }: IBFormProps) {
  const methods = useFormContext();
  const { IB } = useStandarizedTestIds();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${content}.subjectList`,
  });

  useEffect(() => {
    methods.setValue(`${content}.subjectList.0.name`, 'TOK/EE');
    methods.setValue(`${content}.subjectList.0.id`, IB['TOK/EE' as keyof typeof IB]);
    methods.setValue(`${content}.subjectList.0.type`, 'HL');
  }, []);

  return (
    <>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${content}.subjectList`}
        render={({ message }) => <p className='text-[12px] text-red-600'>{message}</p>}
      />
      <div className='flex w-full flex-col gap-2'>
        <div className='mr-[146px] flex justify-end gap-[24px]'>
          <div className='flex gap-[9px]'>
            {/* <Select
              type='select'
              placeholder='TOK/EE'
              category={{ HL: 'HL', SL: 'SL' }}
              content='TOK/EE'
              name=''
              disabled
            /> */}
            <Title
              size='long'
              content='TOK/EE'
              type='title'
            />
            <Input
              type='input'
              content='점수'
              name={`${content}.subjectList.0.score`}
              defaultValue={0}
              size='short'
            />
          </div>
        </div>
        {fields.map(
          ({ id }, index) =>
            index !== 0 && (
              <Form
                content={content}
                index={index}
                remove={remove}
                key={id}
              />
            ),
        )}
      </div>
      <div className='pl-[148px]'>
        <button
          type='button'
          className='flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]'
          onClick={() =>
            append({
              type: null,
              name: null,
              score: 0,
            })
          }
        >
          <Plus color='white' />
          과목추가
        </button>
      </div>
    </>
  );
}

type FormProps = {
  content: string;
  index: number;
  remove: (index: number) => void;
};

function Form({ content, index, remove }: FormProps) {
  const methods = useFormContext();
  const { IB } = useStandarizedTestIds();
  const deleteUserStandardizedTestSubjectHook = useMutation((subjectId: string) =>
    deleteUserStandardizedTestSubject('IB', subjectId),
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    const id = methods.watch(`${content}.subjectList.${index}.id`) ?? null;
    const name = methods.watch(`${content}.subjectList.${index}.name`) ?? null;
    if (id === null && name !== null) {
      methods.setValue(`${content}.subjectList.${index}.id`, IB[name as keyof typeof IB]);
    }
    if (id !== null && name === null) {
      methods.setValue(`${content}.subjectList.${index}.name`, IB[id as keyof typeof IB]);
    }
  }, [content, index, methods, IB]);

  return (
    <div className='mr-[82px] flex justify-end gap-[24px]'>
      <Controller
        name={`${content}.subjectList.${index}`}
        control={methods.control}
        render={({ field }) => (
          <>
            <div className='flex justify-between gap-[9px]'>
              <>
                <Select
                  type='select'
                  placeholder='HL / SL'
                  category={{ HL: 'HL', SL: 'SL' }}
                  content={methods.watch(`${field.name}.type`) ?? ''}
                  name={`${field.name}.type`}
                />
                <ComboBox
                  type='combobox'
                  placeholder='과목 검색'
                  category={IBSubject}
                  content={methods.watch(`${field.name}.name`) ?? ''}
                  name={`${field.name}.name`}
                  onChange={(value) => {
                    methods.setValue(`${field.name}.id`, IB[value as keyof typeof IB]);
                  }}
                />
                <Input
                  {...field}
                  type='input'
                  content='점수'
                  name={`${field.name}.score`}
                  defaultValue={0}
                  size='short'
                />
              </>
            </div>
            <button
              className=' flex   h-[40px]   w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700'
              type='button'
              onClick={() => {
                const subjectId = methods.watch(`${content}.subjectList.${index}.standarizedIbId`);

                if (subjectId)
                  deleteUserStandardizedTestSubjectHook.mutate(subjectId, {
                    onSuccess: () => remove(index),
                  });
                else {
                  remove(index);
                }
                queryClient.invalidateQueries(['standarized-tests-testByUserId']);
              }}
            >
              <Minus />
            </button>
          </>
        )}
      />
    </div>
  );
}
