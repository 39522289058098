import useQuery from '@simppl/react-query/useQuery';
import { getAdmissionsMajorsWithCount } from '@simppl/repository/admission';
import type { GetAdmissionsMajorsWithCountReqType } from '@simppl/repository/admission/req';

export const useAdmissionsMajorLists = (body: GetAdmissionsMajorsWithCountReqType) => {
  const { data, refetch } = useQuery(['useAdmissionsMajorLists'], () =>
    getAdmissionsMajorsWithCount(body),
  );

  return {
    data: data?.data,
    refetch,
  };
};
