import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getReportCardSubjects, getTestSubjectIds } from '@simppl/repository/test';

export function useReportCardSubjects() {
  const { data } = useSuspenseQuery({
    queryKey: ['reportCardSubjects'],
    queryFn: () => getReportCardSubjects(),
  });

  return data;
}
