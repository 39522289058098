import SwitchCase from '@simppl/component/SwitchCase';
import { postUsersLogout } from '@simppl/repository/user';
import LoginStorage from '@simppl/repository/user/LoginStorage';
import { safeLocalStorage } from '@simppl/util/storage';
import { type PropsWithChildren, useEffect, useState } from 'react';
import type { NavigateProps } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const LoginRequired = ({
  children,
  to = '/login',
  replace,
  state,
  relative,
}: PropsWithChildren<Omit<NavigateProps, 'to'> & { to?: NavigateProps['to'] }>) => {
  return (
    <SwitchCase
      value={LoginStorage.isLogin()}
      caseBy={{
        true: <>{children}</>,
        false: (
          <Navigate
            to={to}
            replace={replace}
            state={state}
            relative={relative}
          />
        ),
      }}
    />
  );
};

export default LoginRequired;

const useCheckCleanToken = () => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    setRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return render;
};
