import { FakeApplicantAddModal } from '@component/applicant/modal/FakeApplicantAddModal';
import { FakeApplicantUpdateModal } from '@component/applicant/modal/FakeApplicantUpdateModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useMockUserAdmissions } from '@hook/useMockUserAdmissions';
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { mockUserAdmissions as mockUserAdmissionsApi } from '@simppl/repository/admins';
import type { GetMockUserAdmissionsResType } from '@simppl/repository/admins/res';
import { useMemo, useState } from 'react';

const useFakeAdmission = (userAdmissions: GetMockUserAdmissionsResType['data']) => {
  const [page, setPage] = useState(1);

  const items = useMemo(() => {
    const start = (page - 1) * 10;
    const end = page * 10;

    return userAdmissions.slice(start, end);
  }, [page, userAdmissions]);

  return {
    page,
    setPage,
    items,
  };
};

export default function FakeApplicantPage() {
  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; userAdmissionId: string }
    | null
  >(null);
  const mockUserAdmissions = useMockUserAdmissions();
  const { page, setPage, items } = useFakeAdmission(mockUserAdmissions);

  const {
    isOpen: isFakeApplicantAddOpen,
    onOpen: onFakeApplicantAddOpen,
    onOpenChange: onFakeApplicantAddOpenChange,
    onClose: onFakeApplicantAddClose,
  } = useDisclosure();

  const updateMockUserAdmissionsModal = useDisclosure();

  const queryClient = useQueryClient();
  const deleteMockUserAdmissionsHook = useMutation(mockUserAdmissionsApi.delete);

  return (
    <div className='flex min-h-[80%] flex-col'>
      <FakeApplicantAddModal
        isOpen={isFakeApplicantAddOpen}
        onOpenChange={onFakeApplicantAddOpenChange}
        onClose={onFakeApplicantAddClose}
      />
      {updateMockUserAdmissionsModal.isOpen && modalState?.mode === 'edit' && (
        <FakeApplicantUpdateModal
          isOpen={updateMockUserAdmissionsModal.isOpen}
          onClose={updateMockUserAdmissionsModal.onClose}
          onOpenChange={updateMockUserAdmissionsModal.onOpenChange}
          {...modalState}
        />
      )}
      <Card className='grow'>
        <CardHeader>
          <h1 className='text-2xl'>모의 지원자 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody>
          <div className='flex flex-col gap-4'>
            <div className='flex w-full flex-row gap-4'>
              <Button
                color='primary'
                onPress={onFakeApplicantAddOpen}
              >
                가 데이터 추가
              </Button>
            </div>
          </div>
          <Table>
            <TableHeader>
              <TableColumn
                minWidth={200}
                key='name'
                className=' text-center'
              >
                지원 대학
              </TableColumn>
              <TableColumn
                minWidth={200}
                key='appliedAt'
                className=' text-center'
                allowsSorting
              >
                지원 학과
              </TableColumn>
              <TableColumn
                minWidth={200}
                key='rejectionReason'
                className=' text-center'
              >
                반려사유
              </TableColumn>
              {
                Object.keys(items?.[0]?.serializedScore ?? {}).map((key) => (
                  <TableColumn
                    minWidth={200}
                    className=' text-center'
                    key={key}
                  >
                    {key}
                  </TableColumn>
                )) as any
              }
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody>
              {items.map((user) => (
                <TableRow
                  key='expanded'
                  className='text-center'
                >
                  <TableCell className=''>{user.universityName}</TableCell>
                  <TableCell className=''>{user.majorName}</TableCell>
                  <TableCell className=''>{user.rejectionReason || '없음'}</TableCell>
                  {
                    Object.keys(user.serializedScore).map((key) => (
                      <TableCell
                        key={key}
                        className=''
                      >
                        {user.serializedScore[key as keyof typeof user.serializedScore] ?? '없음'}
                      </TableCell>
                    )) as any
                  }
                  <TableCell className='text-center'>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({ mode: 'edit', userAdmissionId: user.id });
                            updateMockUserAdmissionsModal.onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            // eslint-disable-next-line no-restricted-globals
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteMockUserAdmissionsHook.mutate(
                              { userAdmissionId: user.id },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['useMockUserAdmissions']);
                                },
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(mockUserAdmissions.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
