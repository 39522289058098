import { HighSchoolManageModal } from '@component/database/highSchool/HighSchoolManageModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useHighschools } from '@hook/useHighschools';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteHighschools } from '@simppl/repository/highschools';
import type { GetHighschoolsRes } from '@simppl/repository/highschools/res';
import { ChevronDownIcon, PlusIcon, Search } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

const columns = [
  {
    key: 'name',
    label: '고등학교 이름',
  },
  {
    key: 'isApprove',
    label: '승인여부',
  },
  {
    key: 'setting',
    label: '비고',
  },
];

const useHighschool = (highSchools: GetHighschoolsRes['data']) => {
  const [filterValue, setFilterValue] = useState('');
  const [page, setPage] = useState(1);
  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = useMemo(() => {
    if (hasSearchFilter) {
      return highSchools.filter((highSchool) =>
        highSchool.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return highSchools;
  }, [filterValue, hasSearchFilter, highSchools]);

  const items = useMemo(() => {
    const start = (page - 1) * 10;
    const end = page * 10;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const onSearchChange = useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue('');
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue('');
    setPage(1);
  }, []);

  return {
    filteredItems,
    filterValue,
    page,
    setPage,
    items,
    onSearchChange,
    onClear,
  };
};

export default function HighSchoolPage() {
  //   const [isApprove, setIsApprove] = useState(false);
  const [highSchoolData, setHighSchoolData] = useState<{
    highSchoolId: string;
    isAprroved: boolean;
    highSchoolName: string;
  } | null>(null);
  const { highSchools } = useHighschools({});
  const { page, setPage, items, filteredItems, filterValue, onClear, onSearchChange } =
    useHighschool(highSchools);

  const queryClient = useQueryClient();
  const deleteHighSchoolHook = useMutation((id: string) => deleteHighschools(id));

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className='flex min-h-[80%] flex-col'>
      {highSchoolData && (
        <HighSchoolManageModal
          highSchoolId={highSchoolData.highSchoolId}
          name={highSchoolData.highSchoolName}
          isAprroved={highSchoolData.isAprroved}
          isOpen={isOpen}
          onOpenChange={(value: boolean) => {
            if (!value) {
              setHighSchoolData(null);
              queryClient.invalidateQueries(['highschools']);
            }
            onOpenChange();
          }}
        />
      )}
      <Card className='grow'>
        <CardHeader>
          <h1 className='text-2xl'>고등학교 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <div className='flex flex-col gap-4'>
            <div className='flex w-full flex-row gap-4'>
              <div className='flex max-w-sm flex-1 flex-row items-center'>
                <Input
                  labelPlacement='outside'
                  startContent={<Search />}
                  value={filterValue}
                  onClear={() => onClear()}
                  onValueChange={onSearchChange}
                />
              </div>
              {/* <Dropdown>
                <DropdownTrigger className='hidden sm:flex'>
                  <Button
                    endContent={<ChevronDownIcon className='text-small' />}
                    variant='flat'
                  >
                    상태
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  aria-label='Table Columns'
                  selectedKeys={new Set(isApprove ? ['isAprrove'] : [])}
                  //   closeOnSelect={false}
                  selectionMode='multiple'
                >
                  <DropdownItem
                    key='isAprrove'
                    onPress={() => setIsApprove(!isApprove)}
                  >
                    승인여부
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
              <Button
                color='primary'
                onPress={() => {
                  /**
                   * TODO 검색
                   */
                  console.log('검색');
                }}
              >
                검색
              </Button>
            </div>
          </div>
          <Table>
            <TableHeader columns={columns}>
              {(column) => (
                <TableColumn
                  className='text-center'
                  key={column.key}
                >
                  {column.label}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody items={items}>
              {(highSchool) => (
                <TableRow
                  className='relative'
                  key={highSchool.id}
                >
                  <TableCell>{highSchool.name}</TableCell>
                  <TableCell className='text-center'>
                    {highSchool.isApprove ? '승인' : '반려'}
                  </TableCell>
                  <TableCell className='text-center'>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='manage'
                          onPress={() => {
                            setHighSchoolData({
                              highSchoolId: highSchool.id,
                              isAprroved: highSchool.isApprove,
                              highSchoolName: highSchool.name,
                            });
                            onOpen();
                          }}
                        >
                          승인 관리
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            // eslint-disable-next-line no-restricted-globals
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteHighSchoolHook.mutate(highSchool.id, {
                              onSuccess: () => {
                                queryClient.invalidateQueries(['highschools']);
                              },
                            });
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
