import { AddAdmissionCompetitiveUnitModal } from '@component/admission/AddAdmissionCompetitiveUnitModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useAdmissions } from '@hook/useAdmissions';
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admissionCompetitiveUnit } from '@simppl/repository/admission';
import { Search } from 'lucide-react';
import { useParams } from 'react-router-dom';

export function CompetitiveUnitAdmissionPage() {
  const { competitiveUnitId } = useParams();
  if (!competitiveUnitId) throw new Error('competitiveUnitId is required');

  const competitiveUnit = useCompetitiveUnits().data.find(
    (item) => item.competitiveUnitId === competitiveUnitId,
  );
  const admissions = useAdmissions().data.admissionList.filter(
    (item) => item.competitiveUnitId === competitiveUnitId,
  );

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(admissions, (userPayment, value) =>
      userPayment.admissionName?.includes(value),
    );

  const removeFromCompetitiveUnitHook = useMutation(admissionCompetitiveUnit.patch);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      {isOpen && (
        <AddAdmissionCompetitiveUnitModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          onClose={onClose}
          competitiveUnitId={competitiveUnitId}
        />
      )}
      <Card className='grow'>
        <CardHeader>
          <h1 className='text-2xl'>{competitiveUnit?.name}</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      onOpen();
                    }}
                  >
                    경쟁단위에 모집단위 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='userName'>전형 이름</TableColumn>
              <TableColumn key='userName'>경쟁단위 이름</TableColumn>
              <TableColumn key='userName'>대학 이름</TableColumn>
              <TableColumn key='userName'>학과 이름</TableColumn>
              <TableColumn key='count'>지원 인원수</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={items}>
              {(item) => (
                <TableRow
                  className='relative cursor-pointer'
                  key={item.competitiveUnitId}
                >
                  <TableCell>{item.simulatedApplicationName}</TableCell>
                  <TableCell>{item.competitiveUnitName}</TableCell>
                  <TableCell>{item.universityName}</TableCell>
                  <TableCell>{item.admissionName}</TableCell>
                  <TableCell>{item.appliedCount}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            removeFromCompetitiveUnitHook.mutate(
                              { admissionId: item.admissionId, competitiveUnitId: null },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['getAdmissions']);
                                },
                              },
                            );
                          }}
                        >
                          경쟁단위에서 삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
