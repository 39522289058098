/* eslint-disable no-shadow */
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  Textarea,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import { patchCountries } from '@simppl/repository/countries';
import type { PatchHighSchoolsReq } from '@simppl/repository/highschools/req';
import { useState } from 'react';

type CountryManageModalProps = {
  countryId: string;
  name: string;
  isAprroved: boolean;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
};

export const CountryManageModal = ({
  countryId,
  name,
  isAprroved,
  isOpen,
  onOpenChange,
}: CountryManageModalProps) => {
  const [isApprove, setIsApprove] = useState(isAprroved);
  const [countryName, setCountryName] = useState(name);

  const patchCountryHook = useMutation((body: PatchHighSchoolsReq & { countryId: string }) =>
    patchCountries(body.countryId, body),
  );
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>국가 승인 관리</ModalHeader>
            <ModalBody>
              <div className='flex flex-col gap-4'>
                <div className='flex gap-4'>
                  <p>승인 여부</p>
                  <Switch
                    defaultSelected={false}
                    color='default'
                    isSelected={isApprove}
                    onValueChange={setIsApprove}
                  />
                </div>
                <div className='flex flex-col'>
                  <p>국가 이름</p>
                  <Textarea
                    placeholder='국가 이름을 적어주세요.'
                    value={countryName}
                    onValueChange={setCountryName}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  patchCountryHook.mutate(
                    {
                      countryId,
                      isApprove,
                      name: countryName,
                    },
                    {
                      onSuccess: () => onClose(),
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
