import { Dele } from './Dele';
import { Delf } from './Delf';
import { Etc } from './Etc';
import { Hsk } from './Hsk';
import { Ielts } from './Ielts';
import { Teps } from './Teps';
import { Toefl } from './Toefl';
import { Toeic } from './Toeic';

export const language = {
  DELE: Dele,
  DELF: Delf,
  HSK: Hsk,
  IELTS: Ielts,
  TEPS: Teps,
  TOEFL: Toefl,
  TOEIC: Toeic,
  ETC: Etc,
};
