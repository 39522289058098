/* eslint-disable react-hooks/exhaustive-deps */
import useTestByUserId from '@hook/useTestByUserId';
import { type Tests } from '@simppl/repository/test';
import { extractDateFromTimestamp } from '@simppl/util/date';
import { type PropsWithChildren, type ReactElement, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { scoreBlockContext } from './ScoreBlock';

export function ScoreForm<TData>({
  testType,
  children,
}: PropsWithChildren<{
  testType: keyof Tests;
}>) {
  const { id } = useParams();
  const userData = useTestByUserId(testType, id!);
  const { handleCheck, handleReset } = useContext(scoreBlockContext);
  const methods = useFormContext();

  useEffect(() => {
    methods.setValue('testType', testType);

    if (userData === undefined) return;
    handleReset();
    Object.entries(userData).forEach(([examName, examData]) => {
      if (examName === 'ETC' && examData?.length > 0) {
        handleCheck(examName);
        examData.forEach((examItem: any, i: number) => {
          methods.setValue(`${examName}.subjectList.${i}`, examItem);
          methods.setValue(
            `${examName}.subjectList.${i}.examDate`,
            extractDateFromTimestamp(examItem.examDate),
          );
        });
      } else if (examName !== 'ETC' && examData !== null) {
        handleCheck(examName);
        methods.setValue(examName, examData);
        methods.setValue(`${examName}.examDate`, extractDateFromTimestamp(examData.examDate));
      }
    });
  }, [userData]);

  return (
    <div
      // id='test'
      // onSubmit={methods.handleSubmit(onSubmit, (errors) => console.log(errors))} // 에러 확인을 위해 달았습니다.
      className='flex flex-col gap-4'
    >
      {children}
    </div>
  );
}
