import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { category } from '@simppl/repository/categories';
import type { GetCategoriesReqType } from '@simppl/repository/categories/req';

export const useCategories = (body: GetCategoriesReqType) => {
  const {
    data: { categories },
  } = useSuspenseQuery({
    queryKey: ['getCategories', body.type],
    queryFn: () => category.get(body),
  });
  return { categories };
};
