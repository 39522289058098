import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getUser } from '@simppl/repository/admins';
import type { GetUserInfoByIdReqType } from '@simppl/repository/admins/req';

export const useUserInfo = ({ userId }: GetUserInfoByIdReqType) => {
  const { data } = useSuspenseQuery({
    queryKey: [`userInfo-${userId}}`],
    queryFn: () => getUser({ userId }),
  });

  return data;
};
