import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { StandarizedSubjectModal } from '@component/subject/StandarizedSubjectModal';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useStandarizedSubjects } from '@hook/useStandarizedSubjects';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  type Selection,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { standarizedSubjects } from '@simppl/repository/test';
import { ChevronDownIcon, Search } from 'lucide-react';
import { useState } from 'react';

const statusOptions = [
  { name: 'AP', uid: 'AP' },
  { name: 'A-LEVEL', uid: 'A-LEVEL' },
  { name: 'IB', uid: 'IB' },
];

const columns = [
  {
    key: 'subject',
    label: '과목 타입',
  },
  {
    key: 'name',
    label: '이름',
  },
  {
    key: 'settings',
    label: '비고',
  },
];
export function StandarizedSubjectPage() {
  const { IB, AP, 'A-LEVEL': A_LEVEL } = useStandarizedSubjects();
  const [statusFilter, setStatusFilter] = useState<Selection>('all');
  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(
      [...AP, ...A_LEVEL, ...IB],
      (subject, value) => subject.name.includes(value),
      (subject) => {
        if (statusFilter !== 'all' && Array.from(statusFilter).length !== statusOptions.length) {
          return Array.from(statusFilter).includes(subject.subject);
        }
        return true;
      },
    );

  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; subjectId: string; type: 'AP' | 'A-LEVEL' | 'IB' }
    | null
  >(null);

  const deleteSubjectHook = useMutation(standarizedSubjects.delete);
  const queryClient = useQueryClient();

  const { isOpen, onClose, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className='flex min-h-[80%] flex-col'>
      {isOpen && modalState !== null && (
        <StandarizedSubjectModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          {...modalState}
          onClose={() => {
            onClose();
            setModalState(null);
          }}
        />
      )}
      <Card className='grow'>
        <CardHeader>
          <h1 className='text-2xl'>표준화 과목 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <div className='flex flex-col gap-4'>
            <div className='flex w-full flex-row gap-4'>
              <div className='flex max-w-sm flex-1 flex-row items-center gap-4'>
                <Input
                  labelPlacement='outside'
                  startContent={<Search />}
                  value={filterValue}
                  onClear={() => onClear()}
                  onValueChange={onSearchChange}
                />
                <Dropdown>
                  <DropdownTrigger className='hidden sm:flex'>
                    <Button
                      endContent={<ChevronDownIcon className='text-small' />}
                      variant='flat'
                    >
                      전형 구분
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    disallowEmptySelection
                    aria-label='Table Columns'
                    closeOnSelect={false}
                    selectedKeys={statusFilter}
                    selectionMode='multiple'
                    onSelectionChange={setStatusFilter}
                  >
                    {statusOptions.map((status) => (
                      <DropdownItem
                        key={status.uid}
                        className='capitalize'
                      >
                        {status.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <Button
                color='primary'
                onPress={() => {
                  setModalState({ mode: 'add' });
                  onOpen();
                }}
              >
                세부 과목 입력 (선택)
              </Button>
            </div>
          </div>
          <Table>
            <TableHeader columns={columns}>
              {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
            </TableHeader>
            <TableBody items={items}>
              {(subject) => (
                <TableRow
                  className='relative'
                  key={subject.id}
                >
                  <TableCell>{subject.subject}</TableCell>
                  <TableCell>{subject.name}</TableCell>
                  <TableCell className='text-center'>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              subjectId: subject.id,
                              type: subject.subject,
                            });
                            onOpen();
                          }}
                        >
                          편집
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            // eslint-disable-next-line no-restricted-globals
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteSubjectHook.mutate(
                              {
                                subject: subject.subject,
                                subjectId: subject.id,
                              },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['standarizedSubjects']);
                                },
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
