import * as yup from 'yup';

import { SCHEMA_MESSAGE } from '../message';

export const TOEIC_format = [
  {
    subject: 'reading',
    property: [
      {
        content: 'reading',
        alterText: 'RC',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 495,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
  {
    subject: 'listening',
    property: [
      {
        content: 'listening',
        alterText: 'LC',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 495,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
  {
    subject: 'total',
    property: [
      {
        content: 'total',
        alterText: '총점',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 990,
        type: 'input' as const,
        defaultValue: 0,
      },
    ],
  },
];

export const TOEIC_schema = yup
  .object()
  .shape({
    transcript: yup
      .string()
      .required(
        SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '파일을').customMessage('업로드 해주세요.'),
      ),
    examDate: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '시험일자를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '발급기관을').requiredMessage()),
    reading: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'RC점수는').minMessage(0))
      .max(495, SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'RC점수는').maxMessage(495))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'RC점수는').multipleOfMessage(5),
        (num) => (num ?? 1) % 5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'RC점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'RC점수를').requiredMessage()),
    listening: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'LC점수는').minMessage(0))
      .max(495, SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'LC점수는').maxMessage(495))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'LC점수는').multipleOfMessage(5),
        (num) => (num ?? 1) % 5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'LC점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', 'LC점수를').requiredMessage()),
    total: yup
      .number()
      .min(0, SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '총점은').minMessage(0))
      .max(990, SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '총점은').maxMessage(990))
      .test(
        'multipleOf',
        SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '총점은').multipleOfMessage(5),
        (num) => (num ?? 1) % 5 === 0,
      )
      .required(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '총점을').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('TOEIC 시험', '총점을').requiredMessage()),
  })
  .default(null)
  .nullable();
