/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useAdmissions } from '@hook/useAdmissions';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admissionCompetitiveUnit } from '@simppl/repository/admission';
import type { GetAdmissionResType } from '@simppl/repository/admission/res';
import { useMemo, useState } from 'react';

type AddAdmissionToCompetitiveUnitModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  competitiveUnitId: string;
};

export function AddAdmissionCompetitiveUnitModal(props: AddAdmissionToCompetitiveUnitModalProps) {
  const [majorKeys, setMajorKeys] = useState(new Set(['']));
  const [simulatedKeys, setSimulatedKeys] = useState(new Set(['']));
  const selectedSimulatedValue = useMemo(
    () => Array.from(simulatedKeys).join(', ').replace(/_/g, ' '),
    [simulatedKeys],
  );

  const admissionsMap = useAdmissions().data.admissionList.reduce<
    Record<string, GetAdmissionResType['data']['admissionList']>
  >((acc, cur) => {
    if (cur.simulatedApplicationName in acc) {
      acc[cur.simulatedApplicationName].push(cur);
    } else {
      acc[cur.simulatedApplicationName] = [cur];
    }
    return acc;
  }, {});

  const queryClient = useQueryClient();

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>경쟁단위에 모집단위 추가</ModalHeader>
            <ModalBody>
              <Select
                disallowEmptySelection
                aria-label='Table Columns'
                selectionMode='single'
                selectedKeys={simulatedKeys}
                onSelectionChange={setSimulatedKeys as any}
              >
                {Object.keys(admissionsMap).map((categoryItem) => (
                  <SelectItem
                    key={categoryItem}
                    value={categoryItem}
                    className='capitalize'
                  >
                    {categoryItem}
                  </SelectItem>
                ))}
              </Select>
              <Select
                disallowEmptySelection
                aria-label='Table Columns'
                selectionMode='multiple'
                selectedKeys={majorKeys}
                onSelectionChange={setMajorKeys as any}
              >
                {admissionsMap[selectedSimulatedValue]?.map((categoryItem) => (
                  <SelectItem
                    key={categoryItem.admissionId}
                    value={categoryItem.admissionId}
                    className='capitalize'
                  >
                    {categoryItem.admissionName}
                  </SelectItem>
                ))}
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  Promise.all(
                    Array.from(majorKeys)
                      .filter((v) => v !== '')
                      .map(async (admissionId) => {
                        const result = await admissionCompetitiveUnit.patch({
                          competitiveUnitId: props.competitiveUnitId,
                          admissionId,
                        });
                        return result;
                      }),
                  ).then((results) => {
                    if (results.every((result) => result.resultCode === 200)) {
                      queryClient.invalidateQueries(['getAdmissions']);
                      props.onClose();
                    } else {
                      alert('저장중에 오류가 발생했습니다. 다시 시도해주세요.');
                    }
                  });
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
