import { SCHEMA_MESSAGE } from '@schema/message';
import * as yup from 'yup';

export const highSchoolSchemaMap = yup.object().shape({
  schoolName: yup
    .string()
    .required(SCHEMA_MESSAGE.scoreMessage('고등학교성적', '학교명을').requiredMessage()),
  countryName: yup
    .string()
    .required(SCHEMA_MESSAGE.scoreMessage('고등학교성적', '국가를').requiredMessage()),
});

export const reportCardSchema = yup.object().shape({
  grade: yup.number().required('학년을 필수로 입력해주세요.').typeError('학년을 입력해주세요.'),
  gpa: yup.number().required('GPA를 필수로 입력해주세요.').typeError('GPA를 입력해주세요.'),
  perfectScore: yup
    .number()
    .required('만점을 필수로 입력해주세요.')
    .typeError('만점을 입력해주세요.'),
  semester: yup.number().required('학기를 필수로 입력해주세요.').typeError('학기를 입력해주세요.'),
  subject: yup
    .mixed()
    .oneOf(['IB', 'AP', 'A-LEVEL', 'ETC', 'KOREA'], '커리큘럼을 필수로 선택해주세요.')
    .required('커리큘럼을 선택해주세요.'),
});
