/* eslint-disable react-hooks/exhaustive-deps */
import { ComboBox, Input, Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import { ErrorMessage } from '@hookform/error-message';
import { IBSubject } from '@schema/standardized/ib';
import useMutation from '@simppl/react-query/useMutation';
import {
  deleteUserReportCardDetails,
  postReportCardSubjects,
  reportCardDetail,
  reportCardDetailAdmin,
} from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type IBFormProps = {
  content: string;
};

export function IBForm({ content }: IBFormProps) {
  const methods = useFormContext();
  const subjectIds = useReportCardSubjectsIds();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${content}.reportCardDetailList`,
  });

  return (
    <>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${content}.reportCardDetailList`}
        render={({ message }) => <p className='text-[12px] text-red-600'>{message}</p>}
      />
      <div className='flex w-full flex-col gap-2'>
        {fields.map(({ id }, index) => (
          <Form
            content={content}
            index={index}
            remove={remove}
            key={id}
          />
        ))}
      </div>
      <div className='pl-[148px]'>
        <button
          type='button'
          className='flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]'
          onClick={() =>
            append({
              difficulty: null,
              name: null,
              ibScore: 0,
            })
          }
        >
          <Plus color='white' />
          과목추가 (선택)
        </button>
      </div>
    </>
  );
}

type FormProps = {
  content: string;
  index: number;
  remove: (index: number) => void;
};

function Form({ content, index, remove }: FormProps) {
  const methods = useFormContext();
  const subjectIds = useReportCardSubjectsIds();
  const deleteUserReportCardDetailHook = useMutation(reportCardDetailAdmin.delete);

  useEffect(() => {
    const reportCardSubjectId = methods.watch(
      `${content}.reportCardDetailList.${index}.reportCardSubjectId`,
    );
    const name = methods.watch(`${content}.reportCardDetailList.${index}.name`) ?? null;
    if (reportCardSubjectId === null && name !== null) {
      methods.setValue(
        `${content}.reportCardDetailList.${index}.reportCardSubjectId`,
        subjectIds[name as keyof typeof subjectIds] ?? '',
      );
    }
    if (reportCardSubjectId !== null && name === null) {
      methods.setValue(
        `${content}.reportCardDetailList.${index}.name`,
        subjectIds[reportCardSubjectId as keyof typeof subjectIds],
      );
    }
  }, []);

  return (
    <div className='mr-[82px] flex justify-end gap-[24px]'>
      <Controller
        name={`${content}.reportCardDetailList.${index}`}
        control={methods.control}
        render={({ field }) => (
          <>
            <div className='flex justify-between gap-[9px]'>
              <>
                <Select
                  type='select'
                  placeholder='HL / SL'
                  category={{ HL: 'HL', SL: 'SL' }}
                  content={methods.watch(`${field.name}.difficulty`) ?? ''}
                  name={`${field.name}.difficulty`}
                />
                <ComboBox
                  type='combobox'
                  placeholder='과목 검색'
                  category={IBSubject}
                  forceDisable={methods.getValues(`${field.name}.reportCardSubjectName`)}
                  content={methods.watch(`${field.name}.name`) ?? ''}
                  name={`${field.name}.name`}
                  onChange={(value) => {
                    methods.setValue(
                      `${field.name}.reportCardSubjectId`,
                      subjectIds[value as keyof typeof subjectIds],
                    );
                  }}
                />
                <Input
                  {...field}
                  type='input'
                  content='점수'
                  inputType='stringInput'
                  name={`${field.name}.ibScore`}
                  defaultValue={0}
                  size='short'
                />
              </>
            </div>
            <button
              className=' flex   h-[40px]   w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700'
              type='button'
              onClick={() => {
                const reportCardDetailId = methods.watch(
                  `${content}.reportCardDetailList.${index}.reportCardDetailId`,
                );
                if (reportCardDetailId)
                  deleteUserReportCardDetailHook.mutate(
                    { reportCardDetailId },
                    {
                      onSuccess: () => remove(index),
                    },
                  );
                else {
                  remove(index);
                }
              }}
            >
              <Minus />
            </button>
          </>
        )}
      />
    </div>
  );
}
