import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useAdmissionsInterestByUserId } from '@hook/useAdmissionsInterestByUserId';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { userInterestAdmissions } from '@simppl/repository/user';
import { useContext } from 'react';

const columns = [
  {
    key: 'type',
    label: '전형',
  },
  {
    key: 'name',
    label: '대학',
  },

  {
    key: 'recruitName',
    label: '학부',
  },
  {
    key: 'majorName',
    label: '전공',
  },
  {
    key: 'setting',
    label: '비고',
  },
];

export const UserAdmissionInterestTab = () => {
  const { userId } = useContext(UserIdContext);
  const userAdmissions = useAdmissionsInterestByUserId(userId);

  const queryClient = useQueryClient();

  const deleteAdmissionInterestByUserIdHook = useMutation((interestAdmissionId: string) =>
    userInterestAdmissions.delete({ userId, interestAdmissionId }),
  );
  return (
    <Table layout='auto'>
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={userAdmissions.resultList}>
        {(admission) => (
          <TableRow key={admission.interestAdmissionId}>
            <TableCell>{admission.type}</TableCell>
            <TableCell>{admission.name}</TableCell>
            <TableCell>{admission.recruitName}</TableCell>
            <TableCell>{admission.majorName}</TableCell>
            <TableCell>
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    isIconOnly
                    size='sm'
                    variant='light'
                  >
                    <VerticalDotsIcon className='text-default-300' />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem key='edit'>수정</DropdownItem>
                  <DropdownItem
                    className='text-red-500'
                    onPress={() => {
                      // eslint-disable-next-line no-restricted-globals
                      if (!confirm('정말 삭제하시겠습니까?')) return;
                      deleteAdmissionInterestByUserIdHook.mutate(admission.interestAdmissionId, {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['useAdmissionsInterestByUserId']);
                        },
                      });
                    }}
                    key='delete'
                  >
                    삭제
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
