import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { category } from '@simppl/repository/categories';

export const useCommunityCategories = () => {
  const {
    data: { categories },
  } = useSuspenseQuery({
    queryKey: ['getCommunityCategories'],
    queryFn: () => category.community.get(),
  });
  return { categories };
};
