/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import CustomCKEditor from '@simppl/component/EditorConfig';
import useMutation from '@simppl/react-query/useMutation';
import useQuery from '@simppl/react-query/useQuery';
import useQueryClient from '@simppl/react-query/useQueryClient';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import boardsApi from '@simppl/repository/board';
import { category } from '@simppl/repository/categories';
import { useEffect, useState } from 'react';

const BLACK_LIST = ['추천글', '베스트글'];

type PostModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; boardId: string; categoryId: string }
);

export function PostModal(props: PostModalProps) {
  const {
    postId,
    isEditMode,
    title,
    setTitle,
    content,
    setContent,
    isAnonymous,
    setIsAnonymous,
    categoryId,
    setCategoryId,
    subCategoryId,
    setSubCategoryId,
    setEditor,
  } = useCommunityWriteState(
    props.mode === 'edit' ? { boardId: props.boardId, categoryId: props.categoryId } : null,
  );
  const queryClient = useQueryClient();
  const patchComunityPostHook = useMutation(boardsApi.boardId.posts.postId.patch);
  const postComunityPostHook = useMutation(boardsApi.boardId.posts.post);

  const {
    data: { categories },
  } = useSuspenseQuery({
    queryKey: ['category.community.get'],
    queryFn: () => category.community.get(),
  });

  // 게시판
  const categoryOptions = categories.reduce<Record<string, string>>((acc, { id, title }) => {
    // 추천글과 베스트글은 제외
    if (BLACK_LIST.includes(title)) return acc;
    acc[id] = title;
    return acc;
  }, {});

  // 말머리
  const caregoryIdMap = categories.reduce<Record<string, Record<string, string>>>(
    (acc, { id, children }) => {
      acc[id] = children?.reduce<Record<string, string>>((_acc, { id: _id, title }) => {
        _acc[_id] = title;
        return _acc;
      }, {});
      return acc;
    },
    {},
  );

  const handleSave = () => {
    if ((subCategoryId || categoryId) && title && content) {
      if (isEditMode)
        patchComunityPostHook.mutate(
          {
            postId: postId!,
            title,
            content,
            isAnonymous,
            categoryId: subCategoryId ?? categoryId!,
            isPrivate: false,
          },
          {
            onSuccess: (data) => {
              // success
              queryClient.invalidateQueries(['posts']);
              /**
               * TODO 추후 변경된 카테고리로 수정 필요
               */
              props.onClose();
            },
            onError: () => {
              // error
            },
          },
        );
      else
        postComunityPostHook.mutate(
          {
            boardId: (subCategoryId ?? categoryId)!,
            title,
            content,
            isAnonymous,
            isPrivate: false,
          },
          {
            onSuccess: (data) => {
              // success
              queryClient.invalidateQueries(['posts']);
              props.onClose();
            },
            onError: () => {
              // error
            },
          },
        );
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader>
              <p>{isEditMode ? '글수정' : '글쓰기'}</p>
            </ModalHeader>
            <ModalBody>
              <div className='flex flex-col gap-[16px]'>
                <div className='flex gap-[16px]'>
                  <Select
                    disabled={isEditMode}
                    className='border-Blue-3 text-Blue-Ct-5 w-full border-[1px]'
                    placeholder='게시판'
                    selectedKeys={[categoryId ?? '']}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    {Object.entries(categoryOptions).map(([key, value]) => (
                      <SelectItem key={key}>{value}</SelectItem>
                    ))}
                  </Select>
                  <Select
                    className='border-Blue-3 text-Blue-Ct-5 w-full border-[1px]'
                    placeholder='말머리'
                    selectedKeys={[subCategoryId ?? '']}
                    onChange={(e) => setSubCategoryId(e.target.value)}
                  >
                    {(categoryId
                      ? Object.entries(
                          caregoryIdMap[categoryId as keyof typeof caregoryIdMap] ?? {},
                        )
                      : []
                    ).map(([key, value]) => (
                      <SelectItem key={key}>{value}</SelectItem>
                    ))}
                  </Select>
                </div>
                <Input
                  className='w-full'
                  placeholder='제목'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <Editor
                setEditor={setEditor}
                initialData={content ?? ''}
                onChange={setContent}
                height={300}
              />
            </ModalBody>
            <ModalFooter className='flex flex-col items-center gap-[16px]'>
              <Checkbox
                isSelected={isAnonymous}
                onClick={() => setIsAnonymous((prev) => !prev)}
              >
                익명으로 등록하기
              </Checkbox>
              <Button
                className='w-full'
                color='primary'
                onClick={handleSave}
              >
                {isEditMode ? '수정하기' : '등록하기'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

const useCommunityWriteState = (props: { boardId: string; categoryId: string } | null) => {
  const [editor, setEditor] = useState<CustomCKEditor | null>(null);
  const postId = props?.boardId;

  const isEditMode = !!props;

  const { data } = useQuery(
    ['boardsApi.boardId.posts.postId.get', postId],
    () => boardsApi.boardId.posts.postId.get({ postId: postId! }),
    { enabled: !!postId },
  );

  const postData = data?.data;

  const [isAnonymous, setIsAnonymous] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [subCategoryId, setSubCategoryId] = useState<string | null>(null);

  useEffect(() => {
    if (isEditMode) {
      console.log(postData);
      setIsAnonymous(postData?.isAnonymous ?? false);
      setTitle(postData?.title ?? '');
      editor?.setData(postData?.content ?? '');
      setCategoryId(postData?.category.parent.id ?? null);
      setSubCategoryId(postData?.category.id ?? null);
    }
  }, [isEditMode, postData, editor]);

  return {
    postId,
    isEditMode,
    title,
    setTitle,
    content,
    setContent,
    isAnonymous,
    setIsAnonymous,
    categoryId,
    setCategoryId,
    subCategoryId,
    setSubCategoryId,
    setEditor,
  };
};
