import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getCountries } from '@simppl/repository/countries';
import type { GetCountriesReq } from '@simppl/repository/countries/req';

export const useCountries = (body: GetCountriesReq = {}) => {
  const { data } = useSuspenseQuery({
    queryKey: ['countries'],
    queryFn: () => getCountries(body),
  });

  return {
    countries: data ?? [],
    countryNames: data?.map((country) => country.name) ?? [],
    countryNameIdMap: data?.reduce<Record<string, string>>(
      (acc, cur) => ({ ...acc, [cur.name]: cur.id }),
      {},
    ),
    countryIdNameMap: data?.reduce<Record<string, string>>(
      (acc, cur) => ({ ...acc, [cur.id]: cur.name }),
      {},
    ),
  };
};
