/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useCompetitiveUnits } from '@hook/useCompetitiveUnits';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admissionCompetitiveUnit } from '@simppl/repository/admission';
import { ChevronDownIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

type AddAdmissionToCompetitiveUnitModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  admissionId: string;
};

export function AddAdmissionToCompetitiveUnitModal(props: AddAdmissionToCompetitiveUnitModalProps) {
  const [simulatedKeys, setSimulatedKeys] = useState(new Set(['']));
  const selectedSimulatedValue = useMemo(
    () => Array.from(simulatedKeys).join(', ').replace(/_/g, ' '),
    [simulatedKeys],
  );

  const competitiveUnit = useCompetitiveUnits();

  const queryClient = useQueryClient();

  const postUniversitiesHook = useMutation(admissionCompetitiveUnit.patch);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>경쟁단위에 모집단위 추가</ModalHeader>
            <ModalBody>
              <Select
                disallowEmptySelection
                aria-label='Table Columns'
                selectionMode='single'
                selectedKeys={simulatedKeys}
                onSelectionChange={setSimulatedKeys as any}
              >
                {competitiveUnit.data.map((categoryItem) => (
                  <SelectItem
                    key={categoryItem.competitiveUnitId}
                    value={categoryItem.competitiveUnitId}
                    className='capitalize'
                  >
                    {categoryItem.name}
                  </SelectItem>
                ))}
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  postUniversitiesHook.mutate(
                    {
                      admissionId: props.admissionId,
                      competitiveUnitId: selectedSimulatedValue,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['getAdmissions']);
                        props.onClose();
                      },
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
