/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { UploadFile } from '@component/Uploader';
import { useUniversitiesMajors } from '@hook/useUniversitiesMajors';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { universitiesMajors } from '@simppl/repository/admins';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type UniversityMajorModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; universityMajorId: string }
);

export function UniversityMajorModal(props: UniversityMajorModalProps) {
  const { universityId } = useParams();
  if (!universityId) return null;
  const [input, setInput] = useState('');

  const university =
    props.mode === 'edit'
      ? useUniversitiesMajors({
          universityId,
        }).data?.find((university) => university.universityMajorId === props.universityMajorId)
      : null;

  const queryClient = useQueryClient();

  const postUniversitiesHook = useMutation(universitiesMajors.post);
  const patchUniversitiesHook = useMutation(universitiesMajors.patch);

  useEffect(() => {
    if (props.mode === 'edit' && university) {
      setInput(university.name);
    }
  }, [props, university]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '학과 추가' : '학과 수정'}
            </ModalHeader>
            <ModalBody>
              <Input
                value={input as any}
                onChange={(e) => setInput(e.target.value)}
                label='학과 이름'
                labelPlacement='outside'
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postUniversitiesHook.mutate(
                      {
                        universityId,
                        name: input,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['unversitiesMajors']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && university?.universityMajorId)
                    patchUniversitiesHook.mutate(
                      {
                        universityId,
                        universityMajorId: university.universityMajorId,
                        name: input,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['unversitiesMajors']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
