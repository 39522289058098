export function Award(data: Award) {
  return [
    {
      title: '업로드 url',
      type: 'link',
      content: data.transcript,
    },
    {
      title: '수상일자',
      content: data.awardDate,
    },
    {
      title: '발행기관',
      content: data.publishingOrganization,
    },
    {
      title: '교내/교외',
      content: data.division,
    },
    {
      title: '성격',
      content: data.type,
    },
    {
      title: '내용',
      content: data.content,
    },
  ];
}
