/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useCategories } from '@hook/useCategories';
import { useFaqs } from '@hook/useFaqs';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { faq } from '@simppl/repository/faq';
import { ChevronDownIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

type FaqModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
    | {
      mode: 'add';
    }
    | { mode: 'edit'; page: number; id: string }
  );

export function FaqModal(props: FaqModalProps) {
  const [selectedKeys, setSelectedKeys] = useState(new Set(['']));
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(', ').replace(/_/g, ' '),
    [selectedKeys],
  );
  const item =
    props.mode === 'edit'
      ? useFaqs({
        searchType: 'title',
        search: '',
        page: props.page,
        size: 10,
      }).posts.find((category) => category.id.toString() === props.id)
      : null;

  if (props.mode === 'edit' && !item) return null;
  const categories = useCategories({
    type: 'FAQ',
  });

  const queryClient = useQueryClient();

  const postItemHook = useMutation(faq.post);
  const patchItemHook = useMutation(faq.patch);

  useEffect(() => {
    if (props.mode === 'edit' && item) {
      setQuestion(item.question);
      setAnswer(item.answer);
      setSelectedKeys(new Set([item.category.id]));
    }
  }, [props, item]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? 'FaQ 추가' : 'FaQ 수정'}
            </ModalHeader>
            <ModalBody className='flex flex-col'>
              <div className='flex flex-col'>
                <h4>질문</h4>
                <Editor
                  initialData={item?.question || ''}
                  onChange={setQuestion}
                  height={100}
                />
              </div>
              <div className='flex flex-col'>
                <h4>답변</h4>
                <Editor
                  initialData={item?.answer || ''}
                  onChange={setAnswer}
                  height={100}
                />
              </div>
              <Dropdown>
                <DropdownTrigger className='hidden sm:flex'>
                  <Button endContent={<ChevronDownIcon className='text-small' />}>
                    {selectedValue}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  aria-label='Table Columns'
                  closeOnSelect={false}
                  selectionMode='single'
                  selectedKeys={selectedKeys}
                  onSelectionChange={setSelectedKeys as any}
                >
                  {categories.categories.children.map((categoryItem) => (
                    <DropdownItem
                      key={categoryItem.id}
                      className='capitalize'
                    >
                      {categoryItem.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postItemHook.mutate(
                      {
                        categoryId: Array.from(selectedKeys)[0],
                        question,
                        answer,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getFaqs']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && item?.id)
                    patchItemHook.mutate(
                      {
                        id: item.id.toString(),
                        question,
                        answer,
                        categoryId: Array.from(selectedKeys)[0],
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getFaqs']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
