import * as yup from 'yup';

import { SCHEMA_MESSAGE } from '../message';

export const DELF_format = [
  {
    subject: 'reading',
    property: [
      {
        content: 'reading',
        alterText: '독해',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 25,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'listening',
    property: [
      {
        content: 'listening',
        alterText: '듣기',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 25,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'speaking',
    property: [
      {
        content: 'speaking',
        alterText: '회화',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 25,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'writing',
    property: [
      {
        content: 'writing',
        alterText: '작문',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 25,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
  {
    subject: 'totalScore',
    property: [
      {
        content: 'totalScore',
        alterText: '총점',
        type: 'title' as const,
      },
      {
        content: '점수',
        maxValue: 100,
        type: 'input' as const,
        defaultValue: 0,
        allowFloat: true,
      },
    ],
  },
];

export const DELF_schema = yup
  .object()
  .shape({
    transcript: yup
      .string()
      .required(
        SCHEMA_MESSAGE.scoreMessage('DELF 시험', '파일을').customMessage('업로드 해주세요.'),
      ),
    examDate: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '시험일자를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '시험일자를').requiredMessage()),
    publishingOrganization: yup
      .string()
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '발급기관을').requiredMessage()),
    reading: yup
      .number()
      .min(5, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '독해점수는').minMessage(5))
      .max(25, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '독해점수는').maxMessage(25))
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '독해점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '독해점수를').requiredMessage()),
    listening: yup
      .number()
      .min(5, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '듣기점수는').minMessage(5))
      .max(25, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '듣기점수는').maxMessage(25))
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '듣기점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '듣기점수를').requiredMessage()),
    speaking: yup
      .number()
      .min(5, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '회화점수는').minMessage(5))
      .max(25, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '회화점수는').maxMessage(25))
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '회화점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '회화점수를').requiredMessage()),
    writing: yup
      .number()
      .min(5, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '작문점수는').minMessage(5))
      .max(25, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '작문점수는').maxMessage(25))
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '작문점수를').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '작문점수를').requiredMessage()),
    grade: yup
      .mixed()
      .oneOf(['C2', 'C1', 'B2', 'B1', 'A2', 'A1'], '')
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '등급을').requiredMessage('선택')),
    totalScore: yup
      .number()
      .min(5, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '총점은').minMessage(5))
      .max(100, SCHEMA_MESSAGE.scoreMessage('DELF 시험', '총점은').maxMessage(100))
      .required(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '총점을').requiredMessage())
      .typeError(SCHEMA_MESSAGE.scoreMessage('DELF 시험', '총점을').requiredMessage()),
  })
  .default(null)
  .nullable();
