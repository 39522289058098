import useQuery from '@simppl/react-query/useQuery';
import { universities } from '@simppl/repository/universities';
import type { GetUniversitiesReqType } from '@simppl/repository/universities/req';

export const useUniversities = (body: GetUniversitiesReqType) => {
  const { data, refetch } = useQuery([`universities`, body.name, body.page, body.size], () =>
    universities.get(body),
  );
  return {
    data: data?.data.universities ?? [],
    refetch,
  };
};
