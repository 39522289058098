import { Modal, ModalBody, ModalContent, ModalHeader, Tab, Tabs } from '@nextui-org/react';

import ActivityAwardPage from '../score/edit/ActivityAwardPage';
import HighSchoolPage from '../score/edit/HighSchoolPage';
import LanguageProficiency from '../score/edit/LanguageProficiency';
import StandardPage from '../score/edit/StandardPage';

type EditScoreModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
};

export function EditScoreModal({ isOpen, onOpenChange, onClose }: EditScoreModalProps) {
  return (
    <Modal
      size='5xl'
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader>성적 수정</ModalHeader>
            <ModalBody>
              <Tabs>
                <Tab
                  key='report-cards'
                  title='고교 성적'
                >
                  <div className='flex h-[70vh] w-full flex-col items-center overflow-auto'>
                    <HighSchoolPage />
                  </div>
                </Tab>
                <Tab
                  key='language-tests'
                  title='어학 성적'
                >
                  <div className='flex h-[70vh] w-full flex-col items-center overflow-auto'>
                    <LanguageProficiency />
                  </div>
                </Tab>
                <Tab
                  key='standarized-tests'
                  title='표준화 성적'
                >
                  <div className='flex h-[70vh] w-full flex-col items-center overflow-auto'>
                    <StandardPage />
                  </div>
                </Tab>
                <Tab
                  key='activities-awards'
                  title='활동 성적'
                >
                  <div className='flex h-[70vh] w-full flex-col items-center overflow-auto'>
                    <ActivityAwardPage />
                  </div>
                </Tab>
              </Tabs>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
