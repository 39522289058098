/* eslint-disable react/no-array-index-key */

/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Title } from '@component/score/common/form';
import { TOEFL_format } from '@schema/language/toefl';
import Spacing from '@simppl/component/Spacing';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type TOEFLFormProps = {
  content: string;
};

export function TOEFLForm({ content }: TOEFLFormProps) {
  const methods = useFormContext();

  useEffect(() => {
    const languageTestId = methods.watch(`${content}.languageTestId`);
    if (languageTestId) {
      methods.setValue(`${content}.testId`, languageTestId);
      const [writing, lstening, speaking, reading] = methods.getValues([
        `${content}.writing`,
        `${content}.listening`,
        `${content}.speaking`,
        `${content}.reading`,
      ]);
      methods.setValue(`${content}.total`, writing + lstening + speaking + reading);
    }
  }, []);
  return (
    <div className='grid gap-[24px] [grid-template-areas:"grade_others"]'>
      <Spacing width='124px' />
      <div className='flex flex-col gap-[24px] [grid-area:others]'>
        {TOEFL_format.map(({ subject, property }, index) => (
          <div
            key={index}
            className='flex gap-[24px]'
          >
            {property.map((propertyItem) => (
              <>
                {propertyItem.type === 'input' && (
                  <Input
                    key={propertyItem.content}
                    {...propertyItem}
                    name={`${content}.${subject}`}
                  />
                )}
                {propertyItem.type === 'title' && (
                  <Title
                    key={propertyItem.content}
                    {...propertyItem}
                  />
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
