/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useSimulatedApplication } from '@hook/useSimulatedApplication';
import { useUniversitiesMajors } from '@hook/useUniversitiesMajors';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { admission } from '@simppl/repository/admission';
import { useState } from 'react';

type AddAdmissionModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
};

export function AddAdmissionModal(props: AddAdmissionModalProps) {
  const [majorKeys, setMajorKeys] = useState(new Set(['']));

  const [selectedKeys, setSelectedKeys] = useState('');

  const [isDupplication, setIsDupplication] = useState(false);
  const [name, setName] = useState('');
  const [appliedCount, setAppliedCount] = useState<number>(0);

  const simulatedApplication = useSimulatedApplication();
  const simulatedApplicationMap = simulatedApplication.data.reduce(
    (acc, item) => ({ ...acc, [item.simulatedApplicationId]: item }),
    {},
  ) as any;

  const majors = useUniversitiesMajors({
    universityId: simulatedApplicationMap[selectedKeys as never]?.universityId,
  }).data;

  const queryClient = useQueryClient();

  const postUniversitiesHook = useMutation(admission.post);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>모집단위 추가</ModalHeader>
            <ModalBody>
              <Input
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                label='이름'
                labelPlacement='outside'
              />
              <Input
                type='number'
                value={appliedCount as any}
                onChange={(e) => setAppliedCount(parseInt(e.target.value, 10))}
                label='지원인원'
                labelPlacement='outside'
              />

              <Checkbox
                isSelected={isDupplication}
                onValueChange={setIsDupplication}
              >
                중복 지원 여부
              </Checkbox>
              <Autocomplete
                selectedKey={selectedKeys}
                onSelectionChange={setSelectedKeys as any}
              >
                {simulatedApplication.data.map((categoryItem) => (
                  <AutocompleteItem
                    key={categoryItem.simulatedApplicationId}
                    value={categoryItem.simulatedApplicationId}
                    className='capitalize'
                  >
                    {categoryItem.name}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
              <Select
                disallowEmptySelection
                aria-label='Table Columns'
                selectionMode='multiple'
                selectedKeys={majorKeys}
                onSelectionChange={setMajorKeys as any}
              >
                {majors?.map((categoryItem) => (
                  <SelectItem
                    key={categoryItem.universityMajorId}
                    value={categoryItem.universityMajorId}
                    className='capitalize'
                  >
                    {categoryItem.name}
                  </SelectItem>
                ))}
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  postUniversitiesHook.mutate(
                    {
                      type: 'medical',
                      universityMajorIdList: Array.from(majorKeys).filter((item) => item !== ''),
                      simulatedApplicationId: selectedKeys,
                      isDupplication: false,
                      appliedCount,
                      name,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['getCompetitiveUnits']);
                        props.onClose();
                      },
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
