/* eslint-disable react-hooks/exhaustive-deps */
import { CheckBox, ComboBox, Input, Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import { ErrorMessage } from '@hookform/error-message';
import { APSubject } from '@schema/standardized/ap';
import useMutation from '@simppl/react-query/useMutation';
import {
  deleteUserReportCardDetails,
  postReportCardSubjects,
  reportCardDetail,
  reportCardDetailAdmin,
} from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type APFormProps = {
  content: string;
};

export function APForm({ content }: APFormProps) {
  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${content}.reportCardDetailList`,
  });

  return (
    <>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${content}.reportCardDetailList`}
        render={({ message }) => <p className='text-[12px] text-red-600'>{message}</p>}
      />
      <div className='flex w-full flex-col gap-2'>
        {fields.map(({ id }, index) => (
          <Form
            content={content}
            index={index}
            remove={remove}
            key={id}
          />
        ))}
      </div>
      <div className='pl-[148px]'>
        <button
          type='button'
          className='flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]'
          onClick={() =>
            append({
              reportCardSubjectId: null,
              apScore: '0',
              isAp: false,
            })
          }
        >
          <Plus color='white' />
          과목추가 (선택)
        </button>
      </div>
    </>
  );
}

type FormProps = {
  content: string;
  index: number;
  remove: (index: number) => void;
};

function Form({ content, index, remove }: FormProps) {
  const methods = useFormContext();
  const subjectIds = useReportCardSubjectsIds();
  const deleteUserReportCardDetailHook = useMutation(reportCardDetailAdmin.delete);
  const postReportCardSubjectsHook = useMutation((name: string) => postReportCardSubjects(name));

  useEffect(() => {
    const reportCardSubjectId =
      methods.watch(`${content}.reportCardDetailList.${index}.reportCardSubjectId`) ?? null;
    const reportCardSubjectName = methods.watch(
      `${content}.reportCardDetailList.${index}.reportCardSubjectName`,
    );
    if (reportCardSubjectName) {
      methods.setValue(`${content}.reportCardDetailList.${index}.name`, reportCardSubjectName);
    }
    const name = methods.watch(`${content}.reportCardDetailList.${index}.name`) ?? null;
    if (reportCardSubjectId === null && name !== null) {
      methods.setValue(
        `${content}.reportCardDetailList.${index}.reportCardSubjectId`,
        subjectIds[name as keyof typeof subjectIds] ?? '',
      );
    }
    if (reportCardSubjectId !== null && name === null) {
      methods.setValue(
        `${content}.reportCardDetailList.${index}.name`,
        subjectIds[reportCardSubjectId as keyof typeof subjectIds],
      );
    }
  }, []);

  return (
    <div className='mr-[82px] flex justify-end gap-[24px]'>
      <Controller
        name={`${content}.reportCardDetailList.${index}`}
        control={methods.control}
        render={({ field }) => (
          <>
            <div className='flex justify-end gap-[9px]'>
              <>
                <CheckBox
                  type='checkbox'
                  content='isAp'
                  defaultValue={false}
                  name={`${field.name}.isAp`}
                  title='AP'
                />
                <ComboBox
                  type='combobox'
                  placeholder='과목 검색'
                  category={APSubject}
                  forceDisable={methods.getValues(`${field.name}.reportCardSubjectName`)}
                  useFlag={false}
                  content={methods.watch(`${field.name}.name`) ?? ''}
                  name={`${field.name}.name`}
                  onBlur={() => {
                    setTimeout(() => {
                      const name = methods.watch(`${field.name}.name`);
                      if (name !== '' && !subjectIds[name as keyof typeof subjectIds]) {
                        postReportCardSubjectsHook.mutate(name, {
                          onSuccess: ({ reportCardSubjectId }) => {
                            methods.setValue(
                              `${field.name}.reportCardSubjectId`,
                              reportCardSubjectId,
                            );
                          },
                        });
                      } else if (name !== '' && subjectIds[name as keyof typeof subjectIds]) {
                        methods.setValue(
                          `${field.name}.reportCardSubjectId`,
                          subjectIds[name as keyof typeof subjectIds],
                        );
                      }
                    }, 100);
                  }}
                />
                <Input
                  {...field}
                  type='input'
                  inputType='stringInput'
                  content='apScore'
                  name={`${field.name}.apScore`}
                  defaultValue={0}
                  size='short'
                  alterText='점수'
                />
              </>
            </div>
            <button
              className=' flex  h-[40px]  w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-red-400 text-red-400'
              type='button'
              onClick={() => {
                const reportCardDetailId = methods.watch(
                  `${content}.reportCardDetailList.${index}.reportCardDetailId`,
                );

                if (reportCardDetailId)
                  deleteUserReportCardDetailHook.mutate(
                    { reportCardDetailId },
                    {
                      onSuccess: () => remove(index),
                    },
                  );
                else {
                  remove(index);
                }
              }}
            >
              <Minus />
            </button>
          </>
        )}
      />
    </div>
  );
}
