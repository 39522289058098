import { Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { ErrorMessage } from '@hookform/error-message';
import { activityAwardTestMap } from '@schema/activityAward';
import Text from '@simppl/component/Text';
import getImgUrl from '@simppl/util/getImgUrl';
import { useFormContext } from 'react-hook-form';

import { UploaderInput } from '../../common/uploader/UploaderInput';

type ActivityAwardInputFormProps = {
  name: string;
  removeSemester: () => void;
};

const activityAwardTest = {
  ACTIVITY: '활동',
  AWARD: '수상',
};

export function ActivityAwardInputForm({ name, removeSemester }: ActivityAwardInputFormProps) {
  const methods = useFormContext();

  return (
    <div className='flex w-[890px] flex-col items-center justify-center gap-2  px-[30px] py-[48px]'>
      <div className='flex w-full items-center gap-[24px]'>
        <Select
          className='w-[124px]'
          placeholder='수상/활동'
          defaultValue='ACTIVITY'
          content={methods.watch(`${name}.subject`)}
          type='select'
          category={activityAwardTest}
          name={`${name}.subject`}
          onChange={() => {
            methods.resetField(`${name}.division`);
            methods.resetField(`${name}.type`);
          }}
        />
        <Select
          className='w-[155px]'
          placeholder='교내/교외'
          content={methods.watch(`${name}.division`)}
          type='select'
          category={
            activityAwardTestMap[methods.watch(`${name}.subject`) as keyof typeof activityAwardTest]
              ?.defaultFormat?.division ?? {}
          }
          name={`${name}.division`}
        />
        <Select
          className='w-[155px]'
          placeholder='성격'
          content={methods.watch(`${name}.type`)}
          type='select'
          category={
            activityAwardTestMap[methods.watch(`${name}.subject`) as keyof typeof activityAwardTest]
              ?.defaultFormat?.type ?? {}
          }
          name={`${name}.type`}
        />
        <UploaderInput
          name={name}
          testName={methods.watch(`${name}.subject`)}
          testType='activities-awards'
          alterText='증빙자료.pdf'
        />
        <DeleteExamButton onClick={removeSemester} />
      </div>
      <div className='flex w-full flex-col items-start justify-center gap-[24px] pl-[146px]'>
        <div className='flex items-center gap-[10px] w-[515px]'>
          {methods.watch(`${name}.subject`) === 'ACTIVITY' ? (
            <ActivityDateRange name={name} />
          ) : (
            <AwardDateInput name={`${name}.awardDate`} />
          )}
          <PublishingOrganizationInput name={`${name}.publishingOrganization`} />
        </div>
        <TextArea name={`${name}.content`} />
      </div>
    </div>
  );
}

function PublishingOrganizationInput({ name }: { name: string }) {
  const methods = useFormContext();
  return (
    <div className='relative h-[40px] w-[254px] rounded-[4px]'>
      <input
        placeholder='발급기관'
        className='h-[40px] w-full rounded-[4px] border-[1px] border-blue-600 p-1 placeholder:text-blue-600'
        {...methods.register(name)}
      />
      <img
        className='absolute right-[8px] top-[50%] translate-y-[-50%]'
        src={getImgUrl('/asset/Button/PublishingOrganization.svg')}
        alt='발급기관'
      />
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function TextArea({ name }: { name: string }) {
  const methods = useFormContext();
  return (
    <div className='relative h-[149px] w-[515px]'>
      <textarea
        className='font-Pretendard-Regular h-[149px] w-[515px] rounded-[4px] border-[0.5px] border-blue-600 p-[16px_24px_32px_16px]'
        maxLength={300}
        {...methods.register(name)}
      />
      <div className='absolute bottom-[8px] right-[8px] flex items-center'>
        <Text
          color='text-blue-500'
          weight='Regular'
          as='Label'
          size={2}
        >
          {methods.watch(name)?.length ?? 0}
        </Text>
        <Text
          color='text-gray-400'
          className='whitespace-pre'
          weight='Regular'
          as='Label'
          size={2}
        >
          {` / 300`}
        </Text>
      </div>

      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function AwardDateInput({ name }: { name: string }) {
  const methods = useFormContext();
  return (
    <div className='relative'>
      <input
        type='date'
        placeholder='수상일'
        className='h-[40px] w-[252px] rounded-[4px] border-[1px] border-blue-600 p-1'
        {...methods.register(name)}
      />
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function ActivityDateRange({ name }: { name: string }) {
  const methods = useFormContext();
  return (
    <div className='relative'>
      <div className='flex h-[40px] w-[252px] items-center gap-1 rounded-[4px] border-[1px] border-blue-600 p-1'>
        <input
          type='date'
          placeholder='활동시작일'
          {...methods.register(`${name}.startDate`)}
        />
        <Text
          color='text-blue-600'
          weight='Regular'
          as='Label'
          size={1}
        >
          ~
        </Text>
        <input
          type='date'
          placeholder='활동종료일'
          {...methods.register(`${name}.endDate`)}
        />
      </div>
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${name}.startDate`}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${name}.endDate`}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] right-0 text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function DeleteExamButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type='button'
      className=' flex   h-[40px]   w-[124px] items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 px-[22px] py-[8px] text-blue-700'
      onClick={onClick}
    >
      <Minus />
      성적 삭제
    </button>
  );
}
