import { AddUserPaymentModal } from '@component/database/userPayment/AddUserPaymentModal';
import { UpdateUserPaymentModal } from '@component/database/userPayment/UpdateUserPaymentModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useUserPayments } from '@hook/useUserPayment';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { paymentsHistories } from '@simppl/repository/paymentHistories';
import { Search } from 'lucide-react';
import { useState } from 'react';

export default function UserPaymentPage() {
  const { data } = useUserPayments();
  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(
      data,
      (userPayment, value) =>
        userPayment.userName.includes(value) || userPayment.userEmail.includes(value),
    );
  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; paymentHistoryId: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddUserPaymentModalOpen,
    onOpenChange: onAddUserPaymentModalOpenChange,
    onOpen: onAddUserPaymentModalOpen,
    onClose: onAddUserPaymentModalClose,
  } = useDisclosure();
  const deleteUserPaymentsHook = useMutation(paymentsHistories.delete);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isAddUserPaymentModalOpen && (
          <AddUserPaymentModal
            isOpen={isAddUserPaymentModalOpen}
            onOpenChange={onAddUserPaymentModalOpenChange}
            onClose={onAddUserPaymentModalClose}
          />
        )}
        {isOpen && modalState !== null && modalState.mode === 'edit' && (
          <UpdateUserPaymentModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            paymentHistoryId={modalState.paymentHistoryId}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>유저상품 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      onAddUserPaymentModalOpen();
                    }}
                  >
                    유저상품 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='userName'>유저 이메일</TableColumn>
              <TableColumn key='userName'>유저 이름</TableColumn>
              <TableColumn key='itemName'>상품명</TableColumn>
              <TableColumn key='itemPrice'>상품 가격</TableColumn>
              <TableColumn key='price'>지불 방법</TableColumn>
              <TableColumn key='count'>구매 횟수</TableColumn>
              <TableColumn key='status'>상태</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={items}>
              {(item) => (
                <TableRow
                  className='relative cursor-pointer'
                  key={item.itemId}
                >
                  <TableCell>{item.userEmail}</TableCell>
                  <TableCell>{item.userName}</TableCell>
                  <TableCell>{item.itemName}</TableCell>
                  <TableCell>{item.itemPrice}</TableCell>
                  <TableCell>{item.payMethod}</TableCell>
                  <TableCell>{item.count}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({
                              mode: 'edit',
                              paymentHistoryId: item.paymentHistoryId,
                            });
                            onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            deleteUserPaymentsHook.mutate(
                              { paymentHistoryId: item.paymentHistoryId },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['userPayments']);
                                },
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
