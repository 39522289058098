/* eslint-disable no-unsafe-optional-chaining */
export function subjectToScore(testName: string, data: any) {
  switch (testName) {
    case 'ACT':
      return data?.reading + data?.math + data?.english + data?.scienceReading;
    case 'IB':
      return data?.subjectList.reduce(
        (acc: number, subject: any) => acc + parseInt(subject.score, 10),
        0,
      );
    case 'A-LEVEL':
      return data?.subjectList.reduce(
        (acc: number, subject: any) => acc + parseInt(subject.score, 10),
        0,
      );
    case 'AP':
      return data?.subjectList.reduce(
        (acc: number, subject: any) => acc + parseInt(subject.score, 10),
        0,
      );
    case 'SAT':
      return data?.ebrw + data?.math;
    case 'ETC':
      return (
        data.subjectList.reduce(
          (acc: number, subject: any) => acc + parseInt(subject.totalScore, 10),
          0,
        ) / data.subjectList.length
      );
    default:
      return null;
  }
}
