type BackButtonProps = {
  onClick: () => void;
};
export function BackButton({ onClick }: BackButtonProps) {
  return (
    <button onClick={onClick}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.4859 0.331147C14.0921 0.836237 14.1739 1.73704 13.6689 2.34315L7.28814 10L13.6689 17.6569C14.1739 18.263 14.0921 19.1638 13.4859 19.6689C12.8798 20.1739 11.979 20.0921 11.4739 19.4859L4.33111 10.9145C3.88963 10.3848 3.88963 9.61523 4.33111 9.08545L11.4739 0.514056C11.979 -0.0920515 12.8798 -0.173943 13.4859 0.331147Z'
          fill='#1F64DC'
        />
      </svg>
    </button>
  );
}
