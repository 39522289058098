import SwitchCase from '@simppl/component/SwitchCase';
import { memo } from 'react';

import { DELEForm } from './DELE';
import { DELFForm } from './DELF';
import { HSKForm } from './HSK';
import { IELTSForm } from './IELTS';
import { TEPSForm } from './TEPS';
import { TOEFLForm } from './TOEFL';
import { TOEICForm } from './TOEIC';

type LanguageFormProps = {
  testName: string;
};
export const LanguageForm = memo(({ testName }: LanguageFormProps) => {
  const content = 'edit';
  return (
    <SwitchCase
      value={testName}
      caseBy={{
        TEPS: <TEPSForm content={content} />,
        TOEFL: <TOEFLForm content={content} />,
        TOEIC: <TOEICForm content={content} />,
        HSK: <HSKForm content={content} />,
        DELE: <DELEForm content={content} />,
        DELF: <DELFForm content={content} />,
        IELTS: <IELTSForm content={content} />,
      }}
    />
  );
});
