/* eslint-disable no-param-reassign */
import { Card, Edit, List, Minus, Plus } from '@component/score/common';
import { AddSubjectView } from '@component/score/common/AddSubjectView';
import { CardView } from '@component/score/common/CardView';
import { ListView } from '@component/score/common/ListView';
import { DeleteHighSchoolConfirm } from '@component/score/test/highSchool/DeleteHighSchoolConfirm';
import { HighSchoolListView } from '@component/score/test/highSchool/listView';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import { reportCards as reportCardsApi } from '@simppl/repository/test';
import type { HighSchoolTestResType } from '@simppl/repository/test/res';
import c from '@simppl/util/c';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { HighSchoolModeContext } from './Provider';

type ViewTestProps = {
  highSchools: {
    countryId: string;
    countryName: string;
    schoolName: string;
    highschoolId: string;
    reportCardList: HighSchoolTestResType['reportCardList'];
  }[];
  appendHighSchool: Function;
  removeHighSchool: Function;
};

export function ViewTest({ highSchools, appendHighSchool, removeHighSchool }: ViewTestProps) {
  return (
    <>
      <div className='flex w-full flex-col gap-[32px]'>
        {highSchools.map((reporcards, index) => (
          <ViewTestCard
            index={index}
            key={reporcards.highschoolId}
            reportCards={reporcards}
            removeHighSchool={removeHighSchool}
          />
        ))}
      </div>
      <Spacing height='32px' />
      <CreateSchoolButton appendHighSchool={appendHighSchool} />
    </>
  );
}

type CreateSchoolButtonProps = {
  appendHighSchool: Function;
};

function CreateSchoolButton({ appendHighSchool }: CreateSchoolButtonProps) {
  const { changeMode } = useContext(HighSchoolModeContext);

  return (
    <button
      type='button'
      className='flex h-[58px] w-full items-center justify-center gap-[8px] rounded-[8px] border-[1px] border-blue-500 text-blue-500 '
      onClick={() => {
        changeMode('editSchool', {
          appendHighSchool,
          mode: 'new',
        });
      }}
    >
      <Plus />
      <Text
        color='text-blue-500'
        as='Title'
        size={1}
        weight='Medium'
      >
        학교 추가
      </Text>
    </button>
  );
}

type ViewTestCardProps = {
  index: number;
  reportCards: {
    countryId: string;
    countryName: string;
    schoolName: string;
    highschoolId: string;
    reportCardList: HighSchoolTestResType['reportCardList'];
  };
  removeHighSchool: Function;
};
function ViewTestCard({ reportCards, index, removeHighSchool }: ViewTestCardProps) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { changeMode } = useContext(HighSchoolModeContext);
  const [mode, setMode] = useState<'list' | 'card'>('card');
  const subjectIds = useReportCardSubjectsIds();
  const { id } = useParams();

  // eslint-disable-next-line no-shadow
  const handleChangeMode = (mode: 'list' | 'card') => {
    setMode(mode);
  };

  return (
    <div className='border-Blue-Ct-2 flex w-full flex-col rounded-[16px] border-[1px] px-[51px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
      <div className='flex w-full items-center justify-center gap-[15px]'>
        <Text
          color='text-Blue-4'
          weight='Bold'
          as='Label'
          size={1}
        >
          {reportCards?.schoolName ?? '학교명'}
        </Text>
        <button
          type='button'
          onClick={() => {
            changeMode('editSchool', {
              mode: 'edit',
              editData: {
                index,
                highschoolId: reportCards.highschoolId,
                countryName: reportCards.countryName,
                schoolName: reportCards.schoolName,
                countryId: reportCards.countryId,
              },
            });
          }}
        >
          <Edit />
        </button>
      </div>
      <Spacing height='32px' />
      <div className='flex w-full justify-center gap-[22px]'>
        <button
          type='button'
          onClick={() => handleChangeMode('card')}
        >
          <Card disabled={mode !== 'card'} />
        </button>
        <button
          type='button'
          onClick={() => handleChangeMode('list')}
        >
          <List disabled={mode !== 'list'} />
        </button>
      </div>
      <Spacing height='32px' />
      <div
        className={c(
          'flex w-full flex-wrap gap-[32px]',
          mode === 'list' && 'flex-col justify-center',
        )}
      >
        {reportCards.reportCardList.map((data) => {
          return mode === 'card' ? (
            <CardView
              key={data.reportCardId}
              onClick={() => {
                changeMode('editScore', {
                  mode: 'edit',
                  highschoolId: reportCards.highschoolId,
                  data,
                });
              }}
              semester={`${data.grade}학년 ${data.semester}학기`}
              testName={data.curriculum === 'KOREA' ? '한국학교' : data.curriculum}
              score={data.gpa}
              countOfSubjects={data.reportCardDetailList?.length}
            />
          ) : (
            <ListView
              key={data.reportCardId}
              onClick={() => {
                changeMode('editScore', {
                  mode: 'edit',
                  highschoolId: reportCards.highschoolId,
                  data,
                });
              }}
              semester={`${data.grade}학년 ${data.semester}학기`}
              testName={data.curriculum === 'KOREA' ? '한국학교' : data.curriculum}
              score={data.gpa}
              countOfSubjects={data.reportCardDetailList?.length}
            >
              <HighSchoolListView
                testName={data.curriculum}
                reportCardDetailList={data.reportCardDetailList}
              />
            </ListView>
          );
        })}
        <AddSubjectView
          title='학년 / 학기 / 성적'
          mode={mode}
          onClick={() => {
            // 학기 추가시 마지막 커리큘럼을 추가하기 위한 로직
            changeMode('editScore', {
              mode: 'new',
              highschoolId: reportCards.highschoolId,
            });
          }}
        />
      </div>
      <Spacing height='32px' />
      <button
        className=' flex   h-[40px]   min-w-[40px] items-center justify-center whitespace-pre rounded-[4px] border-[1px] border-blue-500 text-blue-500'
        type='button'
        onClick={() => {
          setDeleteOpen(true);
        }}
      >
        <Minus />
        {` 학교 삭제`}
      </button>
      <DeleteHighSchoolConfirm
        open={deleteOpen}
        onConfirm={async () => {
          const res = await Promise.all(
            reportCards.reportCardList.map(({ reportCardId }) =>
              reportCardsApi.delete({
                reportCardId,
              }),
            ),
          );

          if (res.filter((r) => r.status !== 200).length > 0) {
            alert('삭제중에 에러가 발생했습니다. 다시 시도해주세요.');
          }
          removeHighSchool(reportCards.highschoolId);

          setDeleteOpen(false);
          changeMode('viewTest');
        }}
        onClose={() => setDeleteOpen(false)}
      />
    </div>
  );
}
