/* eslint-disable react/no-array-index-key */
import { Input, Title } from '@component/score/common/form';
import { ACT_format } from '@schema/standardized/act';
import Spacing from '@simppl/component/Spacing';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type ACTFormProps = {
  content: string;
};

export function ACTForm({ content }: ACTFormProps) {
  const methods = useFormContext();

  useEffect(() => {
    const standarizedTestId = methods.watch(`${content}.standarizedTestId`);
    if (standarizedTestId) methods.setValue(`${content}.testId`, standarizedTestId);
  }, []);

  return (
    <div className='grid gap-[24px] [grid-template-areas:"grade_others"]'>
      <Spacing width='124px' />
      <div className='flex flex-col gap-[24px] [grid-area:others]'>
        {ACT_format.map(({ subject, property }, index) => (
          <div
            key={index}
            className='flex gap-[24px]'
          >
            {property.map((propertyItem) => (
              <>
                {propertyItem.type === 'input' && (
                  <Input
                    key={propertyItem.content}
                    {...propertyItem}
                    name={`${content}.${subject}`}
                  />
                )}
                {propertyItem.type === 'title' && (
                  <Title
                    key={propertyItem.content}
                    {...propertyItem}
                  />
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
