/* eslint-disable no-alert */

/* eslint-disable no-param-reassign */

/* eslint-disable react/no-array-index-key */
import { BackButton, Input, UploaderInput } from '@component/score/common';
import { Minus } from '@component/score/common/icon/Minus';
import { LanguageForm, convertToETCAndotherData, scoreBlockContext } from '@component/score/test';
import { ErrorMessage } from '@hookform/error-message';
import { languageTestSchemaMap } from '@schema/language';
import SelectBox from '@simppl/component/SelectBox';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { postTest, deleteTestById } from '@simppl/repository/test';
import type { PostLanguageTestType } from '@simppl/repository/test/req';
import getImgUrl from '@simppl/util/getImgUrl';
import { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { LanguageScoreModeContext } from './Provider';

export function EditScore() {
  const { modeState, changeMode } = useContext(LanguageScoreModeContext);
  const methods = useFormContext();

  const [examName, setExamName] = useState('TOEIC');

  const { id } = useParams();

  const queryClient = useQueryClient();

  const postTestHook = useMutation((props: PostLanguageTestType) =>
    postTest('language-tests', props),
  );

  const deleteTestHook = useMutation((testId: string) => deleteTestById('language-tests', testId));

  useEffect(() => {
    if (modeState.formName) {
      methods.setValue(`edit`, methods.watch(modeState.formName));
      setExamName(modeState.formName);
    }
  }, []);

  const onSave = async () => {
    const data = methods.getValues();
    const edit = methods.watch(`edit`);
    try {
      await languageTestSchemaMap.validateSync({
        [examName]: edit,
      });
      const languageTest = convertToETCAndotherData({ ...data, [examName]: { ...edit } } as any);
      const body = {
        userId: id!,
        languageTest,
      } as PostLanguageTestType;

      if (Object.keys(languageTest).length === 0) {
        // 성적을 입력하지 않으면 다음으로 이동하도록 허용해야 합니다.
      } else if (Object.keys(data).length > 0) {
        postTestHook.mutate(body, {
          onSuccess: () => {
            queryClient.invalidateQueries(['language-tests-testByUserId']);
            changeMode('viewTest');
          },
          onError: (error: any) => {
            alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
            if (error?.response?.data?.message.length > 0) {
              // alert(error?.response?.data?.message);
            }
          },
        });
      }
    } catch (e: any) {
      alert(e.message);
    }
  };

  return (
    <>
      <div className='border-Blue-Ct-2 relative flex w-full flex-col rounded-[16px] border-[1px] px-[40px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
        <div className='absolute left-[42px] top-[32px]'>
          <BackButton
            onClick={() => {
              queryClient.invalidateQueries(['language-tests-testByUserId']);
              changeMode('viewTest');
              methods.resetField('edit');
            }}
          />
        </div>
        <div className='flex w-full flex-col items-center gap-[48px]'>
          <Text
            color='text-blue-500'
            weight='Bold'
            as='Title'
            size={1}
          >
            어학 성적 입력
          </Text>
          <div className='flex w-auto flex-col items-center gap-6 px-[107px] py-[48px]'>
            {examName === 'ETC' ? (
              <>
                <div className='flex w-full justify-between gap-[24px]'>
                  <ExamTitle
                    onChange={setExamName}
                    name={examName}
                  />
                  <DeleteExamButton
                    onClick={() => {
                      // 성적 삭제시 해당 시험 폼 데이터 초기화
                      methods.watch(`edit.subjectList`).forEach((_: any, index: number) => {
                        const testId = methods.watch(
                          `${examName}.subjectList.${index}.languageTestId`,
                        );
                        if (testId) {
                          deleteTestHook.mutate(testId, {
                            onSuccess: () => {
                              methods.resetField('edit');
                              methods.resetField(examName);
                              queryClient.invalidateQueries(['language-tests-testByUserId']);
                              changeMode('viewTest');
                            },
                          });
                        } else {
                          methods.resetField('edit');
                          methods.resetField(examName);
                          queryClient.invalidateQueries(['language-tests-testByUserId']);
                          changeMode('viewTest');
                        }
                      });
                    }}
                  />
                </div>
                <div className='flex w-[809px] flex-col items-start gap-6 '>
                  <div className='flex w-full flex-col gap-2'>
                    <ErrorMessage
                      errors={methods.formState.errors}
                      name='edit.subjectList'
                      render={({ message }) => (
                        <p className='text-[12px] text-red-600'>{message}</p>
                      )}
                    />

                    {methods.watch(`edit.subjectList`)?.map((_: any, index: number) => (
                      <Controller
                        key={index}
                        name={`edit.subjectList.${index}`}
                        control={methods.control}
                        render={({ field }) => (
                          <div className='flex flex-col gap-[24px]'>
                            <div className='flex items-center gap-[24px] pl-[146px]'>
                              <ExamDateInput name={`edit.subjectList.${index}.examDate`} />
                              <PublishingOrganizationInput
                                name={`edit.subjectList.${index}.publishingOrganization`}
                              />
                              <UploaderInput
                                name={`edit.subjectList.${index}`}
                                testName={examName}
                                testType={methods.watch('testType')}
                                alterText='기타성적.pdf'
                              />
                              <DeleteSubjectButton
                                onClick={() => {
                                  const testId = methods.watch(`${field.name}.languageTestId`);
                                  if (testId) {
                                    deleteTestHook.mutate(testId, {
                                      onSuccess: () => {
                                        methods.reset((prev) => {
                                          delete prev.edit.subjectList[index];
                                          return prev;
                                        });
                                      },
                                    });
                                  } else {
                                    methods.reset((prev) => {
                                      delete prev.edit.subjectList[index];
                                      return prev;
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className='flex justify-start gap-[24px] pl-[146px]'>
                              <div className='flex justify-end gap-[9px]'>
                                <Input
                                  type='input'
                                  {...field}
                                  content='name'
                                  alterText='시험명'
                                  inputType='stringInput'
                                  size={135}
                                  name={`edit.subjectList.${index}.name`}
                                />
                                <Input
                                  type='input'
                                  {...field}
                                  content='score'
                                  alterText='점수'
                                  size={135}
                                  defaultValue={0}
                                  name={`edit.subjectList.${index}.score`}
                                />
                                <Input
                                  type='input'
                                  {...field}
                                  content='totalScore'
                                  alterText='만점'
                                  size={135}
                                  defaultValue={0}
                                  name={`edit.subjectList.${index}.totalScore`}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    ))}
                  </div>
                  <div className='flex w-full justify-center'>
                    <button
                      type='button'
                      className='w-[513px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]'
                      onClick={() => {
                        methods.setValue(
                          `edit.subjectList.${methods.watch(`edit.subjectList`)?.length ?? 0}`,
                          {
                            name: null,
                            score: 0,
                            totalScore: 0,
                          },
                        );
                      }}
                    >
                      시험추가
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='flex items-center gap-[24px]'>
                  <ExamTitle
                    onChange={setExamName}
                    name={examName}
                  />
                  <ExamDateInput
                    key={examName}
                    name='edit.examDate'
                  />
                  <PublishingOrganizationInput name='edit.publishingOrganization' />
                  <UploaderInput
                    name='edit'
                    testName={examName}
                    testType={methods.watch('testType')}
                    alterText={`${examName} 성적.pdf`}
                  />
                  <DeleteExamButton
                    onClick={() => {
                      // 성적 삭제시 해당 시험 폼 데이터 초기화
                      const testId = methods.watch(`edit.languageTestId`);
                      if (testId) {
                        deleteTestHook.mutate(testId, {
                          onSuccess: () => {
                            methods.reset((prev) => {
                              delete prev[examName];
                              return prev;
                            });
                            queryClient.invalidateQueries(['language-tests-testByUserId']);
                            changeMode('viewTest');
                          },
                        });
                      } else {
                        methods.reset((prev) => {
                          // eslint-disable-next-line no-param-reassign
                          delete prev[examName];
                          return prev;
                        });
                        queryClient.invalidateQueries(['language-tests-testByUserId']);
                        changeMode('viewTest');
                      }
                    }}
                  />
                </div>
                <div className='flex w-[809px] flex-col items-start gap-6'>
                  <LanguageForm testName={examName} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <button
        type='button'
        className='h-[40px] w-full rounded-md border-[1px] border-blue-500 bg-blue-500 text-[white]'
        onClick={onSave}
      >
        저장
      </button>
    </>
  );
}

function ExamTitle({ name, onChange }: { name: string; onChange: (value: string) => void }) {
  const { category, list, handleChange } = useContext(scoreBlockContext);
  const methods = useFormContext();

  return (
    <SelectBox
      className='h-[40px] w-[124px] border-blue-500 text-blue-500'
      selectedColor='text-blue-500'
      currentValue={name ?? '시험 입력'}
      onClick={(value) => {
        handleChange(name, value);
        methods.resetField(name);
        methods.setValue('edit.examName', value);
        onChange(value);
      }}
      options={list}
      disables={Array.from(category.keys())}
    />
  );
}

function ExamDateInput({ name }: { name: string }) {
  const methods = useFormContext();

  return (
    <div className='relative'>
      <input
        type='date'
        placeholder='응시일'
        className='h-[40px] w-[155px] rounded-[4px] border-[1px] border-blue-600 p-1'
        {...methods.register(name)}
      />
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function PublishingOrganizationInput({ name }: { name: string }) {
  const methods = useFormContext();
  return (
    <div className='relative h-[40px] w-[155px] rounded-[4px]'>
      <input
        placeholder='발급기관'
        className='h-[40px] w-full rounded-[4px] border-[1px] border-blue-600 p-1 placeholder:text-blue-600'
        {...methods.register(name)}
      />
      <img
        className='absolute right-[8px] top-[50%] translate-y-[-50%]'
        src={getImgUrl('/asset/Button/PublishingOrganization.svg')}
        alt='발급기관'
      />
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function DeleteExamButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type='button'
      className=' flex   w-[124px]   items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 px-[22px] py-[8px] text-blue-700'
      onClick={onClick}
    >
      <Minus />
      성적 삭제
    </button>
  );
}

function DeleteSubjectButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type='button'
      className=' flex   w-[124px]   items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 px-[22px] py-[8px] text-blue-700'
      onClick={onClick}
    >
      <Minus />
      시험 삭제
    </button>
  );
}
