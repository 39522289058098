/* eslint-disable no-nested-ternary */

/* eslint-disable no-shadow */
import { useAdmissionsMajorLists } from '@hook/useAdmissionsMajorLists';
import { useAdmissionsUniversities } from '@hook/useAdmissionsUniversity';
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { putAdmissionsMajorsWithCount } from '@simppl/repository/admission';
import type { PutAdmissionsMajorsWithCountReqType } from '@simppl/repository/admission/req';
import { useEffect, useState } from 'react';

export default function AdmissionsPage() {
  const [type, setType] = useState<'3Y' | '12Y' | ''>('');
  const [university, setUniversity] = useState<
    { universityName: string; universityId: string } | undefined
  >();
  const [currentPage, setCurrentPage] = useState<'type' | 'university' | 'subject'>('type');

  const selectType = (selectedType: '3Y' | '12Y') => {
    setType(selectedType);
    setCurrentPage('university');
  };

  const selectUniversity = (selectedUniversity: {
    universityName: string;
    universityId: string;
  }) => {
    setUniversity(selectedUniversity);
    setCurrentPage('subject');
  };

  useEffect(() => {
    if (currentPage === 'type') {
      setType('');
    }
    if (currentPage === 'university') {
      setUniversity(undefined);
    }
  }, [currentPage]);

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        <CardHeader>
          <h1 className='text-2xl'>모의지원 인원 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <div className='flex flex-col gap-4'>
            <div className='flex w-full flex-row gap-4'>
              <div className='flex max-w-sm flex-1 flex-row items-center'>
                <Breadcrumbs onAction={(type) => setCurrentPage(type as 'type' | 'university')}>
                  <BreadcrumbItem
                    key='type'
                    isCurrent={currentPage === 'type'}
                  >
                    {type === '' ? '전형' : type === '3Y' ? '3년 특례' : '12년 특례'}
                  </BreadcrumbItem>
                  {type !== '' && (
                    <BreadcrumbItem
                      key='university'
                      isCurrent={currentPage === 'university'}
                    >
                      {university === undefined ? '대학' : university.universityName}
                    </BreadcrumbItem>
                  )}
                  {university !== undefined && (
                    <BreadcrumbItem
                      key='university'
                      isCurrent={currentPage === 'subject'}
                    >
                      모집 단위
                    </BreadcrumbItem>
                  )}
                </Breadcrumbs>
              </div>
            </div>
          </div>
          {type === '' && <TypeTable selectType={selectType} />}
          {type !== '' && university === undefined && (
            <UniversityTable
              type={type}
              selectUniversity={selectUniversity}
            />
          )}
          {type !== '' && university !== undefined && (
            <SubjectTable
              type={type}
              university={university}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

type TypeTableProps = {
  selectType: (selectedType: '3Y' | '12Y') => void;
};
function TypeTable({ selectType }: TypeTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableColumn className='text-center'>전형</TableColumn>
      </TableHeader>
      <TableBody>
        <TableRow className='relative cursor-pointer'>
          <TableCell onClick={() => selectType('12Y')}>12년</TableCell>
        </TableRow>
        <TableRow className='relative cursor-pointer'>
          <TableCell onClick={() => selectType('3Y')}>3년</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

type UniversityTableProps = {
  type: '3Y' | '12Y';
  selectUniversity: (selectedUniversity: { universityName: string; universityId: string }) => void;
};
function UniversityTable({ type, selectUniversity }: UniversityTableProps) {
  const { data } = useAdmissionsUniversities({
    // isMain null 전체 대학 조회
    name: '',
    page: 0,
    size: 10000,
  });

  if (!data?.universities) return null;

  return (
    <Table>
      <TableHeader>
        <TableColumn
          className='text-center'
          key='name'
        >
          대학 이름
        </TableColumn>
      </TableHeader>
      <TableBody items={data.universities}>
        {(university) => (
          <TableRow
            className='relative cursor-pointer'
            key={university.universityId}
          >
            <TableCell
              onClick={() =>
                selectUniversity({
                  universityId: university.universityId,
                  universityName: university.name,
                })
              }
            >
              {university.name}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

type SubjectTableProps = {
  type: '3Y' | '12Y';
  university: { universityName: string; universityId: string };
};

function SubjectTable({ type, university }: SubjectTableProps) {
  /**
   *  NOTE: 기획에서 핸들링 하기 쉽게, 입력시에만 총데이터에서 실 데이터를 뺀 나머지 데이터를 핸들링하도록 수정
   */
  const [selectedMajor, setSelectedMajor] = useState<
    | {
      admissionId: string;
      majorName: string;
      universityMajorId: string;
      appliedCount: number;
      realAppliedCount: number;
    }
    | undefined
  >(undefined);
  const { isOpen, onOpenChange, onOpen } = useDisclosure();
  const { data, refetch } = useAdmissionsMajorLists({
    type,
    name: '',
    universityId: university.universityId,
    page: 0,
    size: 99999,
    isAvailable: true
  });

  useEffect(() => {
    refetch();
  }, []);

  if (!data) return null;

  const [totalAppliedCount, totalRealAppliedCount] = data.reduce(
    (acc, value) => {
      acc[0] += value.appliedCount;
      acc[1] += value.realAppliedCount;
      return acc;
    },
    [0, 0],
  );

  return (
    <>
      {selectedMajor !== undefined && (
        <EditSubjectCountModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          selectedMajor={selectedMajor}
        />
      )}
      <Table
        isHeaderSticky
        classNames={{
          base: 'h-[700px] overflow-scroll',
        }}
        selectionMode='single'
      >
        <TableHeader>
          <TableColumn
            className='w-[336px] text-center'
            key='type'
          >
            모집단위
          </TableColumn>
          <TableColumn
            className='text-center'
            key='realAppliedCount'
          >
            지원인원
          </TableColumn>
          <TableColumn
            className='text-center'
            key='appliedCount'
          >
            입력인원
          </TableColumn>
          <TableColumn
            className='text-center'
            key='totalCount'
          >
            최종인원
          </TableColumn>
        </TableHeader>
        <TableBody items={data}>
          {(subject) => (
            <TableRow
              className='relative cursor-pointer text-center'
              key={subject.admissionId}
              onClick={() => {
                setSelectedMajor({
                  admissionId: subject.admissionId,
                  majorName: subject.majorName,
                  universityMajorId: subject.universityMajorId,
                  appliedCount: subject.appliedCount,
                  realAppliedCount: subject.realAppliedCount,
                });
                onOpen();
              }}
            >
              <TableCell className='w-[336px]'>{subject.majorName}</TableCell>
              <TableCell className='flex-1'>{subject.realAppliedCount}</TableCell>
              <TableCell className='flex-1'>
                {subject.appliedCount - subject.realAppliedCount}
              </TableCell>
              <TableCell className='flex-1'>{subject.appliedCount}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className='flex w-full p-4'>
        <div className='flex h-[40px] w-full items-center rounded-[0.5rem] bg-[#F4F4F5]'>
          <div className='w-[336px] px-3 text-start'>총 인원수</div>
          <div className='flex-1 px-3 text-center'>{totalRealAppliedCount}</div>
          <div className='flex-1 px-3 text-center'>{totalAppliedCount - totalRealAppliedCount}</div>
          <div className='flex-1 px-3 text-center'>{totalAppliedCount}</div>
        </div>
      </div>
    </>
  );
}

type EditSubjectCountModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  selectedMajor: {
    admissionId: string;
    majorName: string;
    universityMajorId: string;
    appliedCount: number;
    realAppliedCount: number;
  };
};

function EditSubjectCountModal({
  isOpen,
  onOpenChange,
  selectedMajor,
}: EditSubjectCountModalProps) {
  const [input, setInput] = useState(selectedMajor.appliedCount - selectedMajor.realAppliedCount);

  const queryClient = useQueryClient();
  const putAdmissionsMajorsWithCountHook = useMutation(
    (body: PutAdmissionsMajorsWithCountReqType) =>
      putAdmissionsMajorsWithCount(selectedMajor.admissionId, body),
  );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>{selectedMajor.majorName}</ModalHeader>
            <ModalBody>
              <Input
                type='number'
                value={input as any}
                onChange={(e) => setInput(e.target.value as any)}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  putAdmissionsMajorsWithCountHook.mutate(
                    {
                      count: Number(input) + selectedMajor.realAppliedCount,
                      universityMajorId: selectedMajor.universityMajorId,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['useAdmissionsMajorLists']);
                        onClose();
                      },
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
