/* eslint-disable no-param-reassign */

/* eslint-disable no-shadow */
import { Plus, UploaderButton } from '@component/score/common';
import { useCountries } from '@hook/useCountries';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import useTestByUserId from '@hook/useTestByUserId';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import type { HighSchoolTestResType } from '@simppl/repository/test/res';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EditHighSchool } from './EditHighSchool';
import { EditScore } from './EditScore';
import { HighSchoolModeContext, HighSchoolModeProvider } from './Provider';
import { ViewTest } from './ViewTest';

type ModeType = 'noData' | 'editSchool' | 'editScore' | 'viewTest';

const HighSchoolPage = () => {
  const [mode, setMode] = useState<ModeType>('viewTest');
  const [highSchools, setHighSchools] = useState<
    { countryId: string; countryName: string; schoolName: string; highschoolId: string }[]
  >([]);

  const { countryIdNameMap } = useCountries({});
  const subjectIds = useReportCardSubjectsIds();

  const { id } = useParams();
  const userData = useTestByUserId('report-cards', id!);

  const highSchoolTestMap = userData.reportCardList.reduce<
    Record<string, HighSchoolTestResType['reportCardList']>
  >((acc, reportCard) => {
    if (acc[reportCard.highschoolId]) {
      acc[reportCard.highschoolId].push(reportCard);
    } else {
      acc[reportCard.highschoolId] = [reportCard];
    }

    return acc;
  }, {});

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (userData) {
      methods.setValue('transcript' as any, userData.transcript);
    }
    if (highSchoolTestMap && countryIdNameMap) {
      setHighSchools(
        Object.keys(highSchoolTestMap).map((id) => ({
          highschoolId: id,
          countryId: highSchoolTestMap[id][0].countryId,
          countryName: countryIdNameMap[highSchoolTestMap[id][0].countryId],
          schoolName: highSchoolTestMap[id][0].highschoolName,
        })),
      );
    }
  }, [userData]);

  const appendHighSchool = (data: {
    countryId: string;
    countryName: string;
    schoolName: string;
    highschoolId: string;
  }) => {
    setHighSchools([...highSchools, data]);
  };

  const updateHighSchool = (
    index: number,
    data: {
      countryId: string;
      countryName: string;
      schoolName: string;
      highschoolId: string;
    },
  ) => {
    const newHighSchools = [...highSchools];
    newHighSchools[index] = data;
    setHighSchools(newHighSchools);
  };

  const removeHighSchool = (highschoolId: string) => {
    setHighSchools((prev) => prev.filter((data) => data.highschoolId !== highschoolId));
  };

  return (
    <HighSchoolModeProvider
      mode={mode}
      setMode={setMode}
    >
      <FormProvider {...methods}>
        <form
          id='test'
          // onSubmit={methods.handleSubmit(onSubmit, (errors) =>
          //   console.log(errors, methods.getValues()),
          // )} // 에러 확인을 위해 달았습니다.
          className='flex w-[915px] flex-col items-center'
        >
          <UploaderButton
            name=''
            testName='high-school-tests'
            testType='high-school-tests'
          />
          <Spacing height='32px' />
          {mode === 'noData' && <CreateSchoolButton appendHighSchool={appendHighSchool} />}
          {mode === 'editSchool' && (
            <EditHighSchool
              removeHighSchool={removeHighSchool}
              updateHighSchool={updateHighSchool}
            />
          )}
          {mode === 'viewTest' && (
            <ViewTest
              highSchools={highSchools.map((data) => ({
                ...data,
                reportCardList: highSchoolTestMap[data.highschoolId] ?? [],
              }))}
              appendHighSchool={appendHighSchool}
              removeHighSchool={removeHighSchool}
            />
          )}
          {mode === 'editScore' && <EditScore />}
          <Spacing height='80px' />
        </form>
      </FormProvider>
    </HighSchoolModeProvider>
  );
};

type CreateSchoolButtonProps = {
  appendHighSchool: Function;
};

function CreateSchoolButton({ appendHighSchool }: CreateSchoolButtonProps) {
  const { changeMode } = useContext(HighSchoolModeContext);

  return (
    <button
      type='button'
      className='flex h-[58px] w-full items-center justify-center gap-[8px] rounded-[8px] border-[1px] border-blue-500 text-blue-500 '
      onClick={() => {
        changeMode('editSchool', {
          appendHighSchool,
          mode: 'new',
        });
      }}
    >
      <Plus />
      <Text
        color='text-blue-500'
        as='Title'
        size={2}
        weight='Medium'
      >
        학교를 추가하여 성적 입력 시작
      </Text>
    </button>
  );
}

export default HighSchoolPage;
