import { Select } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { ErrorMessage } from '@hookform/error-message';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { deleteTestById } from '@simppl/repository/test';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { HightSchoolForm } from '.';

type HighSchoolInputFormProps = {
  name: string;
  removeSemester: () => void;
};

const highSchoolTest = {
  AP: 'AP',
  IB: 'IB',
  'A-LEVEL': 'A-LEVEL',
  ETC: 'ETC',
  KOREA: 'KOREA',
};

export function HighSchoolInputForm({ name, removeSemester }: HighSchoolInputFormProps) {
  const methods = useFormContext();

  const test = methods.watch(`${name}.subject`) as keyof typeof highSchoolTest;

  const queryClient = useQueryClient();
  const deleteTestByIdHook = useMutation((reportCardId: string) =>
    deleteTestById('report-cards', reportCardId),
  );
  return (
    <div className='border-Blue-Ct-2 flex w-auto flex-col items-center gap-6 px-[8px] py-[48px]'>
      <div className='flex gap-[24px]'>
        <Select
          placeholder='커리큘럼'
          content={methods.watch(`${name}.subject`)}
          type='select'
          category={highSchoolTest}
          name={`${name}.subject`}
        />
        <GradeInput name={`${name}.grade`} />
        <SemesterInput name={`${name}.semester`} />
        <GpaInput
          scoreName={`${name}.gpa`}
          totalName={`${name}.perfectScore`}
        />
        <DeleteExamButton
          onClick={() => {
            const reportCardId = methods.watch(`${name}.reportCardId`);
            if (reportCardId) {
              deleteTestByIdHook.mutate(reportCardId, {
                onSuccess: () => {
                  queryClient.invalidateQueries(['report-cards-testByUserId']);
                  removeSemester();
                },
              });
            } else {
              removeSemester();
            }
          }}
        />
      </div>
      <div className='flex w-[809px] flex-col items-start gap-6'>
        <HightSchoolForm
          testName={test}
          content={name}
        />
      </div>
    </div>
  );
}

function GradeInput({ name }: { name: string }) {
  const methods = useFormContext();

  return (
    <div className='relative flex w-[155px] items-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 px-[16px] py-[8px]'>
      <Text
        color='text-blue-600'
        weight='Regular'
        as='Label'
        size={1}
      >
        학년
      </Text>
      <div className='flex items-center'>
        <input
          className='line-[24px] w-full text-right text-[16px] outline-none'
          type='number'
          {...methods.register(name)}
        />
        <Text
          color='text-Base-Black'
          weight='Regular'
          as='Label'
          size={1}
        >
          학년
        </Text>
      </div>
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function SemesterInput({ name }: { name: string }) {
  const methods = useFormContext();

  return (
    <div className='relative flex w-[155px] items-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 px-[16px] py-[8px]'>
      <Text
        color='text-blue-600'
        weight='Regular'
        as='Label'
        size={1}
      >
        학기
      </Text>
      <div className='flex items-center'>
        <input
          className='line-[24px] w-full text-right text-[16px] outline-none'
          type='number'
          {...methods.register(name)}
        />
        <Text
          color='text-Base-Black'
          weight='Regular'
          as='Label'
          size={1}
        >
          학기
        </Text>
      </div>
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}

function GpaInput({ scoreName, totalName }: { scoreName: string; totalName: string }) {
  const methods = useFormContext();

  return (
    <div className='relative flex w-[155px] items-center whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 p-1'>
      <Text
        color='text-blue-600'
        weight='Regular'
        as='Label'
        size={1}
      >
        GPA
      </Text>
      <div className='flex items-center'>
        <input
          className='line-[24px] w-full text-right text-[16px] outline-none'
          placeholder='평점'
          type='number'
          step='0.01'
          {...methods.register(scoreName)}
        />
        <Text
          color='text-Black'
          weight='Medium'
          as='Label'
          size={1}
        >
          /
        </Text>
        <input
          className='line-[24px] w-full text-right text-[16px] outline-none'
          type='number'
          placeholder='만점'
          step='0.01'
          {...methods.register(totalName)}
        />
      </div>
      <div className='absolute bottom-[-20px] left-0 flex text-[12px] text-red-600'>
        <ErrorMessage
          errors={methods.formState.errors}
          name={scoreName}
          render={({ message }) => <p>{message}</p>}
        />
        <ErrorMessage
          errors={methods.formState.errors}
          name={totalName}
          render={({ message }) => <p>{message}</p>}
        />
      </div>
    </div>
  );
}

function DeleteExamButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type='button'
      className=' flex   w-[124px]   items-center justify-center gap-[8px] whitespace-nowrap rounded-[4px] border-[1px] border-blue-700 text-blue-700'
      onClick={onClick}
    >
      <Minus />
      성적 삭제
    </button>
  );
}
