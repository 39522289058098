/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useItem } from '@hook/useItem';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { items } from '@simppl/repository/items';
import { extractDateFromTimestamp } from '@simppl/util/date';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type ItemModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; itemId: string }
);

export function ItemModal(props: ItemModalProps) {
  const item =
    props.mode === 'edit' ? useItem().data?.find((item) => item.itemId === props.itemId) : null;

  const queryClient = useQueryClient();

  const postItemHook = useMutation(items.post);
  const patchItemHook = useMutation(items.patch);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const { control, handleSubmit } = methods;

  const [
    name,
    description,
    periodDescription,
    price,
    originalPrice,
    admissionCount,
    exposeStartAt,
    exposeEndAt,
    availableStartAt,
    availableEndAt,
  ] = [
    'name',
    'description',
    'periodDescription',
    'price',
    'originalPrice',
    'admissionCount',
    'exposeStartAt',
    'exposeEndAt',
    'availableStartAt',
    'availableEndAt',
  ].map((t) => methods.watch(t as any));

  useEffect(() => {
    if (props.mode === 'edit' && item) {
      methods.setValue('name', item.name);
      methods.setValue('description', item.description);
      methods.setValue('periodDescription', item.periodDescription);
      methods.setValue('price', item.price);
      methods.setValue('originalPrice', item.originalPrice);
      methods.setValue('admissionCount', item.admissionCount);
      methods.setValue('exposeStartAt', extractDateFromTimestamp(item.exposeStartAt));
      methods.setValue('exposeEndAt', extractDateFromTimestamp(item.exposeEndAt));
      methods.setValue('availableStartAt', extractDateFromTimestamp(item.availableStartAt));
      methods.setValue('availableEndAt', extractDateFromTimestamp(item.availableEndAt));
    }
  }, [props, item]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '상품 추가' : '상품 수정'}
            </ModalHeader>
            <ModalBody>
              <Controller
                name='name'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    labelPlacement='outside'
                    placeholder='이름'
                    {...field}
                  />
                )}
              />
              <Controller
                name='description'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    labelPlacement='outside'
                    placeholder='설명'
                    {...field}
                  />
                )}
              />
              <Controller
                name='periodDescription'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    labelPlacement='outside'
                    placeholder='기간 설명'
                    {...field}
                  />
                )}
              />
              <Controller
                name='price'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    type='number'
                    labelPlacement='outside'
                    placeholder='가격'
                    {...field}
                  />
                )}
              />
              <Controller
                name='originalPrice'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    type='number'
                    labelPlacement='outside'
                    placeholder='기존 가격'
                    {...field}
                  />
                )}
              />
              <Controller
                name='admissionCount'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    type='number'
                    labelPlacement='outside'
                    placeholder='모의 지원 횟수'
                    {...field}
                  />
                )}
              />
              <Controller
                name='exposeStartAt'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    type='date'
                    labelPlacement='outside'
                    placeholder='노출 시작일'
                    {...field}
                  />
                )}
              />
              <Controller
                name='exposeEndAt'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    type='date'
                    labelPlacement='outside'
                    placeholder='노출 종료일'
                    {...field}
                  />
                )}
              />
              <Controller
                name='availableStartAt'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    type='date'
                    labelPlacement='outside'
                    placeholder='구매가능 시작일'
                    {...field}
                  />
                )}
              />
              <Controller
                name='availableEndAt'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    type='date'
                    labelPlacement='outside'
                    placeholder='구매가능 종료일'
                    {...field}
                  />
                )}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postItemHook.mutate(
                      {
                        name,
                        description,
                        periodDescription,
                        price: parseInt(price, 10),
                        originalPrice: parseInt(originalPrice, 10),
                        admissionCount: parseInt(admissionCount, 10),
                        exposeStartAt: new Date(exposeStartAt).toISOString(),
                        exposeEndAt: new Date(exposeEndAt).toISOString(),
                        availableStartAt: new Date(availableStartAt).toISOString(),
                        availableEndAt: new Date(availableEndAt).toISOString(),
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['items']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && item?.itemId)
                    patchItemHook.mutate(
                      {
                        itemId: item.itemId,
                        name,
                        description,
                        periodDescription,
                        price: parseInt(price, 10),
                        originalPrice: parseInt(originalPrice, 10),
                        admissionCount: parseInt(admissionCount, 10),
                        exposeStartAt: new Date(exposeStartAt).toISOString(),
                        exposeEndAt: new Date(exposeEndAt).toISOString(),
                        availableStartAt: new Date(availableStartAt).toISOString(),
                        availableEndAt: new Date(availableEndAt).toISOString(),
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['items']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
