import Text from '@simppl/component/Text';
import c from '@simppl/util/c';

import { type CommonType } from './CommonType';

export type Title = CommonType & {
  type: 'title';
  size?: 'long' | 'short';
  alterText?: string;
};

export function Title({ content, size = 'short', alterText, type }: Title) {
  return (
    <div
      className={c(
        'h-[40px] text-ellipsis rounded-[4px] bg-blue-500 px-[10px] py-[8px] text-white',
        size === 'short' ? 'w-[155px]' : 'w-[252px]',
      )}
    >
      <Text
        className=' inline-block w-full overflow-hidden text-ellipsis whitespace-nowrap'
        color='text-White'
        weight='Regular'
        as='Label'
        size={1}
      >
        {alterText || content}
      </Text>
    </div>
  );
}
