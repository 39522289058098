import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { mockUserAdmissions } from '@simppl/repository/admins';

export const useMockUserAdmissions = () => {
  const { data } = useSuspenseQuery({
    queryKey: ['useMockUserAdmissions'],
    queryFn: () => mockUserAdmissions.get(),
  });

  return data.sort((a, b) =>
    new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime() ? 1 : -1,
  );
};
