import { AddItemModal } from '@component/database/item/AddItemModal';
import { ItemModal } from '@component/database/item/ItemModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useItem } from '@hook/useItem';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { items as itemsApi } from '@simppl/repository/items';
import { Search } from 'lucide-react';
import { useState } from 'react';

export default function ItemPage() {
  const { data } = useItem();
  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(data, (university, value) => university.name.includes(value));
  const [modalState, setModalState] = useState<
    | {
        mode: 'add';
      }
    | { mode: 'edit'; itemId: string }
    | null
  >(null);

  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();

  const [addModalState, setAddModalState] = useState<string | null>(null);
  const {
    isOpen: isAddModalOpen,
    onOpenChange: onAddModalOpenChange,
    onOpen: onAddModalOpen,
    onClose: onAddModalClose,
  } = useDisclosure();
  const deleteItemsHook = useMutation(itemsApi.delete);
  const queryClient = useQueryClient();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isAddModalOpen && addModalState !== null && (
          <AddItemModal
            isOpen={isAddModalOpen}
            onOpenChange={onAddModalOpenChange}
            onClose={onAddModalClose}
            itemId={addModalState}
          />
        )}
        {isOpen && modalState !== null && (
          <ItemModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            {...modalState}
            onClose={() => {
              onClose();
              setModalState(null);
            }}
          />
        )}
        <CardHeader>
          <h1 className='text-2xl'>상품 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={() => {
                      setModalState({ mode: 'add' });
                      onOpen();
                    }}
                  >
                    상품 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='name'>상품 이름</TableColumn>
              <TableColumn key='description'>상품 설명</TableColumn>
              <TableColumn key='periodDescription'>기간 설명</TableColumn>
              <TableColumn key='price'>가격</TableColumn>
              <TableColumn key='originalPrice'>기존 가격</TableColumn>
              <TableColumn key='admissionCount'>모의 지원 횟수</TableColumn>
              <TableColumn key='exposeStartAt'>노출 시작일</TableColumn>
              <TableColumn key='exposeEndAt'>노출 종료일</TableColumn>
              <TableColumn key='availableStartAt'>구매가능 시작일</TableColumn>
              <TableColumn key='availableEndAt'>구매가능 종료일</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={items}>
              {(item) => (
                <TableRow
                  className='relative cursor-pointer'
                  key={item.itemId}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.periodDescription}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>{item.originalPrice}</TableCell>
                  <TableCell>{item.admissionCount}</TableCell>
                  <TableCell>{item.exposeStartAt}</TableCell>
                  <TableCell>{item.exposeEndAt}</TableCell>
                  <TableCell>{item.availableStartAt}</TableCell>
                  <TableCell>{item.availableEndAt}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setModalState({ mode: 'edit', itemId: item.itemId });
                            onOpen();
                          }}
                        >
                          수정
                        </DropdownItem>
                        <DropdownItem
                          key='add'
                          onPress={() => {
                            setAddModalState(item.itemId);
                            onAddModalOpen();
                          }}
                        >
                          유저에게 상품 추가
                        </DropdownItem>
                        <DropdownItem
                          key='delete'
                          onPress={() => {
                            deleteItemsHook.mutate(
                              { itemId: item.itemId },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries(['items']);
                                },
                              },
                            );
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
