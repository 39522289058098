import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { getUsers } from '@simppl/repository/admins/index';

export const useUsers = () => {
  const { data } = useSuspenseQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  });
  return data;
};
