import { Accordion, AccordionItem } from '@nextui-org/react';
import type { AP_Curriculum } from '@simppl/repository/test/res';

export function Ap(data: AP_Curriculum['AP']) {
  return Object.values(data).map((item) => (
    <div
      className='pl-8'
      key={item.name}
    >
      <Accordion>
        <AccordionItem title={item.name}>
          <tr className='h-10'>
            <th className='w-20'>점수</th>
            <td>{item.apScore}</td>
          </tr>
          <tr className='h-10'>
            <th className='w-20'>AP 여부</th>
            <td>{item.isAp.toString()}</td>
          </tr>
        </AccordionItem>
      </Accordion>
    </div>
  ));
}
